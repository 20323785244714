const { useSelector } = require("react-redux");
const { Outlet, Navigate, useLocation } = require("react-router-dom");

export const PrivateRoute = ({ children, ...rest }) => {
  const auth = useSelector((state) => state.islogin);
  const location = useLocation();
  return auth ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};
