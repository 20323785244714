import * as React from "react";
import { MiniDrawer } from "./MiniDrawer";
import { Drawer } from "@mui/material";
const drawerWidth = 240;
export default function CustomDrawer({ children, open }) {
  return (
    <>
      {window.innerWidth <= 600 ? (
        <Drawer
          sx={{
            width: open ? drawerWidth : "0",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              backgroundColor: "#231751",
              color: "white",
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          // variant="persistent"
          variant={window.innerWidth <= 600 ? "temporary" : "persistent"}
          anchor="left"
          open={open}
        >
          {children}
        </Drawer>
      ) : (
        <MiniDrawer open={open}>{children}</MiniDrawer>
      )}
    </>
  );
}
