import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./common.scss";

const InputRadio = ({
  data = { label: "", values: [] }, // Fallback for data
  onChange, // Callback to handle answer selection
  formValidation = true, // Fallback for form validation
  validationMessage = "Required Field", // Custom validation message
  renderOption, // Custom function to render each option
  containerClassName = "", // Custom class for the container
  labelClassName = "form-title pt-3", // Custom class for label
  optionClassName = "radio-common custom-radio-selector my-3", // Custom class for options
  disabled = false, // Option to disable the entire selector
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [validForm, setFormValidation] = useState(formValidation);

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
    }
  }, [formValidation, data]);

  const handleOptionClick = (item) => {
    if (!item.disabled) {
      setSelectedOption(item.value);
      if (onChange) {
        onChange(data, item);
      }
      setFormValidation(true);
    }
  };

  return (
    <div className={`w-full ${containerClassName}`} {...rest}>
      {data?.label && (
        <div className={labelClassName}>
          {data.label}
          {data?.validate?.required && (
            <>
              <span className={!validForm ? "text-red-700" : ""}> * </span>
              {!validForm && (
                <span className="text-red-700 font-light text-xs">
                  {validationMessage}
                </span>
              )}
            </>
          )}
        </div>
      )}
      {data?.values && data.values.length > 0 ? (
        <div>
          {data.values.map((item) => (
            <div
              className={`${optionClassName} ${
                item.disabled ? "disabled" : ""
              }`}
              key={item.value}
            >
              <label className="pl-2">
                <input
                  type="radio"
                  value={item.value}
                  checked={selectedOption === item.value}
                  className="mr-2 custom-radio"
                  id="box-shadow"
                  onClick={() => handleOptionClick(item)}
                  disabled={item.disabled || disabled}
                />
                {renderOption ? (
                  renderOption(item) // Custom render for each option
                ) : (
                  <span className="form-title">{item.label}</span>
                )}
              </label>
            </div>
          ))}
        </div>
      ) : (
        <div>No options available</div>
      )}
    </div>
  );
};

// PropTypes for type validation
InputRadio.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
        disabled: PropTypes.bool, // Option to disable specific radios
      })
    ),
    answer: PropTypes.shape({
      value: PropTypes.any,
    }),
    validate: PropTypes.shape({
      required: PropTypes.bool,
    }),
  }),
  onChange: PropTypes.func, // Callback for answer selection
  formValidation: PropTypes.bool, // Initial form validation state
  validationMessage: PropTypes.string, // Custom validation message
  renderOption: PropTypes.func, // Custom render function for options
  containerClassName: PropTypes.string, // Custom class for the container
  labelClassName: PropTypes.string, // Custom class for the label
  optionClassName: PropTypes.string, // Custom class for each option
  disabled: PropTypes.bool, // Disable all radio buttons
};

// Default props for fallback values
InputRadio.defaultProps = {
  data: { label: "", values: [] },
  onChange: null,
  formValidation: true,
  validationMessage: "Required Field",
  renderOption: null,
  containerClassName: "",
  labelClassName: "form-title pt-3",
  optionClassName: "radio-common custom-radio-selector my-3",
  disabled: false,
};

export default InputRadio;
