import {
  Box,
  Stack,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Add, Close, Delete, Edit, Visibility } from "@mui/icons-material";
import CustomButton from "../../components/custom/CustomButton";
import ModalBox from "./ModalBox";
import TableLayout from "../../components/common/TableLayout";
import { deleteRequest, getRequest, postRequest } from "../../utils/request";
import { FiCheck, FiX } from "react-icons/fi";
import EditSpace from "./EditSpace";
import PageHeading from "../../components/common/PageHeading";
import { useNavigate } from "react-router-dom";
const CustomSpaces = () => {
  const navigate = useNavigate();
  const [enterName, setEnterName] = useState(false);
  const [type, setType] = useState("");
  const [spaces, setSpaces] = useState([]);
  const [addSpace, setAddSpace] = useState(false);
  const [name, setName] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const actions = (space) => {
    const handleDelete = async () => {
      try {
        const response = await deleteRequest(
          `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customspaces/${space._id}`
        );
        const modifiedSpaces = spaces.filter(
          (s) => s._id !== response.data.customSpace._id
        );
        console.log(modifiedSpaces);
        setSpaces(modifiedSpaces);
      } catch (e) {
        console.log("Could not delete, try again later");
      }
    };
    return [
      <IconButton
        onClick={() => {
          navigate(`/edit-custom-space/${space._id}`);
        }}
      >
        <Edit />
      </IconButton>,
      <IconButton onClick={handleDelete}>
        <Delete />
      </IconButton>,
      <IconButton
        onClick={() => {
          navigate(`/view-custom-space/${space._id}`);
        }}
      >
        <Visibility />
      </IconButton>,
    ];
  };
  const processSpaceData = (space) => {
    if (space.actions) {
      delete space.action;
    } else {
      space.actions = actions(space);
    }
    return space;
  };

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await getRequest(
        `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customspaces`,
        {},
        false
      );
      let modifiedSpaces = response.data.customSpaces;
      modifiedSpaces.forEach((space) => {
        space = processSpaceData(space);
      });
      setSpaces(modifiedSpaces);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const handleNewSpace = async (newSpace) => {
    const modifiedSpace = processSpaceData(newSpace);
    setSpaces((prevSpaces) => [...prevSpaces, modifiedSpace]);
  };

  const handleClose = () => {
    setAddSpace(false);
    setType();

    setName();
    setEnterName(false);
  };

  const handleSelectCreate = () => {
    setType("create");
  };
  const handleSelectCSV = () => {
    // setType("csv");
  };
  const columns = [
    {
      id: "common_name",
      value: "common_name",
      label: "Space Name",
      align: "center",
      sort: true
    },
    {
      id: "actions",
      value: "actions",
      label: "Actions",
      minWidth: 100,
      align: "center",
    },
  ];

  return (
    <Stack p={2} spacing={4} width={"100%"} justifyContent={"center"}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">Custom Spaces</Typography>
        {enterName ? (
          <div className="flex">
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Custom Space Name"
              className="px-2 border mustard_border rounded"
            ></input>
            <FiCheck
              size={40}
              className="my-auto ml-2 rounded purple_bg_dark p-2 cursor-pointer text-white"
              onClick={() => {
                setAddSpace(true);
                handleSelectCreate();
              }}
            />
            <FiX
              size={40}
              className="my-auto ml-2 rounded bg-red-500 p-2 cursor-pointer text-white"
              onClick={() => {
                setName();
                setEnterName(false);
              }}
            />
          </div>
        ) : (
          <CustomButton onClick={() => setEnterName(true)}>
            <Add /> CREATE
          </CustomButton>
        )}
      </Box>
      {isLoading ? (
        <div className="flex justify-center py-24">
          <CircularProgress sx={{ color: "#CF7447" }} />
        </div>
      ) : (
        <TableLayout columns={columns} rows={spaces}/>
      )}
      <ModalBox open={addSpace} onClose={handleClose}>
        <PageHeading
          heading={`Create Space`}
          icon={<Close />}
          iconClick={handleClose}
          variant={"text"}
        />
        <EditSpace
          type={type}
          handleSelectCSV={handleSelectCSV}
          spaceName={name}
          handleNewSpace={handleNewSpace}
          handleClose={handleClose}
        />
      </ModalBox>
    </Stack>
  );
};

export default CustomSpaces;
