import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Stack,
} from "@mui/material";

export const Notifications = () => {
  return (
    <Box>
      {/* <Box m={"20px"}>
        <Typography variant="h5"> Notifications</Typography>
      </Box> */}
      <Stack spacing={4}>
        <Typography variant="h4">Notifications</Typography>
        <Box>
          {notifications.map(({ title, summary, details }, index) => (
            <Accordion key={index} sx={{ margin: 2, maxWidth: "800px" }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  {title} : {summary}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography textAlign={"left"}>{details}</Typography>
              </AccordionDetails>
            </Accordion>
            // <Paper sx={{ margin: 2 }} key={index} variant={"outlined"}>
            //   {title}: {details}
            // </Paper>
          ))}
        </Box>
      </Stack>
    </Box>
  );
};

const notifications = [
  {
    title: "Notification1",
    summary: "this is notification1",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    title: "Notification2",
    summary: "this is notification2",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    title: "Notification3",
    summary: "this is notification3",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    title: "Notification4",
    summary: "this is notification4",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    title: "Notification5",
    summary: "this is notification5",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    title: "Notification6",
    summary: "this is notification6",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    title: "Notification7",
    summary: "this is notification7",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    title: "Notification8",
    summary: "this is notification8",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
  {
    title: "Notification9",
    summary: "this is notification9",
    details:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
];
