import React from "react";
import { Stack, Typography, Avatar, CardContent, Divider } from "@mui/material";
import { TrendingDown, TrendingUp } from "@mui/icons-material";
import { CustomCard } from "../../../../components/custom/CustomCard";

export const CardLayout = ({ children, icon, increase, decrease }) => {
  return (
    <CustomCard sx={{ height: "160px" }}>
      <Stack direction="column" spacing={0}>
        <CardContent>
          <Stack spacing={3}>
            <Stack direction="row" sx={{ alignItems: "flex-end" }} spacing={3}>
              <Avatar
                sx={{
                  backgroundColor: "#FCFCF9", //"#231751",
                  // height: "56px",
                  width: "56px",
                  borderRadius: "50%",
                  color: "#231751",
                }}
              >
                {icon}
              </Avatar>
              <Stack>{children}</Stack>
            </Stack>
          </Stack>
        </CardContent>
        {/* </Card> */}
        <Divider
          sx={{
            borderStyle: "solid",
            borderColor: "grey.300",
            borderWidth: "1px 0 0 0",
          }}
        />
        <CardContent>
          {increase && <TrendingUp style={{ color: "#15b79f" }} />}
          {decrease && <TrendingDown style={{ color: "#F04438" }} />}
          <Typography variant="caption">
            <span style={{ color: increase ? "#15b79f" : "#F04438" }}>
              {`${increase ?? decrease}%`}
            </span>{" "}
            {increase ? "increase" : "decrease"} vs last month
          </Typography>
        </CardContent>
      </Stack>
    </CustomCard>
  );
};
