import {
  ADD_INTEGRATION,
  SET_APPOINTMENT,
  SET_LOGIN,
  SET_USER,
} from "./action";

const initialState = {
  islogin: window.localStorage.getItem("isLoggedIn"),
  integration: [],
  appointment: {},
  user: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      return {
        ...state,
        islogin: action.payload,
      };
    case ADD_INTEGRATION:
      return {
        ...state,
        integration: [...state.integration, action.payload],
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    // case REMOVE_INTEGRATION:
    // return {
    //   ...state,
    //   integration: state.integration.filter(item => item.id !== action.payload),
    // };

    case SET_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
      };
    default:
      return state;
  }
};
export default userReducer;
