import React, { useState } from "react";
import FormRendererContext from "./FormRendererContext";

const FormRendererContextProvider = ({ children }) => {
  const [journeyData, setJourneyData] = useState(null);
  const [activeScreenID, setActiveScreenID] = useState(null);
  const [activeScreenPageID, setActiveScreenPageID] = useState(null);
  const [activeComponent, setActiveComponent] = useState(null);
  const [activeFormPage, setActiveFormPage] = useState(null);
  const [completeJourneyAnswers, setCompleteJourneyAnswers] = useState([]);

  return (
    <FormRendererContext.Provider
      value={{
        journeyData,
        setJourneyData,
        activeScreenID,
        setActiveScreenID,
        activeScreenPageID,
        setActiveScreenPageID,
        activeComponent,
        setActiveComponent,
        activeFormPage,
        setActiveFormPage,
        completeJourneyAnswers,
        setCompleteJourneyAnswers,
      }}
    >
      {children}
    </FormRendererContext.Provider>
  );
};

export default FormRendererContextProvider;
