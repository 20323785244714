import React from "react";
import { AppRegistrationOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { CardLayout } from "./CardLayout";

export const SignUps = () => {
  return (
    <CardLayout icon={<AppRegistrationOutlined />} decrease={"5"}>
      <Typography variant="overline">APPOINTMENTS</Typography>
      <Typography variant="h4">240</Typography>
    </CardLayout>
  );
};
