import { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PropTypes from "prop-types";

const DateInput = ({
  label, // Label for the input field
  value, // Controlled value
  onChange, // Callback to handle date changes
  required = false, // Whether the field is required
  invalidMessage = "Required Field", // Custom invalid message
  formClass = "", // Additional class for the form container
  inputClass = "", // Class for the date picker input
  isValid = true, // Validation status
  onValidationChange, // Function to control validation status
  dateAdapter = AdapterDayjs, // Adapter for date library (default: Dayjs)
  pickerProps = {}, // Additional props for the DatePicker component
}) => {
  const [selectedDate, setSelectedDate] = useState(value || null); // Initialize with null as default

  useEffect(() => {
    // Check if the value is a valid date; if not, reset to null
    if (value && !value.isValid) {
      setSelectedDate(null);
    } else {
      setSelectedDate(value);
    }
  }, [value]);

  useEffect(() => {
    if (onValidationChange) {
      onValidationChange(isValid);
    }
  }, [isValid, onValidationChange]);

  const handleDateChange = (newValue) => {
    // Fallback handling for newValue
    if (newValue && newValue.isValid) {
      setSelectedDate(newValue);
      if (onChange) onChange({ value: newValue });
      if (required && onValidationChange) {
        onValidationChange(!!newValue);
      }
    } else {
      // Handle case for invalid date selection
      setSelectedDate(null);
      if (required && onValidationChange) {
        onValidationChange(false);
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={dateAdapter}>
      <div className={`date-input-container w-full ${formClass}`}>
        {label && (
          <div className="form-title pt-3">
            {label}
            {required && (
              <span className={!isValid ? "text-red-700" : "text-gray-600"}>
                {" "}
                *
              </span>
            )}
          </div>
        )}
        <div className="date-picker-wrapper my-2">
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            className={`text-field text-input ${inputClass} ${
              !isValid ? "input-error" : ""
            }`}
            {...pickerProps} // Allow custom DatePicker props
          />
        </div>
        {required && !isValid && (
          <span className="text-red-700 font-light text-xs">
            {invalidMessage}
          </span>
        )}
      </div>
    </LocalizationProvider>
  );
};

// PropTypes for component validation
DateInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any, // Can be a string or dayjs object, depending on the date adapter
  onChange: PropTypes.func,
  required: PropTypes.bool,
  invalidMessage: PropTypes.string,
  formClass: PropTypes.string,
  inputClass: PropTypes.string,
  isValid: PropTypes.bool,
  onValidationChange: PropTypes.func,
  dateAdapter: PropTypes.elementType, // Adapter component
  pickerProps: PropTypes.object,
};

export default DateInput;
