import React from "react";
import PropTypes from "prop-types";

const MuiWrapper = ({ component: Component, children, ...props }) => {
  return <Component {...props}>{children}</Component>;
};

// PropTypes for type checking
MuiWrapper.propTypes = {
  component: PropTypes.elementType.isRequired, // Required: MUI component to render
  children: PropTypes.node, // Optional: Children to render inside the component
};

export default MuiWrapper;
