import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import FormField from "../../components/common/FormField";
import "./scannedForm.scss";
import CustomButton from "../../components/custom/CustomButton";
import { getAuthToken } from "../../utils/cookie";
import axios from "axios";

const title = "";
const subTitle = "Let’s build a healthier tomorrow, together.";

const ScannedForm = () => {
  const [formData, setFormData] = useState({
    firstName: { valid: true, value: "" },
    lastName: { valid: true, value: "" },
    email: { valid: true, value: "" },
    company: { valid: true, value: "" },
    iAm: {
      valid: true,
      options: [
        { label: "An Investor", value: false, id: "investor" },
        { label: "A Customer", value: false, id: "customer" },
        { label: "A Passer-by", value: false, id: "passerby" },
      ],
      value: [],
    },
    message: { valid: true, value: "" },
    token: { valid: true, value: null },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef = useRef(null);

  const handleChange = (field, value) => {
    if (field === "iAm") {
      setFormData((prevData) => {
        const updatedOptions = prevData.iAm.options.map((option) =>
          option.id === value
            ? { ...option, value: !option.value } // Toggle the selected option's value
            : { ...option }
        );
        const updatedValue = updatedOptions
          .filter((option) => option.value) // Get only selected options
          .map((option) => option.id); // Extract their IDs
        return {
          ...prevData,
          iAm: {
            ...prevData.iAm,
            options: updatedOptions,
            valid: true,
            value: updatedValue,
          },
        };
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [field]: { ...prevData[field], value, valid: true },
      }));
    }
  };

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validateForm = () => {
    let isValid = true;
    const updatedData = { ...formData };

    Object.keys(updatedData).forEach((key) => {
      if (key === "email") {
        if (updatedData[key].value && updatedData[key].value !== "") {
          let isValidMail = isValidEmail(updatedData[key].value);
          if (!isValidMail) {
            updatedData[key].valid = false;
            updatedData[key].inValidMessage = "Invalid Email";
            isValid = false;
          }
        } else {
          updatedData[key].valid = false;
          updatedData[key].inValidMessage = null;
          isValid = false;
        }
      } else if (key === "iAm") {
        if (!updatedData[key]?.value?.length) {
          updatedData[key].valid = false;
          isValid = false;
        }
      } else if (!updatedData[key].value && key !== "message") {
        updatedData[key].valid = false;
        isValid = false;
      }
    });

    setFormData(updatedData);
    return isValid;
  };

  const formatFormData = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (key === "iAm") {
        acc[key] = value.value; // Special handling for iAm
      } else {
        acc[key] = value.value; // For other fields
      }
      return acc;
    }, {});
  };

  const downloadFile = async (url) => {
    try {
      const response = await axios.get(url, { responseType: "blob" }); // Ensure binary response
      const blob = new Blob([response.data], { type: "application/pdf" }); // Create PDF blob
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob); // Create object URL
      link.download = "HealthBlox Investor 1 Pager.pdf"; // Set file name
      link.click(); // Trigger download
      URL.revokeObjectURL(link.href); // Clean up
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      console.error("Form validation failed");
      return;
    }
    setIsLoading(true);
    const formattedData = formatFormData(formData);

    let API_URL = `${
      process.env?.REACT_APP_SERVER ||
      "https://accounts-sandbox.healthblox.care/api/v1"
    }/form-response/submit`;
    const headers = getAuthToken();
    try {
      const response = await axios.post(API_URL, formattedData, headers);
      const { url } = response?.data;
      await downloadFile(url);
      setFormSubmitted(true);
      setIsLoading(false);
      captchaRef.current.reset();
      return;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      //   alert("error in uploading data");
      return null;
    }
  };

  if (formSubmitted) {
    return (
      <div className="main-container w-full thankyou-container">
        <p>Thank you for connecting with us!</p>
      </div>
    );
  }

  return (
    <div className="main-container w-full">
      {isLoading ? (
        <div className="dot-loader"></div>
      ) : (
        <div className="mobile-container p-3">
          <div className="heading-container">
            <div className="title">
              Connect with{" "}
              <span className="electric_violet_text">HealthBlox</span>
            </div>
            <div className="subtitle">{subTitle}</div>
          </div>
          <div className="form-container">
            <div className="name-container">
              <FormField
                label="First Name"
                value={formData.firstName.value}
                isValid={formData.firstName.valid}
                placeholder="John"
                required
                onChange={(value) => handleChange("firstName", value)}
              />
              <FormField
                label="Last Name"
                value={formData.lastName.value}
                isValid={formData.lastName.valid}
                placeholder="Smith"
                required
                onChange={(value) => handleChange("lastName", value)}
              />
            </div>
            <div className="email-container">
              <FormField
                label="Email"
                type="email"
                value={formData.email.value}
                isValid={formData.email.valid}
                inValidMessage={formData?.email?.inValidMessage || null}
                placeholder="john@example.com"
                required
                onChange={(value) => handleChange("email", value)}
              />
              <FormField
                label="Company"
                value={formData.company.value}
                isValid={formData.company.valid}
                placeholder="HealthBlox"
                required
                onChange={(value) => handleChange("company", value)}
              />
            </div>
            <FormField
              label="I am"
              type="select"
              value={formData.iAm.options}
              isValid={formData.iAm.valid}
              required
              onChange={(value) => handleChange("iAm", value)}
            />
            <FormField
              label="Message"
              type="textarea"
              value={formData.message.value}
              placeholder="Leave us a message..."
              onChange={(value) => handleChange("message", value)}
            />
            <div className="pt-2 w-full recaptcha-container flex justify-center">
              <div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  ref={captchaRef}
                  onChange={(value) => handleChange("token", value)}
                />
                {!formData.token.valid && (
                  <span className="text-red-700 font-light text-xs">
                    Please verify the captcha
                  </span>
                )}
              </div>
            </div>
            <div className="pt-3 flex justify-center">
              <div className="submit-container">
                <CustomButton onClick={handleSubmit} className="w-full">
                  Submit
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScannedForm;
