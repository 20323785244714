import { Box, CssBaseline } from "@mui/material";
import { DrawerHeader } from "../../components/AppBarDrawer";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { isTokenValid } from "../../utils/sessionutils";
import AppBarDrawer from "../../components/AppBarDrawer";

const Dashboard = () => {
  useEffect(() => {
    isTokenValid();
  }, []);
  return (
    <>
      <CssBaseline />
      <AppBarDrawer />
      <Box component="main" sx={{ flexGrow: 1, p: 2, overflowX: "hidden" }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </>
  );
};

export default Dashboard;
