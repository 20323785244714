import Cookies from "js-cookie";

export const getAuthToken = (header = {}) => {
    const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const headers = {
      headers: {
        ...header,
        "x-auth-token": token,
      },
    }
    return headers;
  };