import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useState, useRef } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { upload } from "../utils/uploadfuctions";
import { useSelector } from "react-redux";

const DragDropFiles = ({ next }) => {
  const [files, setFiles] = useState(null);
  const inputRef = useRef();
  const [waiting, setWaiting] = useState(false);
  const appointment = useSelector((state) => state.appointment);
  const details = {
    providerid: appointment.providerId,
    departmentid: appointment.departmentId,
    patientid: appointment.patient.patientId,
    practiceid: appointment.practiceId,
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    // for (var file in event.dataTransfer.files) {
    //   if (event.dataTransfer.files[file].type === "application/pdf") {
    //     console.log("pdf");
    //     addFiles(file);
    //   } else {
    //     alert("Upload pdf");
    //   }
    // }
    addFiles(event.dataTransfer.files);
  };
  const addFiles = (newFile) => {
    if (files) {
      console.log("new file", newFile);
      console.log("file", files);
      setFiles((prevFiles) => [...prevFiles, ...newFile]);
    } else {
      setFiles(newFile);
    }
  };
  const handleUpload = async () => {
    setWaiting(true);
    console.log(files);
    const file = files[0];
    console.log(file);
    const formData = new FormData();
    formData.append("file", file);
    console.log(formData);
    for (const pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
      await upload(pair[1], next, true, details);
    }

    setWaiting(false);
  };

  return (
    <>
      <div
        className="dropzone"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => inputRef.current.click()}
      >
        <Box>
          <CloudUploadIcon />
        </Box>
        <Typography variant="h7"> Drag and Drop Files to Upload</Typography>
        <input
          type="file"
          multiple
          onChange={(event) => addFiles(event.target.files)}
          hidden
          accept=".pdf"
          ref={inputRef}
        />
      </div>
      {files && (
        <div>
          <ul>
            {Array.from(files).map((file, idx) => (
              <li key={idx}>{file.name}</li>
            ))}
          </ul>
          {waiting ? (
            <CircularProgress color="inherit" />
          ) : (
            <div className="actions">
              <Button
                disabled={waiting}
                size="small"
                variant="contained"
                onClick={() => setFiles(null)}
              >
                Cancel
              </Button>
              <Button
                disabled={waiting}
                size="small"
                variant="contained"
                style={{ marginLeft: "5px" }}
                onClick={handleUpload}
              >
                Upload
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DragDropFiles;
