import { useState, useEffect } from "react";
import "./common.scss";

const InputNumber = ({
  label, // Label for the input field
  value, // Controlled value
  onChange, // Callback to handle value changes
  required = false, // Whether the field is required
  invalidMessage = "Required Field", // Custom invalid message
  className = "", // Additional class for the input container
  inputProps = {}, // Additional props for the input field
  isValid = true, // Validation status
}) => {
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    setInputValue(value); // Update local state if the value prop changes
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue); // Update local state
    if (onChange) {
      onChange({value: newValue}); // Call the onChange callback with the new value
    }
  };

  return (
    <div className={`number-input-container w-full ${className}`}>
      {label && (
        <label className="form-title pt-3">
          {label}
          {required && <span className="text-red-700"> *</span>}
        </label>
      )}

      <div className="input-wrapper my-2">
        <input
          type="number"
          value={inputValue}
          onChange={handleChange}
          className={`text-field text-input ${!isValid ? "input-error" : ""}`}
          aria-invalid={!isValid} // Accessibility: indicate if input is valid
          {...inputProps} // Spread additional input props
        />
      </div>

      {required && !isValid && (
        <span className="text-red-700 font-light text-xs">{invalidMessage}</span>
      )}
    </div>
  );
};

export default InputNumber;
