import BoxWrapper from "../../mui-wrappers/mui-components/BoxWrapper";
import TypographyWrapper from "../../mui-wrappers/mui-components/TypographyWrapper";
import CustomButton from "../custom/CustomButton";
import PropTypes from "prop-types";

const PageHeading = ({
  heading = "Default Heading", // Fallback for heading
  icon = null, // Fallback for icon
  iconText = "", // Fallback for iconText
  iconClick = () => {}, // Fallback for iconClick
  ...rest
}) => {
  return (
    <BoxWrapper
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
      {...rest}
    >
      <TypographyWrapper variant="h4">{heading}</TypographyWrapper>
      {(icon || iconText) && (
        <CustomButton onClick={iconClick}>
          {icon} {iconText}
        </CustomButton>
      )}
    </BoxWrapper>
  );
};

// PropTypes for type safety and documentation
PageHeading.propTypes = {
  heading: PropTypes.string, // The page heading
  icon: PropTypes.element, // Icon to display in the button
  iconText: PropTypes.string, // Text to display alongside the icon
  iconClick: PropTypes.func, // Function called on button click
};

// Default props for fallback values
PageHeading.defaultProps = {
  heading: "Default Heading",
  icon: null,
  iconText: "",
  iconClick: () => {},
};

export default PageHeading;
