import { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import Summary from "./Modal/Summary";
import { Box, Modal } from "@mui/material";

const columns = [
  { id: "id", label: "ID" },
  { id: "name", label: "Name" },
  {
    id: "platform",
    label: "Platform",
  },
  {
    id: "primary_id",
    label: "Primary Id",
  },
];

export default function StickyHeadTable() {
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const integrations = useSelector((state) => state.integration);
  const data = useSelector((state) => state.integration);

  function createData(id, name, primary_id, platform) {
    return { id, name, primary_id, platform };
  }

  const rows = [];
  for (var i in integrations) {
    rows.concat(createData(i.id, i.name, i.primary_id, i.platform));
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    style={{ cursor: "pointer" }}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    onClick={() => {
                      //   const data = getData(row.id);
                      setModalData({
                        id: row.id,
                        name: row.name,
                        summary: "summary",
                        primary_id: row.primary_id,
                        appointment_updates: false,
                        signed_order_updates: true,
                        appointment_summary: true,
                      });
                      setOpenModal(true);
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal open={openModal}>
        <Box sx={style}>
          <Summary closeModal={() => setOpenModal(false)} data={modalData} />
        </Box>
      </Modal>
    </Paper>
  );
}

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
