import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import StripeSetup from "./StripeSetup";

const PaymentWidgetSetup = () => {
  const [orgId, setOrgId] = useState("");
  const [connectId, setConnectId] = useState("");
  const [email, setEmail] = useState("");
  const [setup, setSetup] = useState(false);
  const [amount, setAmount] = useState("");
  const [saveDetails, setSaveDetails] = useState(false);
  const [type, setType] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    setSetup(true);
  };
  return (
    <Box m="20px">
      {!setup ? (
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setSetup(false);
            }}
          />
          <TextField
            label="Organization ID"
            variant="outlined"
            fullWidth
            margin="normal"
            value={orgId}
            onChange={(e) => {
              setOrgId(e.target.value);
              setSetup(false);
            }}
          />
          <TextField
            label="Connect ID"
            variant="outlined"
            fullWidth
            margin="normal"
            value={connectId}
            onChange={(e) => {
              setConnectId(e.target.value);
              setSetup(false);
            }}
          />
          <TextField
            label="Amount"
            variant="outlined"
            fullWidth
            margin="normal"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
              setSetup(false);
            }}
          />
          <TextField
            label="Payment/setup"
            variant="outlined"
            fullWidth
            margin="normal"
            value={type}
            onChange={(e) => {
              setType(e.target.value);
              setSetup(false);
            }}
          />
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveDetails}
                  onChange={(e) => setSaveDetails(e.target.checked)}
                />
              }
              label="Save details"
            />
          </Box>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
      ) : (
        <StripeSetup
          // org_id={"65df093d2ef404d2fa3a8f57"}
          // amount={80}
          // connect_id={"acct_1Ow27qRRTBHh8vmT"}
          // email={"testpaymentscustomer@customer.com"}
          org_id={orgId}
          amount={amount}
          connect_id={connectId}
          email={email}
          save_details={saveDetails}
          type={type}
        />
      )}
    </Box>
  );
};
export default PaymentWidgetSetup;
