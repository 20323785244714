
import { useState, useEffect } from "react";

const InputDropdown = ({
  label, // Directly receive label
  options = [], // Directly receive options
  answer = null, // Controlled answer value
  onChange, // Callback for when the selection changes
  placeholder = "Please Choose...", // Default placeholder
  isDisabled = false, // Optional disabled state
  className = "", // Custom class for the dropdown
  labelClassName = "", // Custom class for the label
  selectClassName = "", // Custom class for the select field
  badgeClassName = "", // Custom class for the badge
  required = false, // Optional required attribute for form validation
  badgePosition = "absolute", // Control over badge positioning
}) => {
  const [selectedOption, setSelectedOption] = useState(answer || null);

  const handleChange = (e) => {
    const selectedValue = { value: e.target.value };
    setSelectedOption(selectedValue);
    onChange && onChange(selectedValue); // Use onChange callback
  };

  useEffect(() => {
    setSelectedOption(answer);
  }, [answer]);

  return (
    <div className={`w-full relative dropdown-select ${className}`}>
      {/* Label */}
      {label && (
        <div className={`form-title pt-3 ${labelClassName}`}>
          {label}
          {required && <span className="text-red-700"> *</span>}
        </div>
      )}

      <select
        required={required}
        disabled={isDisabled}
        className={`new-dropdown radio-common text-field text-input ${selectClassName}`}
        value={selectedOption?.value || ""}
        onChange={handleChange}
      >
        <option className="placeholder-text" value="" disabled hidden>
          {placeholder}
        </option>
        {options.length > 0 &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label || option.value || "Option"}
            </option>
          ))}
      </select>

      {/* Badge */}
      {selectedOption?.badge && (
        <div className={`${badgePosition} dropdown-badge ${badgeClassName}`}>
          {selectedOption.badge}
        </div>
      )}
    </div>
  );
};

export default InputDropdown;