import { getRequest } from "../../../utils/request";

export const getData = async () => {
  try {
    const response = await getRequest("http://localhost:5000/appointments");
    const data = response.data;
    const filtered_data = data.map((obj) => obj.Simple);
    return filtered_data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

// const data = [
//   {
//     appointmentId: "1430476",
//     appointmentType: "Physical",
//     appointmentStatus: "Booked",
//     practiceId: "1959585",
//     rescheduledAppointmentId: null,
//     cancelReason: null,
//     departmentId: "1",
//     providerId: "88",
//     lastModified: "12/17/2023 04:37:52",
//     duration: 15,
//     startTime: "08:00",
//     date: "12/18/2023",
//     checkOutDateTime: null,
//     checkInDateTime: null,
//     stopExamDateTime: null,
//     encounterId: null,

//     patient: {
//       patientId: "26914",
//       firstName: "Test",
//       middleName: null,
//       lastName: "Testhbx",
//       SSN: "*****6789",
//       DOB: "02/19/1987",
//       sex: "Male",
//       race: "Patient Declined",
//       religion: null,
//       maritalStatus: "Single",
//       isDeceased: false,
//       phoneNumber: { home: "7867677771", mobile: "7867677771" },
//       language: "declined",
//       address: {
//         streetAddress: "9450 SW Gemini Dr",
//         city: "BEAVERTON",
//         state: "OR",
//         ZIP: "97008",
//         country: "USA",
//       },
//     },
//     custom: { referrer: "HATPro", orderId: "12345678" },
//   },
//   {
//     appointmentId: "1430479",
//     appointmentType: "Follow Up",
//     appointmentStatus: "Booked",
//     practiceId: "1959585",
//     rescheduledAppointmentId: null,
//     cancelReason: null,
//     departmentId: "1",
//     providerId: "88",
//     lastModified: "12/17/2023 05:18:46",
//     duration: 15,
//     startTime: "08:45",
//     date: "12/18/2023",
//     checkOutDateTime: null,
//     checkInDateTime: null,
//     stopExamDateTime: null,
//     encounterId: null,
//     patient: {
//       patientId: "26914",
//       firstName: "Test",
//       middleName: null,
//       lastName: "Testhbx",
//       SSN: "*****6789",
//       DOB: "02/19/1987",
//       sex: "Male",
//       race: "Patient Declined",
//       religion: null,
//       maritalStatus: "Single",
//       isDeceased: false,
//       phoneNumber: { home: "7867677771", mobile: "7867677771" },
//       language: "declined",
//       address: {
//         streetAddress: "9450 SW Gemini Dr",
//         city: "BEAVERTON",
//         state: "OR",
//         ZIP: "97008",
//         country: "USA",
//       },
//     },
//     custom: {
//       referrer: "HSATPro",
//       orderId: 1234344,
//     },
//   },
//   {
//     appointmentId: "1430484",
//     appointmentType: "New Patient",
//     appointmentStatus: "Booked",
//     practiceId: "1959585",
//     rescheduledAppointmentId: null,
//     cancelReason: null,
//     departmentId: "1",
//     providerId: "88",
//     lastModified: "12/17/2023 05:22:31",
//     duration: 30,
//     startTime: "10:00",
//     date: "12/18/2023",
//     checkOutDateTime: null,
//     checkInDateTime: null,
//     stopExamDateTime: null,
//     encounterId: null,
//     patient: {
//       patientId: "26915",
//       firstName: "Test",
//       middleName: null,
//       lastName: "Simple",
//       SSN: "*****6788",
//       DOB: "12/01/2004",
//       sex: "Male",
//       race: null,
//       religion: null,
//       maritalStatus: "Single",
//       isDeceased: false,
//       phoneNumber: {
//         home: "7867672222",
//         mobile: "7867672222",
//       },
//       language: null,
//       address: {
//         streetAddress: null,
//         city: null,
//         state: null,
//         ZIP: null,
//         country: "USA",
//       },
//     },
//     custom: {
//       referrer: "HSATPro",
//       orderId: 12455,
//     },
//   },
// ];
