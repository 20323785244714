import React, { useEffect, useState } from "react";
import "./scenarios.scss";
import { Link, useNavigate } from "react-router-dom";
import TableLayout from "../../components/common/TableLayout";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import PageHeading from "../../components/common/PageHeading";
import { Add } from "@mui/icons-material";
import { MdDelete } from "react-icons/md";
import { IoIosArrowRoundForward } from "react-icons/io";
import { getRequest } from "../../utils/request";
import { MdModeEdit } from "react-icons/md";
import { BsExclamation } from "react-icons/bs";
import { ReactComponent as PendingLogo } from "../../assets/images/logo/logoIpsum.svg";
import { IoIosCloseCircleOutline } from "react-icons/io";

import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiDraftFill } from "react-icons/ri";
import { FiMinusCircle } from "react-icons/fi";

const Scenarios = () => {
  const [scenarioList, setScenarioList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openAllStatus, setOpenAllStatus] = useState(null);
  const [openAllApproveStatus, setOpenAllApproveStatus] = useState(null);

  let navigate = useNavigate();
  const columns = [
    {
      id: "name",
      label: "Name",
      align: "center",
      format: (value, row) => nameFormatter(value, row),
      sort: true
    },
    {
      id: "originator_client",
      label: "Originator Client",
      align: "center",
      format: (value, row) => logoFormatter(value, row),
    },
    { id: "client", label: "Organization", align: "center" },
    {
      id: "default_client_integration",
      label: "Default Integration",
      align: "center",
    },
    {
      id: "status",
      label: "Status",
      align: "center",
      format: (value, row) => statusFormatter(value, row),
    },
    {
      id: "approved",
      label: "Approved",
      align: "center",
      format: (value, row) => approvalFormatter(value, row),
    },
    {
      id: "actions",
      label: "Actions",
      align: "center",
      format: (value, row) => actionFormatter(value, row),
    },
    ,
  ];
  const getWorkflows = async () => {
    setIsLoading(true);
    try {
      const response = await getRequest(
        `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/workflows/raw`,
        {},
        false
      );
      // console.log("workflows", response.data.workflows)

      let Workflows = response.data.workflows;
      let WorkflowData = [];
      for (let i = 0; i < Workflows.length; i++) {
        WorkflowData.push(Workflows[i]?.data);
        if (WorkflowData[i]?.madeFromHBxDashboard) {
          WorkflowData[i]._id = Workflows[i]._id;
        } else WorkflowData[i]._id = i + 10;
      }
      setScenarioList(WorkflowData);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const handleStatusUpdate = async (status, id) => {
    setIsLoading(true);
    for (let i = 0; i < scenarioList.length; i++) {
      if (scenarioList[i]._id === id) {
        scenarioList[i].status = status;
      }
    }
    setOpenAllStatus(null);
    setIsLoading(false);
  };

  const handleApproveStatusUpdate = async (status, id) => {
    setIsLoading(true);
    for (let i = 0; i < scenarioList.length; i++) {
      if (scenarioList[i]._id === id) {
        scenarioList[i].approved = status;
      }
    }
    setOpenAllApproveStatus(null);
    setIsLoading(false);
  };

  useEffect(() => {
    getWorkflows();
  }, []);
  const actionFormatter = (value, row) => {
    return (
      <div className="flex justify-center">
        <MdDelete size={32} className="cursor-pointer p-1" onClick={() => {}} />
        <Link
          key={row?.label}
          className="no-underline px-2 mustard_text"
          to={`/edit-work-flow/${row?._id}`}
        >
          <IoIosArrowRoundForward size={32} className="cursor-pointer" />
        </Link>
      </div>
    );
  };

  const nameFormatter = (value, row) => {
    if (row?.status !== "PENDING") return value;
    return (
      <>
        <div className="flex justify-center">
          <BsExclamation color={"red"} size={42} className="px-1" />
          <div className="my-auto">{value}</div>
        </div>
      </>
    );
  };
  const logoFormatter = (value, row) => {
    if (row?.originator_client !== "IDTF") return value;
    return (
      <>
        <div className="">
          <PendingLogo
            style={{
              height: "30px",
              marginInline: "auto",
              marginBottom: "6px",
            }}
          />
          {/* <BsExclamation color={"red"} size={42} className="px-1" /> */}
          <div className="mx-auto">{value}</div>
        </div>
      </>
    );
  };

  const statusFormatter = (status, row) => {
    const statusConfig = {
      ENABLED: {
        icon: <IoIosCheckmarkCircle className="my-auto" color={"green"} />,
        text: "ENABLED",
      },
      DISABLED: {
        icon: <FiMinusCircle className="my-auto" color={"red"} />,
        text: "DISABLED",
      },
      PENDING: {
        icon: <RiDraftFill className="my-auto" color={"gray"} />,
        text: "PENDING",
      },
      // PUBLISHED: {
      //   icon: <IoIosCheckmarkCircle className="my-auto" color={"green"} />,
      //   text: "Published",
      // },
    };
    const config = statusConfig[status];
    if (config) {
      return (
        <>
          <div className="flex justify-center">
            <div className="rounded-2xl border-gray-700 border flex w-fit px-2">
              {config.icon}
              <span className="pl-1">{config.text}</span>
            </div>
            <div className="flex px-1 w-fit px-2 my-auto">
              <MdModeEdit
                className="cursor-pointer"
                onClick={() => setOpenAllStatus(row?._id)}
              />
            </div>
          </div>
          {openAllStatus && openAllStatus === row?._id ? (
            <>
              <div className="flex justify-center pt-2 gap-2">
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleStatusUpdate("ENABLED", row._id)}
                >
                  Enabled
                </div>
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleStatusUpdate("DISABLED", row._id)}
                >
                  Disabled
                </div>
              </div>
              <div className="flex justify-center pt-1 gap-2">
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleStatusUpdate("PENDING", row._id)}
                >
                  Pending
                </div>
                {/* <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleStatusUpdate("PUBLISHED", row._id)}
                >
                  Published
                </div> */}
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <div className="rounded-2xl border-gray-700 border flex px-1 w-fit">
          <span>{status}</span>
        </div>
      );
    }
  };

  const approvalFormatter = (status, row) => {
    const statusConfig = {
      true: {
        text: "True",
        icon: <IoIosCheckmarkCircle className="my-auto" color={"green"} />,
      },
      false: {
        text: "False",
        icon: <IoIosCloseCircleOutline className="my-auto" color={"red"} />,
      },
    };
    const config = statusConfig[status];
    if (config) {
      return (
        <>
          <div className="flex justify-center">
            <div className="rounded-2xl border-gray-700 border flex w-fit px-2">
              {config.icon}
              <span className="pl-1">{config.text}</span>
            </div>
            <div className="flex px-1 w-fit px-2 my-auto">
              <MdModeEdit
                className="cursor-pointer"
                onClick={() => setOpenAllApproveStatus(row?._id)}
              />
            </div>
          </div>
          {openAllApproveStatus && openAllApproveStatus === row?._id ? (
            <>
              <div className="flex justify-center pt-2 gap-2">
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleApproveStatusUpdate("true", row._id)}
                >
                  True
                </div>
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleApproveStatusUpdate("false", row._id)}
                >
                  False
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <div className="rounded-2xl border-gray-700 border flex px-1 w-fit">
          <span>{status}</span>
        </div>
      );
    }
  };
  return (
    <Box m={2} pb={4}>
      <PageHeading
        heading={"Care Flows"}
        icon={<Add />}
        iconText={"Create New WorkFlow"}
        iconClick={() => {
          navigate("/create-work-flow");
        }}
      />
      {isLoading ? (
        <div className="flex justify-center py-24">
          <CircularProgress className="m-auto purple-text" />
        </div>
      ) : (
        <TableLayout
          columns={columns ?? []}
          rows={scenarioList ?? []}
          filters={[]}
        />
      )}
    </Box>
  );
};
export default Scenarios;
