import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import "./common.scss";
import { getRequest } from "../../utils/request";
import { CardHeader, CircularProgress, IconButton } from "@mui/material";
import { ResponsiveContainer } from "recharts";
import TypographyWrapper from "../../mui-wrappers/mui-components/TypographyWrapper";
import { BarChartOutlined } from "@mui/icons-material";
import { LineChart, PieChart } from "@mui/x-charts";

const RenderHomePageChart = ({ chartObj }) => {
  const { selectedSpace } = chartObj;
  const { xAxis } = chartObj;
  const { chartType } = chartObj;

  const spaceID = selectedSpace?._id;

  const [space, setSpace] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fields, setFields] = useState(null);
  const [name, setName] = useState(null);
  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (spaceID) fetchData();
  }, [chartObj]);

  const fetchData = async () => {
    try {
      const response = await getRequest(
        `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customtable/${spaceID}`
      );
      const { customTables } = response.data;

      const chartData = getChartData(customTables, xAxis?.key, chartType);
      setXAxisData(chartData?.xAxisData);
      setYAxisData(chartData?.yAxisData);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const getChartData = (data, groupByKey, chartType) => {
    // Reduce data to calculate counts grouped by the specified key
    const groupedData = data.reduce((acc, item) => {
      const key = item[groupByKey];
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});

    // Extract x-axis (keys) and y-axis (counts) for bar chart
    const xAxisData = Object.keys(groupedData);
    const yAxisData = Object.values(groupedData);

    if (chartType === "pieChart") {
      // Transform yAxisData for pieChart
      const pieChartData = xAxisData.map((label, index) => ({
        id: index,
        value: groupedData[label],
        label,
      }));
      return { yAxisData: pieChartData };
    }

    if (chartType === "lineChart") {
      debugger
      // Group data by date (specific key for lineChart)
      const groupedByDate = data.reduce((acc, item) => {
        const key = item[groupByKey]; // Replace `date` with your date key
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});

      const lineChartXAxis = Object.keys(groupedByDate);
      const lineChartYAxis = Object.values(groupedByDate);

      return { xAxisData: lineChartXAxis, yAxisData: lineChartYAxis };
    }

    // Default behavior: bar chart
    return { xAxisData, yAxisData };
  };

  return (
    <>
      {isLoading ? (
        <ResponsiveContainer
          width={"100%"}
          height={"80%"}
          className="text-center content-center cursor-pointer"
        >
          <CircularProgress sx={{ color: "#CF7447" }} />
        </ResponsiveContainer>
      ) : (
        <>
          <CardHeader
            title={
              <TypographyWrapper variant="h6">
                {selectedSpace?.common_name}
              </TypographyWrapper>
            }
            subheader={
              <TypographyWrapper variant="subtitle2">
                Grouped by: {xAxis?.name}
              </TypographyWrapper>
            }
            avatar={
              <IconButton aria-label="sales" size="large">
                <BarChartOutlined />
              </IconButton>
            }
          />
          <ResponsiveContainer
            width={"100%"}
            height={"75%"}
            className="text-center content-center cursor-pointer"
          >
            {chartType === "pieChart" ? (
              <PieChart series={[{ data: yAxisData }]} />
            ) : chartType === "lineChart" ? (
              <LineChart
                xAxis={[
                  {
                    data: xAxisData,
                    scaleType: "band",
                  },
                ]}
                yAxis={[
                  {
                    min: 0, // Set minimum value for the y-axis
                  },
                ]}
          
                series={[{ data: yAxisData, area: true }]}
              />
            ) : (
              <BarChart
                xAxis={[
                  {
                    scaleType: "band",
                    data: xAxisData,
                    tickPlacement: "middle",
                    tickLabelPlacement: "middle",
                  },
                ]}
                series={[{ data: yAxisData, color: "#3339F8" }]}
              />
            )}
          </ResponsiveContainer>
        </>
      )}
    </>
  );
};

export default RenderHomePageChart;
