import {
  Box,
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { isTokenValid } from "../../../utils/sessionutils";
import { getRequest, postRequest } from "../../../utils/request";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAlert } from "../../../store/context/AlertContext";
import { CustomCard } from "../../../components/custom/CustomCard";
import { Payment } from "@mui/icons-material";
import CustomButton from "../../../components/custom/CustomButton";
import { GrStatusDisabled, GrStatusGood, GrStatusInfo } from "react-icons/gr";

export const Payments = () => {
  const userData = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [enabled, setenabled] = useState(false);
  const [serverError, setServerError] = useState(true);
  const [request, setRequest] = useState(null);
  const location = useLocation();
  const { handleAlert } = useAlert();

  const getOrgStatus = async () => {
    try {
      const response = await getRequest(
        `${process.env.REACT_APP_PAYMENT_SERVER}/organization/status`
      );
      if (response.data.status === "enabled") {
        setenabled(true);
      } else {
        setenabled(false);
      }
      setServerError(false);
      setLoading(false);
      setRequest(true);
      return true;
    } catch (e) {
      console.log(e);
      setServerError(true);
    }
    setRequest(true);
  };
  const enablePayments = async () => {
    setLoading(true);
    console.log("Send to stripe service ");
    try {
      const response = await postRequest(
        `${process.env.REACT_APP_PAYMENT_SERVER}/organization/`,
        {}
      );
      pollOrgStatus();
      window.open(response.data.url, "_blank", "noopener,noreferrer");
    } catch (e) {
      console.log(e);
    }
    // setLoading(false);
  };
  const pollOrgStatus = async () => {
    while (true) {
      console.log("loop");
      const response = await getOrgStatus();
      if (response) {
        console.log("exit");
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  };
  useEffect(() => {
    if (location.hash === "#registration=success") {
      handleAlert(
        "Registration Successful... It may take some time to reflect on your account",
        "success"
      );
      setLoading(true);
    }
    if (location.hash === "#link_invalid") {
      handleAlert("Link Expired or Invalid", "error");
      setLoading(true);
    }
    isTokenValid();
    getOrgStatus();
  }, []);
  return (
    <Stack spacing={4}>
      <Typography variant="h4">Payments</Typography>
      <CustomCard sx={{ p: 2 }}>
        <CardHeader
          title={"Status"}
          titleTypographyProps={{ variant: "h6" }}
          avatar={enabled ? <GrStatusGood /> : <GrStatusDisabled />}
        />
        <CardContent>
          {/* <Paper sx={{ margin: "20px", padding: 5 }} variant="outlined"> */}
          {!request ? (
            <CircularProgress />
          ) : serverError ? (
            <Typography>
              Something went wrong... Try again after sometime
            </Typography>
          ) : enabled ? (
            <Typography>Payments is enabled</Typography>
          ) : !userData?.roles?.includes("owner") ? (
            <Typography>
              Your Organization needs to create a stripe connect account in
              order to collect payments through our platform. Please contact the
              owner to enable payments
            </Typography>
          ) : (
            <Box
              display={"flex"}
              flexDirection={"row"}
              // alignItems={"flex-end"}

              justifyContent={"space-between"}
            >
              <Typography width={"70%"}>
                You need to create a stripe connect account to recieve payments
                on our platform
              </Typography>
              <Box display="flex" justifyContent="center" alignItems={"center"}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <CustomButton
                    width={"30%"}
                    disabled={loading}
                    onClick={enablePayments}
                  >
                    Get Started
                  </CustomButton>
                )}
                {/* {url && (
          <Typography>
            <a href={url} target="_blank">
              Click here to setup your stripe account
            </a>
          </Typography>
        )} */}
              </Box>
            </Box>
          )}
          {/* </Paper> */}
        </CardContent>
      </CustomCard>
    </Stack>
  );
};
