import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./common.scss";

const InputSingleCheckBox = ({
  data = { key: "", label: "", answer: { value: [] } }, // Default fallback for data
  setAnswer, // Callback to set the answer
  formValidation = true, // Default form validation state
  validationMessage = "Required Field", // Custom validation message
  renderCheckbox, // Custom render function for the checkbox
  className = "", // Custom class for the container
  checkboxClassName = "inp-cbx", // Custom class for the checkbox input
  labelClassName = "form-title pl-3", // Custom class for the label
  disabled = false, // Option to disable the checkbox
  ...rest
}) => {
  const [selectedList, setSelectedList] = useState([]);
  const [validForm, setFormValidation] = useState(formValidation);

  const handleCheckboxClick = (value) => {
    let updatedSelectedList = [...selectedList];
    if (updatedSelectedList.includes(value)) {
      updatedSelectedList.splice(updatedSelectedList.indexOf(value), 1);
    } else {
      updatedSelectedList.push(value);
    }
    setSelectedList(updatedSelectedList);
    if (setAnswer) {
      setAnswer(data, { value: updatedSelectedList });
    }
    setFormValidation(true);
  };

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer?.value) {
      setSelectedList(data.answer.value);
    }
  }, [formValidation, data]);

  return (
    <div className={`w-full single-checkbox ${className}`} {...rest}>
      <form>
        <div className={`checkbox-wrapper-4 radio-common ${disabled ? "disabled" : ""}`}>
          {renderCheckbox ? (
            renderCheckbox(data, selectedList, handleCheckboxClick)
          ) : (
            <>
              <input
                className={checkboxClassName}
                id={data.key}
                name={data.key}
                value={data.key}
                checked={selectedList.includes(data.key)}
                onChange={() => handleCheckboxClick(data.key)}
                disabled={disabled}
                type="checkbox"
              />
              <label className="cbx" htmlFor={data.key}>
                <span>
                  <svg width="12px" height="10px">
                    <use xlinkHref={`#check${data.key}`} />
                  </svg>
                </span>
                <span className={labelClassName}>{data.label}</span>
              </label>
              <svg className="inline-svg">
                <symbol id={`check${data.key}`} viewBox="0 0 12 10">
                  <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                </symbol>
              </svg>
            </>
          )}
        </div>
        {!validForm && (
          <div className="text-red-700 font-light text-xs mt-2">
            {validationMessage}
          </div>
        )}
      </form>
    </div>
  );
};

// PropTypes for validation
InputSingleCheckBox.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    answer: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.any),
    }),
  }),
  setAnswer: PropTypes.func, // Function to handle answer selection
  formValidation: PropTypes.bool, // Initial form validation state
  validationMessage: PropTypes.string, // Validation message for invalid form
  renderCheckbox: PropTypes.func, // Custom render function for checkbox
  className: PropTypes.string, // Custom class for container
  checkboxClassName: PropTypes.string, // Custom class for checkbox input
  labelClassName: PropTypes.string, // Custom class for label
  disabled: PropTypes.bool, // Disable the checkbox
};

// Default Props for fallback
InputSingleCheckBox.defaultProps = {
  data: { key: "", label: "", answer: { value: [] } },
  setAnswer: null,
  formValidation: true,
  validationMessage: "Required Field",
  renderCheckbox: null,
  className: "",
  checkboxClassName: "inp-cbx",
  labelClassName: "form-title pl-3",
  disabled: false,
};

export default InputSingleCheckBox;
