import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { readCookie, startSession } from "../../utils/sessionutils";
import { postRequest } from "../../utils/request";
import { useLocation, useNavigate } from "react-router-dom";
import CustomInput from "../../components/custom/CustomInput";
import CustomButton, {
  CustomTextButton,
} from "../../components/custom/CustomButton";

const MfaLogin = ({ type, email, remember }) => {
  useEffect(() => {
    type !== "APP" && sendOtp();
    readCookie();
  }, [type]);
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [waiting, setWaiting] = useState(false);
  const handleSubmit = async (e) => {
    setWaiting(true);
    e.preventDefault();
    const data = {
      email: email,
      type: type,
      token: otp,
      remember: remember,
    };
    try {
      const response = await postRequest("mfa/validateotp", data, {
        withCredentials: true,
      });
      console.log(response);
      await startSession(response.data.token);
      if (location.state?.from) {
        navigate(location.state.from);
      }
    } catch (e) {
      console.log(e);
      setError(e.response?.data?.message ?? "Something went wrong");
      // alert(
      //   e.response.data.message
      //     ? e.response.data.message
      //     : e.response.data.err.data.message
      // );
    }
    setWaiting(false);
  };

  const sendOtp = async (e) => {
    setWaiting(true);
    const data = {
      email: email,
      type: type,
    };
    try {
      const response = await postRequest("mfa/generateotp", data);
      console.log(response);
    } catch (e) {
      console.log(e);
      // alert(
      //   e.response.data.message
      //     ? e.response.data.message
      //     : e.response.data.err.data.message
      // );
    }
    setWaiting(false);
  };
  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        // noValidate
        sx={{ mt: 1 }}
      >
        <Typography>
          {type === "APP"
            ? "Please enter otp from Authenticator app "
            : `Please enter the otp send to you in ${type}`}
        </Typography>
        <CustomInput
          margin="normal"
          required
          fullWidth
          value={otp}
          onChange={(e) => {
            setOtp(e.target.value);
          }}
          error={!!error}
          helperText={error}
          id="otp"
          labelText="OTP"
          name="otp"
          autoComplete="otp"
          autoFocus
        />
        {type !== "APP" && (
          <CustomTextButton disabled={waiting} onClick={sendOtp}>
            Resend OTP
          </CustomTextButton>
        )}
        <CustomButton
          disabled={waiting}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {waiting ? <CircularProgress size={25} /> : "Sign In"}
        </CustomButton>
      </Box>
    </>
  );
};
export default MfaLogin;
