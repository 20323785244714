export const SET_LOGIN = "SET_LOGIN";
export const ADD_INTEGRATION = "ADD_INTEGRATION";
export const SET_APPOINTMENT = "SET_APPOINTMENT ";
export const SET_USER = "SET_USER";
export const setLogin = (islogin) => (dispatch) => {
  dispatch({
    type: SET_LOGIN,
    payload: islogin,
  });
};

export const addIntegration = (integration) => (dispatch) => {
  dispatch({
    type: ADD_INTEGRATION,
    payload: integration,
  });
};

export const setAppointment = (appointment) => (dispatch) => {
  dispatch({
    type: SET_APPOINTMENT,
    payload: appointment,
  });
};

export const setUser = (user) => (dispatch) => {
  dispatch({
    type: SET_USER,
    payload: user,
  });
};
