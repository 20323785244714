import {
  Box,
  CardContent,
  CardHeader,
  Fab,
  Modal,
  Stack,
  Typography,
  Divider,
  InputAdornment,
  TableRow,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableBody,
  IconButton,
} from "@mui/material";
import { getRequest } from "../../../utils/request";
import { useEffect, useState } from "react";
import {
  Delete,
  Email,
  EmailOutlined,
  GroupAdd,
  Key,
  MoreHoriz,
  Person,
} from "@mui/icons-material";
import CreateInvite from "./AddMember";
import { isTokenValid } from "../../../utils/sessionutils";
import { useSelector } from "react-redux";
import { CustomCard } from "../../../components/custom/CustomCard";
import CustomInput from "../../../components/custom/CustomInput";

export const Members = () => {
  const userData = useSelector((state) => state.user);
  const [members, setMembers] = useState(null);
  const [addingMember, setAddingMenmber] = useState(false);
  const getMembers = async () => {
    try {
      const response = await getRequest("organization/members");
      setMembers(response.data.members);
      console.log(response.data.members);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    isTokenValid();
    getMembers();
  }, []);
  return (
    <Box>
      <Stack spacing={4}>
        <Typography variant="h4">Members</Typography>
        <CustomCard sx={{ p: 2 }}>
          <CardHeader
            title={"Invite Members"}
            titleTypographyProps={{ variant: "body1" }}
            avatar={<GroupAdd />}
          />
          <CardContent>
            <Stack spacing={2}>
              {userData?.roles?.includes("owner") && <CreateInvite />}
              <Stack spacing={2}>
                <TableContainer>
                  <Table sx={{ width: "100%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Member</TableCell>
                        <TableCell>Roles</TableCell>
                        {userData?.roles?.includes("owner") && (
                          <TableCell>
                            <MoreHoriz />
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {members &&
                        members.map((member, index) => (
                          // <Paper
                          //   key={"paper" + index}
                          //   sx={{ margin: "20px", padding: 5 }}
                          //   variant="outlined"
                          // >
                          <TableRow>
                            <TableCell>
                              <Stack>
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  spacing={1}
                                >
                                  <Person />
                                  <Stack>
                                    <Typography key={"text1" + index}>
                                      {member.firstname} {member.lastname}
                                    </Typography>
                                    <Typography key={"text2" + index}>
                                      {member.email}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              {member?.roles ? member.roles.join(", ") : ""}
                            </TableCell>
                            {userData?.roles?.includes("owner") && (
                              <TableCell>
                                <IconButton>
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      {/* <CustomCard>
                    <CardContent>
                      <Typography key={"text1" + index}>
                        Name: {member.firstname} {member.lastname}
                      </Typography>
                      <Typography key={"text2" + index}>
                        Email: {member.email}
                      </Typography>
                      <Typography key={"text3" + index}>
                        Role: {member.roles}
                      </Typography>
                    </CardContent>
                  </CustomCard> 
                  // </Paper>*/}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Stack>
          </CardContent>
        </CustomCard>
      </Stack>
    </Box>
  );
};
