import React from "react";
import DocsHeader from "./header";
import DocsSidebar from "./sidebar";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";

const markdownRoot = `
\`\`\`js
<div id="unique-id"></div> 
\`\`\`
`;
const markdownHTML = `
\`\`\`js
<div id="myChart" style="width: 100%; height: 400px;"></div>
\`\`\`
`;

const markdownJS = `
\`\`\`js
 <script>
    if (window.renderHbxChart) {
      window.renderHbxChart('myChart', props);
    }
  </script>
`;

const RootElement = () => {
  return (
    <div>
      <DocsHeader />
      <div className="flex flex-row gap-4">
        <DocsSidebar />
        <div className="grow px-3">
          {" "}
          <div className="py-2 border-b block border-b-gray-400 text-lg text-violet-800">
            V1
          </div>
          <div className="py-3 text-3xl">Quick Start Guide</div>
          <div className="pt-3 pb-1 text-2xl font-semibold">
            Creating a chart
          </div>
          <div className="pt-3 pb-1 text-xl font-semibold">Root element</div>
          <div className="text-lg pb-2">
            The central object of each chart starts with a central object. We
            call it "the root".
          </div>
          <div className="text-lg pb-2">
            The root is super important, as you will need it to create every
            single object in the chart.
          </div>
          <div className="text-lg pb-2">Let's create one right now:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {markdownRoot}
            </ReactMarkdown>
          </div>
          <div className="text-lg pb-2">
            Root is part of our core package, so we use unique id to define and
            differentiate every charts in some unique way.
          </div>
          <div className="text-lg pb-2">
            Final notice about creating a "root" element, is that we need to
            pass in an id of the div container we want to put our chart in. The
            parameter can also accept a reference of the actual element, too.
          </div>
          <div className="pt-3 pb-1 text-xl font-semibold">HTML Setup</div>
          <div className="text-lg pb-2">
            First, create an HTML container where your chart will be rendered.
            You can define a div or canvas element like so:
          </div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {markdownHTML}
            </ReactMarkdown>
          </div>
          <div className="pt-3 pb-1 text-xl font-semibold">
            JavaScript Initialization
          </div>
          <div className="text-lg pb-2">
            Once the HTML is set up, initialize the chart in JavaScript. After
            the page has loaded, you can create a new chart instance using the
            library's API.
          </div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {markdownJS}
            </ReactMarkdown>
          </div>
          <div className="pt-3 pb-1 text-2xl font-semibold">
            Common Issues & TroubleshootingNo Chart Display
          </div>
          <div className="pt-3 pb-1 text-xl font-semibold">
            No Chart Display
          </div>
          <div className="text-lg pb-2">
            Ensure that the chart's container (e.g., div or canvas) has a
            defined width and height.
          </div>
          <div className="text-lg pb-2">
            Double-check that you've correctly passed the props parameter and a
            valid licence key.
          </div>
          <div className="pt-3 pb-1 text-xl font-semibold">
            Chart Not Updating
          </div>
          <div className="text-lg pb-2">
            If the chart is not updating after changing the data, ensure that
            you are calling chart.update() to re-render the chart with the new
            data.
          </div>
        </div>
      </div>
    </div>
  );
};
export default RootElement;
