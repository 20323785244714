import { theme } from "./CustomInput";
import ButtonWrapper from "../../mui-wrappers/mui-components/ButtonWrapper";
import TypographyWrapper from "../../mui-wrappers/mui-components/TypographyWrapper";

const { ThemeProvider } = require("@mui/material");
const CustomButton = ({ children, color, variant, font, ...rest }) => {
  return (
    <ThemeProvider theme={theme}>
      <ButtonWrapper variant={variant ?? "contained"} {...rest}>
        {children}
      </ButtonWrapper>
    </ThemeProvider>
  );
};

export default CustomButton;

export const CustomButtonIcon = ({ logo, variant, text, ...rest }) => {
  return (
    <ThemeProvider theme={theme}>
      <ButtonWrapper
        variant={variant ?? "outlined"}
        p={"5px 15px 5px 15px"}
        endIcon={<img src={logo} alt="logo" style={{ height: 20 }} />}
        {...rest}
      >
        <TypographyWrapper variant="body1">{text}</TypographyWrapper>
      </ButtonWrapper>
    </ThemeProvider>
  );
};

export const CustomTextButton = ({ children, variant, sx, ...rest }) => {
  return (
    <TypographyWrapper
      variant={variant ?? "body2"}
      sx={{ ...sx, color: "#CF7447", cursor: "pointer" }}
      {...rest}
    >
      {children}
    </TypographyWrapper>
  );
};
