import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import "./schedulerWidget.css";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { getAuthToken } from "../../../utils/cookie";
import axios from "axios";
import { Skeleton } from "@mui/material";


const SchedulerWidget = ({ widget }) => {
  const [scheduler_status, setScheduler_status] = useState('ENABLED');
  const [isLoading, setIsLoading] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "Widget",
    item: { widget, type: "scheduler-widget"},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  
  useEffect(() => {
    setIsLoading(false)
    widget.scheduler_status = scheduler_status
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton
            variant="rectangular"
            width={250}
            height={150}
            className="rounded"
          />
        </>
      ) : (
        <>
          <div
              ref={drag}
              style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: "move",
              }}
              className="card w-64 h-32 custom-card  my-1 p-2 cursor-pointer"
            >
              <div className="text-center font-semibold ">
                {widget.label}
              </div>
            </div>
        </>
      )}
    </>
  );
};

export default SchedulerWidget;
