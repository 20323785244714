import React from "react";
import ShareIcon from "@mui/icons-material/Share";
import { Typography } from "@mui/material";
import { CardLayout } from "./CardLayout";

export const Tickets = () => {
  return (
    <CardLayout icon={<ShareIcon />} increase={"15"}>
      <Typography variant="overline">Workflows run</Typography>
      <Typography variant="h4">31</Typography>
    </CardLayout>
  );
};
