import { Editor } from "@monaco-editor/react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useState, useRef } from "react";
import { saveAs } from "file-saver";

const Code = () => {
  const [text, setText] = useState(
    window.localStorage.getItem("code")
      ? window.localStorage.getItem("code")
      : "Enter your code here..."
  );
  const [language, setLanguage] = useState("python");
  const [mode, setMode] = useState("vs-dark");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        setText(content);
        window.localStorage.setItem("code", content);
        console.log(text);
      };
      reader.readAsText(selectedFile);
    }
  };

  const saveFile = () => {
    console.log("saving file");
    console.log(text);
    window.localStorage.setItem("code", text);
    const fileName = window.prompt("Enter the file name:", "untitled.txt");

    if (fileName) {
      const blob = new Blob([text], { type: "text/plain;charset=utf-8" });
      saveAs(blob, fileName);
    }
  };

  const openFile = () => {
    console.log("opening file");
    fileInputRef.current.click();
  };

  const reset = () => {
    console.log("clearing");
    setText("Enter your code here...");
    window.localStorage.setItem("code", "Enter your code here...");
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent={"space-between"} paddingY={2}>
        <FormControl fullWidth style={{ marginRight: "10px" }}>
          <InputLabel id="language-select-label">Language </InputLabel>
          <Select
            labelId="language"
            id="language"
            style={{ height: "40px", width: "100%" }}
            value={language}
            label="Language"
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          >
            <MenuItem value={"python"}>Python</MenuItem>
            <MenuItem value={"cpp"}>C++</MenuItem>
            <MenuItem value={"javascript"}>Javascript</MenuItem>
          </Select>
        </FormControl>

        {/* <Box display="flex" justifyContent={"space-between"} p={2}>
            <Typography>Mode: </Typography> */}

        <FormControl fullWidth>
          <InputLabel id="theme-select-label">Theme </InputLabel>
          <Select
            labelId="mode"
            id="mode"
            style={{ height: "40px", width: "100%" }}
            value={mode}
            label="Mode "
            onChange={(e) => {
              setMode(e.target.value);
            }}
          >
            <MenuItem value={"vs"}>Light</MenuItem>
            <MenuItem value={"vs-dark"}>Dark</MenuItem>
            <MenuItem value={"hc-black"}>High Contrast</MenuItem>
          </Select>
        </FormControl>

        {/* </Box> */}
        <Button onClick={reset}>RESET</Button>
        <Button onClick={openFile}>OPEN</Button>
        <Button onClick={saveFile}>SAVE</Button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept=".txt"
          onChange={handleFileChange}
        />
      </Box>
      <Box display="flex" justifyContent={"space-between"} height="65vh">
        <Box width="25%" sx={{ backgroundColor: "grey" }}></Box>
        <Editor
          defaultLanguage={language}
          theme={mode}
          //   defaultValue={text}
          value={text}
          onChange={(value) => {
            setText(value);
            window.localStorage.setItem("code", value);
          }}
        />
      </Box>
    </Box>
  );
};
export default Code;
