import { useState, useEffect } from "react";
import "./common.scss";

const InputText = ({
  value = "", // Controlled value
  onChange, // Change handler
  label,
  placeholder = "",
  isValid = true, // Prop for validation state
  required = false, // Flag for required field
  className = "", // Additional classes
  ...props // Other native input props
}) => {
  const [internalValue, setInternalValue] = useState(value);

  // Sync the internal value with external changes
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInternalValue(newValue);
    if (onChange) {
      onChange({value: newValue}); // Call the external change handler
    }
  };

  return (
    <div className={`w-full ${className}`}>
      {label && (
        <label className="form-title pt-3">
          {label}
          {required && (
            <span className={isValid ? "" : "text-red-700"}> * </span>
          )}
        </label>
      )}
      <input
        type="text"
        value={internalValue}
        onChange={handleChange}
        placeholder={placeholder}
        className={`text-field text-input ${!isValid ? "border-red-700" : ""}`} // Conditionally apply error class
        aria-invalid={!isValid} // Accessibility
        {...props} // Spread other props for flexibility
      />
      {required && !isValid && (
        <span className="text-red-700 font-light text-xs">Required Field</span>
      )}
    </div>
  );
};

export default InputText;
