export const isWeakPassword = (password) => {
  if (password.length < 8) {
    return "Too short";
  }
  // Contains at least one lowercase letter
  if (!/[a-z]/.test(password)) {
    return "Password should contain atleast 1 lowercase letter";
  }
  // Contains at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    return "Password should contain atleast 1 upercase letter";
  }
  // Contains at least one digit
  if (!/\d/.test(password)) {
    return "Password should contain atleast 1 digit";
  }
  // Contains at least one special character
  if (!/[^a-zA-Z0-9]/.test(password)) {
    return "Password should contain atleast 1 special character";
  }
  return null;
};
