import React from "react";
import DocsHeader from "./header";
import DocsSidebar from "./sidebar";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";

const licenceKeyMarkdown = `
\`\`\`js
const licenceKey="your-licence-key"

//react.jsx
<YourPlot licenceKey={licenceKey} />
\`\`\`
`;
const datasetMarDown = `
\`\`\`js
[10, 20, 30, 40, 50]
\`\`\`
`;
const annotationsMarkdown = `
\`\`\`js
{
  "1": "Annotation for second data point",
  "3": "Annotation for fourth data point"
}
\`\`\`
`;
const dataMarkdown = `
\`\`\`js
const data = {
  dataSet: [10, 20, 30, 40, 50],
  annotationsData: {
    1: "Annotation for second data point",
    3: "Annotation for fourth data point",
  },
};

<YourPlot data={data} />
\`\`\`
`;
const lineColorMarkdown = `
\`\`\`js
{
  "r": 255,
  "g": 0,
  "b": 0,
  "a": 1
}
\`\`\`
`;
const bgColorMarkdown = `
\`\`\`js
{
  "r": 255,
  "g": 255,
  "b": 255,
  "a": 1
}
\`\`\`
`;
const axisColorMarkdown = `
\`\`\`js
{
  "r": 0,
  "g": 255,
  "b": 255,
  "a": 1
}
\`\`\`
`;
const annotColorMarkdown = `
\`\`\`js
{
  "r": 0,
  "g": 0,
  "b": 255,
  "a": 1
}
\`\`\`
`;
const highlightColorMarkdown = `
\`\`\`js
{
  "r": 245,
  "g": 155,
  "b": 0,
  "a": 1
}
\`\`\`
`;
const designMarkdown = `
\`\`\`js
const design = {
  lineColor: { r: 255, g: 0, b: 0, a: 1 },
  bgColor: { r: 255, g: 255, b: 255, a: 1 },
  axisColor: { r: 0, g: 0, b: 0, a: 1 },
  annotDotColor: { r: 0, g: 255, b: 0, a: 1 },
  highlightsColor: { r: 255, g: 255, b: 0, a: 0.5 },
  toolTip: {
    title: "Time",
    desc: "Value",
  },
};

<YourPlot design={design} />
\`\`\`
`;
const xAxisFormatterMarkdown = `
\`\`\`js
(value) => \`Day \\\${value}\`
\`\`\`
`;
const yAxisFormatterMarkdown = `
\`\`\`js
(value) => \`\\\${value}%\`
\`\`\`
`;
const formattersMarkdown = `
\`\`\`js
const formatters = {
  xAxisFormatter: (value) => \`Day \\\${value}\`,
  yAxisFormatter: (value) => \`\\\${value}%\`,
};

<YourPlot formatters={formatters} />
\`\`\`
`;
const outputMarkdown = `
\`\`\`js
const handleOutput = ({ dataArray, annotMap }) => {
  console.log("Current data:", dataArray);
  console.log("Annotations:", annotMap);
};

<YourPlot output={handleOutput} />
\`\`\`
`;

const HbxCharts = () => {
  return (
    <div>
      <DocsHeader />
      <div className="flex flex-row gap-4">
        <DocsSidebar />
        <div className="grow px-3">
          <div className="py-2 border-b block border-b-gray-400 text-lg text-violet-800">
            V1
          </div>
          <div className="py-3 text-3xl">Line Graph</div>
          <div className="text-xl">
            Line Graph chart is basically used to represent any linear or
            scatter data in two dimensions. This tutorial will get you started
            in creating these charts and all the props and apis integrated with
            that.
          </div>
          <div className="pt-3 pb-1 text-2xl font-semibold">
            Props Overview:
          </div>
          <div className="text-lg py-1">
            <div className="py-1">
              <span className="bg-gray-300 rounded px-1">licenceKey:</span>
              <span className="italic">{" (String)"}</span>
            </div>
            <div className="py-1">
              <span className="bg-gray-300 rounded px-1">data:</span>
              <span className="italic">{" (Object)"}</span>
            </div>
            <div className="py-1">
              <span className="bg-gray-200 rounded px-1">design:</span>
              <span className="italic">{" (Object)"}</span>
            </div>
            <div className="py-1">
              <span className="bg-gray-300 rounded px-1">formatters:</span>
              <span className="italic">{" (Object)"}</span>
            </div>
            <div className="py-1">
              <span className="bg-gray-300 rounded px-1">canvas:</span>
              <span className="italic">{" (Object)"}</span>
            </div>
            <div className="py-1">
              <span className="bg-gray-300 rounded px-1">output:</span>
              <span className="italic">{" (Function)"}</span>
            </div>
          </div>

          <div className="pt-3 pb-1 text-xl font-semibold">licenceKey</div>
          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> String</span>
          </div>
          <div className="text-lg py-1">
            A string value that represents the license key for using the
            charting library. If this key is invalid or expired, the chart will
            not render, and an error message will be shown.
          </div>
          <div className="text-lg py-1">
            hbx-chart come with a valid licence. Please ensures that the library
            is being used under a valid license.
          </div>
          <div className="text-xl py-1">Usage Example:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {licenceKeyMarkdown}
            </ReactMarkdown>
          </div>

          <div className="pt-3 pb-1 text-xl font-semibold">data</div>
          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Object</span>
          </div>
          <div className="text-lg py-1">
            This object contains the actual data that will be plotted on the
            graph, along with any annotations that should appear on the chart.
          </div>

          <div className="text-lg py-1">
            This object consists of two main properties:
          </div>

          <div className="text-lg pt-1">
            <span className="font-semibold">dataSet: </span>
            <span>
              An array of numerical values representing the data points.
            </span>
          </div>
          <div className="text-lg pt-1">
            <span className="font-semibold">annotationsData: </span>
            <span>
              An object where the keys represent the data index, and the values
              represent annotations for those points.
            </span>
          </div>

          <div className="text-lg pt-3 font-semibold">dataSet</div>

          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Array of Numbers</span>
          </div>
          <div className="text-lg py-1">
            An array representing the data to be plotted on the chart. Each
            value corresponds to a point on the X-axis, with the index
            determining its X-position and the value itself determining its
            Y-position.
          </div>

          <div className="text-xl py-1">Usage Example:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {datasetMarDown}
            </ReactMarkdown>
          </div>

          <div className="text-lg pt-3 font-semibold">annotationsData</div>

          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Object</span>
          </div>
          <div className="text-lg py-1">
            A key-value object where the keys represent the index of the
            dataSet, and the value represents the annotation for that point.
          </div>

          <div className="text-xl py-1">Usage Example:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {annotationsMarkdown}
            </ReactMarkdown>
          </div>
          <div className="text-xl py-2">data Example</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {dataMarkdown}
            </ReactMarkdown>
          </div>

          <div className="pt-3 pb-1 text-xl font-semibold">design</div>
          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Object</span>
          </div>
          <div className="text-lg py-1">
            This object allows you to customize the appearance of the chart,
            including colors for lines, background, axes, annotations, and
            highlights.
          </div>

          <div className="text-lg font-semibold">lineColor</div>

          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Object (RGBA format)</span>
          </div>
          <div className="text-lg py-1">
            Defines the color of the plotted line. It is passed as an RGBA
            object with values ranging from 0 to 255 for r, g, b, and a (alpha).
          </div>

          <div className="text-xl py-1">Usage Example:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {lineColorMarkdown}
            </ReactMarkdown>
          </div>

          <div className="text-lg font-semibold">bgColor</div>

          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Object (RGBA format)</span>
          </div>
          <div className="text-lg py-1">
            Defines the background color of the chart. Same format as lineColor.{" "}
          </div>

          <div className="text-xl py-1">Usage Example:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {bgColorMarkdown}
            </ReactMarkdown>
          </div>

          <div className="text-lg font-semibold">axisColor</div>

          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Object (RGBA format)</span>
          </div>
          <div className="text-lg py-1">
            Defines the color of the X and Y axes. Same format as lineColor.
          </div>

          <div className="text-xl py-1">Usage Example:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {axisColorMarkdown}
            </ReactMarkdown>
          </div>

          <div className="text-lg font-semibold">annotDotColor</div>

          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Object (RGBA format)</span>
          </div>
          <div className="text-lg py-1">
            Defines the color of annotation dots that appear on specific data
            points.
          </div>

          <div className="text-xl py-1">Usage Example:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {annotColorMarkdown}
            </ReactMarkdown>
          </div>

          <div className="text-lg font-semibold">highlightsColor</div>

          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Object (RGBA format)</span>
          </div>
          <div className="text-lg py-1">
            Defines the color of highlighted sections of the chart.
          </div>

          <div className="text-xl py-1">Usage Example:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {highlightColorMarkdown}
            </ReactMarkdown>
          </div>
          <div className="text-xl py-2">design Example</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {designMarkdown}
            </ReactMarkdown>
          </div>

          <div className="pt-3 pb-1 text-xl font-semibold">formatters</div>
          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Object</span>
          </div>
          <div className="text-lg py-1">
            Provides custom formatter functions for formatting the X and Y axes
            values.
          </div>

          <div className="text-lg font-semibold">xAxisFormatter</div>

          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Function</span>
          </div>
          <div className="text-lg py-1">
            Formats the values on the X-axis. Takes a numerical value as an
            input and returns a formatted string or number.
          </div>

          <div className="text-xl py-1">Usage Example:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {xAxisFormatterMarkdown}
            </ReactMarkdown>
          </div>

          <div className="text-lg font-semibold">yAxisFormatter</div>

          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Function</span>
          </div>
          <div className="text-lg py-1">
            Formats the values on the Y-axis. Takes a numerical value as an
            input and returns a formatted string or number.
          </div>

          <div className="text-xl py-1">Usage Example:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {yAxisFormatterMarkdown}
            </ReactMarkdown>
          </div>
          <div className="text-xl py-2">formatters Example </div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {formattersMarkdown}
            </ReactMarkdown>
          </div>

          <div className="pt-3 pb-1 text-xl font-semibold">output</div>
          <div>
            <span className="bg-gray-300 rounded px-1">Type:</span>
            <span> Function</span>
          </div>
          <div className="text-lg py-1">
            A callback function that is triggered to output the current state of
            the chart. This function can be used to retrieve the data points and
            annotations currently displayed.
          </div>
          <div className="text-xl py-2">output Example </div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {outputMarkdown}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HbxCharts;
