import React from "react";
import DocsHeader from "./header";
import DocsSidebar from "./sidebar";

const Docs = () => {
  return (
    <div>
      <DocsHeader />
      <div className="flex flex-row gap-4">
        <DocsSidebar />
        <div className="grow px-3">
          <div className="py-2 border-b block border-b-gray-400 text-lg text-violet-800	">
            V1
          </div>
          <div className="py-3 text-3xl">HBX Charts Documentation</div>
          <div className="py-2 text-xl">Welcome to the <span className="font-semibold">hbx-chart</span> documentation!</div>
          <div className="text-lg pt-1">This guide will walk you through everything you need to know to get started with the hbx-chart JavaScript module. Whether you're looking to visualize data in a dashboard or build custom visualizations, this document will cover all the essentials, from installation to usage examples.</div>
          <div className="text-lg pt-2">Use the navigation on the left to select a topic.</div>
          </div>
      </div>
    </div>
  );
};
export default Docs;
