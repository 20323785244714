import React from "react";

const DocsHeader = () => {
  return (
    <div className="rounded bg-gray-200 p-3 flex gap-20 justify-between my-2">
      <div className="text-3xl">Documentation</div>
      <div className="px-3 flex gap-4 my-auto">
        <div className="text-lg font-semibold">HBX Charts</div>
        <div className="text-xl text-violet-800">v1</div>
      </div>
    </div>
  );
};
export default DocsHeader;
