import {
  Box,
  Button,
  IconButton,
  Input,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addIntegration } from "../../../../store/redux/action";

const CreateIntegration = ({ closeModal }) => {
  const dispatch = useDispatch();
  const integrations = useSelector((state) => state.integration);
  const [name, setName] = useState(null);
  const [platform, setPlatform] = useState("Athena");
  const [primary_id, setPrimary_id] = useState(null);
  const platforms = [
    {
      value: "Athena",
      label: "Athena",
    },
    {
      value: "Cerner",
      label: "Cerner",
    },
    {
      value: "EPIC",
      label: "Epic",
    },
  ];

  const save = () => {
    dispatch(
      addIntegration({
        id:
          integrations.length > 0
            ? integrations[integrations.length - 1].id + 1
            : 1,
        name: name,
        platform: platform,
        primary_id: primary_id,
      })
    );
  };

  return (
    <Box>
      <Box display="flex" justifyContent={"space-between"}>
        <Typography variant="h6">Create Integration</Typography>
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box m={"20px"}>
        <Box display="flex" justifyContent={"space-between"}>
          <Typography variant="h7">Name : </Typography>
          <Input
            style={{ width: "80%" }}
            id="name"
            variant="standard"
            onChange={(e) => {
              setName(e.target.value);
            }}
          ></Input>
        </Box>
        <Box display="flex" justifyContent={"space-between"}>
          <Typography variant="h7">Platform :</Typography>
          <TextField
            style={{ width: "80%" }}
            id="platform"
            select
            onChange={(e) => {
              setPlatform(e.target.value);
            }}
            // label="Select"
            defaultValue="Athena"
            //   helperText="Please select your currency"
            variant="standard"
          >
            {platforms.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box display="flex" justifyContent={"space-between"}>
          <Typography variant="h7">Primary ID : </Typography>
          <Input
            style={{ width: "80%" }}
            id="primary_id"
            variant="standard"
            onChange={(e) => {
              setPrimary_id(e.target.value);
            }}
          ></Input>
        </Box>
      </Box>
      <Box display="flex" justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            save();
            closeModal();
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
export default CreateIntegration;
