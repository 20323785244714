import { Box, Stack, Typography } from "@mui/material";
import EnhancedTable from "./table";

export const Activity = () => {
  return (
    <Stack spacing={4}>
      <Typography variant="h4">Activity</Typography>
      <EnhancedTable />
    </Stack>
  );
};
