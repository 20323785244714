import { Box, CardContent, TextField, Typography, Stack } from "@mui/material";
import { useEffect } from "react";
import { isTokenValid } from "../../../utils/sessionutils";
import { useSelector } from "react-redux";
import { CustomCard } from "../../../components/custom/CustomCard";

export const Role = () => {
  const userData = useSelector((state) => state.user);
  useEffect(() => {
    isTokenValid();
  }, []);
  return (
    <Stack spacing={4}>
      <Typography variant="h4">Roles</Typography>
      <CustomCard>
        <CardContent p={4}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"flex-end"}
            m={2}
          >
            <Typography>Organization</Typography>
            <TextField
              fullWidth
              disabled
              sx={{ paddingLeft: 2 }}
              variant="standard"
              value={userData?.org?.name ?? ""}
            ></TextField>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"flex-end"}
            m={2}
          >
            <Typography>Role:</Typography>
            <TextField
              disabled
              fullWidth
              sx={{ paddingLeft: 2 }}
              variant="standard"
              value={userData?.roles ?? ""}
            ></TextField>
          </Box>
        </CardContent>
      </CustomCard>
    </Stack>
  );
};
