import React from "react";
import DocsHeader from "./header";
import DocsSidebar from "./sidebar";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";

const markdownBundleScript = `
\`\`\`js
//index.html

<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <title>React App</title>
</head>
<body>
  <div id="root"></div>
  <!-- Include your chart bundle -->
  <script src="path/to/hbx-chart-bundle.min.js"></script>
  <script src="https://unpkg.com/react@18/umd/react.production.min.js"></script>
  <script src="https://unpkg.com/react-dom@18/umd/react-dom.production.min.js"></script>
</body>
</html>
\`\`\`
`;
const markdownReact = `
\`\`\`js
import React, { useEffect } from 'react';

const ChartComponent = () => {
  useEffect(() => {
    // renderHbxChart is a global method to render chart library.
   if (window.renderHbxChart) {
      window.renderHbxChart('chart-id', props);
    }
  }, []);
};

export default ChartComponent;

\`\`\`
`;
const markdownDynamicJs = `
\`\`\`js
import React, { useEffect } from 'react';

function MyComponent() {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');

    // Set the source of the script
    script.src = '/path/to/your/script.js';
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // This function is assumed to be inside the external script
  const callRenderHbxChart = () => {
    if (window.renderHbxChart) {
      window.renderHbxChart('chart-id', props);
    } else {
      console.error('renderHbxChart is not defined');
    }
  };

  return (
    <div>
      <h1>Load Chart Script in JSX</h1>
      <button onClick={callRenderHbxChart}>Call RenderHbxChart Function</button>
    </div>
  );
}

export default MyComponent;


\`\`\`
`;
const markdownReactReal = `
\`\`\`js
import React, { useEffect } from 'react';
const demoData = [23, 45, 67, 89, 12, 34, 56, 78, 90, 11, 22, 33, 44, 55, 66, 77, 88, 99, 10, 21, 32,
 43, 54, 65, 76, 87, 98, 19, 28, 37, 46, 57, 68, 79, 80, 91, 20, 31, 42, 53, 64, 75, 86, 97, 18, 27,
 36, 47, 58, 69, 70, 81, 92, 13, 24, 35, 46, 57, 68, 79, 90, 11, 22, 33, 44, 55, 66, 77, 88, 99, 10,
 21, 32, 43, 54, 65, 76, 87, 98, 19, 28, 37, 46, 57, 68, 79, 80, 91, 20, 31, 42, 53, 64, 75, 86, 97,
 18, 27, 36, 47, 58, 69]

const formatNumberToTime = (number) => {
  // Multiply by 10 to convert increments to milliseconds
  const totalMilliseconds = number * 10;

  // Calculate hours, minutes, seconds, and frames
  // Format the numbers to be two digits
  // Combine the parts into a time string
  const hours = Math.floor(totalMilliseconds / 3600000);
  const minutes = Math.floor((totalMilliseconds % 3600000) / 60000);
  const seconds = Math.floor((totalMilliseconds % 60000) / 1000);
  const frames = Math.floor((totalMilliseconds % 1000) / 10);
  return [hours, minutes, seconds, frames]
    .map((val) => String(val).padStart(2, "0"))
    .join(":");
};

const props = {
  data: {
    dataSet: demoData,
    annotationsData: {},
    highlightsData: {},
  },
  design: {
    lineColor: {
      r: 125,
      g: 249,
      b: 255,
      a: 1,
    },
    bgColor: {
      r: 0,
      g: 0,
      b: 0,
      a: 1,
    },
    annotDotColor: {
      r: 0,
      g: 100,
      b: 0,
      a: 1,
    },
    highlightsColor: {
      r: 143,
      g: 188,
      b: 153,
      a: 0.3,
    },
    axisColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    toolTip: {
      title: "Time",
      Desc: "Value",
    },
  },
  formatters: {
    xAxisFormatter: formatNumberToTime,
    yAxisFormatter: null,
  },
  canvas: {},
  output: (data) => {
    console.log(data);
  },
};

const ChartComponent = () => {
  useEffect(() => {
    // renderHbxChart is a global method to render chart library.
   if (window.renderHbxChart) {
      window.renderHbxChart('hbx-chart-test', props);
    }
  }, []);

  return (
    <div>
      <h1>HBX Chart</h1>
      <div id="hbx-chart-test"></div>
    </div>
  );
};

export default ChartComponent;

\`\`\`
`;

const ChartIntegration = () => {
  return (
    <div>
      <DocsHeader />
      <div className="flex flex-row gap-4">
        <DocsSidebar />
        <div className="grow px-3">
          <div className="py-2 border-b block border-b-gray-400 text-lg text-violet-800	">
            V1
          </div>
          <div className="py-3 text-3xl">Integration</div>
          <div className="py-2 text-2xl font-semibold">
            Using the Minified Bundle in a React Project
          </div>
          <div className="text-xl">
            To use the minified bundle in a React project, follow these steps:
          </div>
          <div className="pt-3 pb-1 text-xl font-semibold">
            Install the Minified Bundle
          </div>
          <div className="text-lg">
            Copy the downloaded minifiled js file into your React project and
            place it in the public directory.
          </div>

          <div className="pt-3 pb-1 text-xl font-semibold">Load the script</div>
          <div className="text-lg">
            Easiest way to load the script is to modify the public/index.html
            file to include the script.
          </div>

          <div className="text-lg">It will look like this:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {markdownBundleScript}
            </ReactMarkdown>
          </div>
          <div className="text-lg">
            you can also load your script in jsx when required. To load it
            dynamically, insert the script into the DOM once the component
            mounts.
          </div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {markdownDynamicJs}
            </ReactMarkdown>
          </div>

          <div className="pt-3 pb-1 text-xl font-semibold">
            Using the Chart in Your React Components
          </div>
          <div className="text-lg">
            Now, within your React components, you can use the exported chart
            component or functionality from the minified bundle.
          </div>

          <div className="text-lg">Here's how you might use it:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {markdownReact}
            </ReactMarkdown>
          </div>
          <div className="text-lg">
            In this example, the renderHbxChart function is loaded from the
            chart bundle file, which exposes a ChartComponent. This component is
            rendered in the useEffect hook when the component mounts.
          </div>

          <div className="text-lg py-1">
            Pass all the valid and mandatory props from chart configuration to
            load the charts.
          </div>
          <div className="text-lg py-1">
            To learn more about props, go to{" "}
            <a href="/charts/docs/hbx-line-graph" className="no-underline">
              Line Graph
            </a>
          </div>
          <div className="text-xl pt-3 pb-2 font-semibold">Here's a example with props:</div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {markdownReactReal}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChartIntegration;
