import { getAuthToken } from "./cookie";
import axios from "axios";

export const getAllStandardForms = async (errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const getAllStandardFormsApiUrl = `${API_URL}/forms/hbx`;
  try {
    const headers = getAuthToken();
    const response = await axios.get(getAllStandardFormsApiUrl, headers);
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const getAllForms = async (errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const getAllFormsApiUrl = `${API_URL}/forms`;
  try {
    const headers = getAuthToken();
    const response = await axios.get(getAllFormsApiUrl, headers);
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const saveForm = async (newFormSchema, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const saveFormApiUrl = `${API_URL}/forms`;
  try {
    const headers = getAuthToken();
    await axios.post(saveFormApiUrl, newFormSchema, headers);
  } catch (error) {
    console.log(error);
    errorHandlerFunction()
  }
};

export const getFormData = async (formId, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const fetchFormApiUrl = `${API_URL}/forms/${formId}`;
  try {
    const headers = getAuthToken();
    const response = await axios.get(fetchFormApiUrl, headers);
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const getStandardFormData = async (formId, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const fetchFormApiUrl = `${API_URL}/forms/user/${formId}`;
  try {
    const headers = getAuthToken();
    const response = await axios.get(fetchFormApiUrl, headers);
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const updateFormData = async (formId, formData, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const updateFormApiUrl = `${API_URL}/forms/${formId}`;

  try {
    const headers = getAuthToken();
    await axios.patch(
      updateFormApiUrl,
      formData,
      headers
    );
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const updateFormStatus = async (formId, newStatus, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const updateFormApiUrl = `${API_URL}/forms/${formId}`;

  try {
    const headers = getAuthToken();
    await axios.patch(
      updateFormApiUrl,
      {
        status: newStatus
      },
      headers
    );
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const deleteForm = async (formId, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const deleteFormApiUrl = `${API_URL}/form/${formId}`;
  try {
    const headers = getAuthToken();
    const response = await axios.delete(deleteFormApiUrl, headers);
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const getAllJourney = async (errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const getAllJourneysApiUrl = `${API_URL}/journey`;
  try {
    const headers = getAuthToken();
    const response = await axios.get(getAllJourneysApiUrl, headers);
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const saveJourney = async (newJourneySchema, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const saveJourneysApiUrl = `${API_URL}/journey`;
  try {
    const headers = getAuthToken();
    const response = await axios.post(
      saveJourneysApiUrl,
      newJourneySchema,
      headers
    );
    return response;
  } catch (error) {
    console.log(error);
    errorHandlerFunction()
  }
};

export const deleteJourney = async (journeyId, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const deleteJourneysApiUrl = `${API_URL}/journey/${journeyId}`;
  try {
    const headers = getAuthToken();
    const response = await axios.delete(deleteJourneysApiUrl, headers);
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const getJourneyData = async (journeyId, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const fetchJourneyApiUrl = `${API_URL}/journey/${journeyId}`;
  try {
    const headers = getAuthToken();
    const response = await axios.get(fetchJourneyApiUrl, headers);
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const updateJourneyData = async (journeyId, newJourneySchema, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const updateJourneyApiUrl = `${API_URL}/journey/${journeyId}`;

  try {
    const headers = getAuthToken();
    const response = await axios.patch(
      updateJourneyApiUrl,
      {
        journey_schema: newJourneySchema,
      },
      headers
    );
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const updateJourneyStatus = async (journeyId, status, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const updateJourneyApiUrl = `${API_URL}/journey/${journeyId}`;

  try {
    const headers = getAuthToken();
    const response = await axios.patch(
      updateJourneyApiUrl,
      {
        status: status,
      },
      headers
    );
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const newFormSubmit = async (formData, org, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const formSubmissionUrl = `${API_URL}/formsubmissions`;

  try {
    const headers = getAuthToken();
    const response = await axios.post(
      formSubmissionUrl,
      {
        form: formData,
        org
      },
      headers
    );
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const getJourneyDataForUsers = async (journeyId, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const fetchJourneyApiUrl = `${API_URL}/journey/user/${journeyId}`;
  try {
    const response = await axios.get(fetchJourneyApiUrl);
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};

export const getFormDataForUsers = async (formId, errorHandlerFunction=(()=>{})) => {
  const API_URL = process.env.REACT_APP_API_URL
  const fetchFormApiUrl = `${API_URL}/forms/user/${formId}`;
  try {
    const response = await axios.get(fetchFormApiUrl);
    return response;
  } catch (e) {
    console.log(e);
    errorHandlerFunction()
  }
};
