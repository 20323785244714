import {
  Box,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import { isTokenValid } from "../../../utils/sessionutils";
import { useSelector } from "react-redux";
import { CustomCard } from "../../../components/custom/CustomCard";
import CustomInput from "../../../components/custom/CustomInput";
import { InfoOutlined } from "@mui/icons-material";

export const OrganizationInfo = () => {
  const userData = useSelector((state) => state.user);
  useEffect(() => {
    isTokenValid();
    // console.log("user", userData);
  }, []);

  const renderAddressFields = Object.entries(userData?.org?.address || {}).map(
    ([key, value]) => (
      <CustomInput
        labelText={key
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase())}
        disabled
        fullWidth
        sx={{ paddingLeft: 2 }}
        variant="standard"
        value={value ?? ""}
      />
    )
  );

  return (
    <Stack spacing={4}>
      <Typography variant="h4">Organization Details</Typography>
      <CustomCard sx={{ p: 2 }}>
        <CardHeader
          title={"Basic Details"}
          titleTypographyProps={{ variant: "h6" }}
          // sx={{ p: "32px 24px 16px" }}
          avatar={<InfoOutlined />}
        />
        <CardContent p={4}>
          <CustomInput
            labelText={"Organization"}
            fullWidth
            disabled
            sx={{ paddingLeft: 2 }}
            variant="standard"
            value={userData?.org?.name ?? ""}
          />

          {typeof userData?.org?.address === "object" ? (
            renderAddressFields
          ) : (
            <CustomInput
              labelText={"Address"}
              disabled
              fullWidth
              sx={{ paddingLeft: 2 }}
              variant="standard"
              value={userData?.org?.address ?? ""}
            />
          )}
        </CardContent>
      </CustomCard>
    </Stack>
  );
};
