import { Box } from "@mui/material";
import React from "react";
import StripeCheckout from "./stripePayment";

export const Stripe = () => {
  return (
    <Box m="20px">
      <StripeCheckout org_id={"65ddbd49039a41f871bc4915"} amount={100} />
    </Box>
  );
};
