import axios from "axios";
import Cookies from "js-cookie";
import { clearCookie } from "./sessionutils";

export const getRequest = async (endpoint, header = {}, logOutFlag = true) => {
  try {
    const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const url =
      endpoint.includes("http://") || endpoint.includes("https://")
        ? endpoint
        : `${process.env.REACT_APP_SERVER}/${endpoint}`;
    const headers = {
      headers: {
        ...header,
        "x-auth-token": token,
      },
    };
    // console.log(headers);
    const response = await axios.get(url, headers);
    return response;
  } catch (e) {
    if (logOutFlag && e.response.status === 401) {
      await clearCookie();
    }
    throw e;
  }
};

export const postRequest = async (endpoint, body, options = {}, logOutFlag = true) => {
  try {
    const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const url =
      endpoint.includes("http://") || endpoint.includes("https://")
        ? endpoint
        : `${process.env.REACT_APP_SERVER}/${endpoint}`;
    options.headers = options.headers || {};
    options.headers["x-auth-token"] = token;
    console.log(options);
    const response = await axios.post(url, body, options);
    return response;
  } catch (e) {
    console.log(e);
    if (logOutFlag && e.response.status === 401) {
      await clearCookie();
    }
    throw e;
  }
};

export const patchRequest = async (endpoint, body, options = {}, logOutFlag = true) => {
  try {
    const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const url =
      endpoint.includes("http://") || endpoint.includes("https://")
        ? endpoint
        : `${process.env.REACT_APP_SERVER}/${endpoint}`;
    options.headers = options.headers || {};
    options.headers["x-auth-token"] = token;
    console.log(options);
    const response = await axios.patch(url, body, options);
    console.log(response);
    return response;
  } catch (e) {
    if (logOutFlag && e.response.status === 401) {
      await clearCookie();
    }
    throw e;
  }
};

export const deleteRequest = async (endpoint, body = {}, options = {}, logOutFlag = true) => {
  try {
    const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
    const url =
      endpoint.includes("http://") || endpoint.includes("https://")
        ? endpoint
        : `${process.env.REACT_APP_SERVER}/${endpoint}`;
    options.headers = options.headers || {};
    options.headers["x-auth-token"] = token;
    console.log(options);
    const response = await axios.delete(url, options);
    console.log(response);
    return response;
  } catch (e) {
    if (logOutFlag && e.response.status === 401) {
      await clearCookie();
    }
    throw e;
  }
};
