import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Page1 = () => {
  return (
    <Box m="20px">
      {/* <h1>Page1</h1> */}
      <Box>
        <Link to="../page2">Page 2</Link>
      </Box>
      <Box>
        <Link to="../">Home</Link>
      </Box>
    </Box>
  );
};

export default Page1;
