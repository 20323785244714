import React, { useState } from "react";
import CareFlowContext from "./CareFlowContext";

const CareFlowContextProvider = ({children}) => {
    const [conditionData, setConditionData] = useState([])
    const [history, setHistory] = useState([])
    const [historyStep, setHistoryStep] = useState(0);
    const [appsList, setAppsList] = useState([])
    // const [activeScreen, setActiveScreen] = useState(null)
    // const [activePage, setActivePage] = useState(null)
    // const [answerData, setAnswerData] = useState({})
    // const [journeyId, setJourneyId] = useState(null)
    return (
        <CareFlowContext.Provider value = {{ conditionData, setConditionData, history, setHistory, historyStep, setHistoryStep, appsList, setAppsList}}>
            {children}
        </CareFlowContext.Provider>
    )

}

export default CareFlowContextProvider