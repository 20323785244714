import { Box, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const PasswordInstructions = ({ password = "" }) => {
  const [score, setScore] = useState(0);

  const generateColor = (score) => {
    switch (score) {
      case 0:
        return "#FF0000";
      case 1:
        return "#FF0000";
      case 2:
        return "#FFA500";
      case 3:
        return "#FFD700";
      case 4:
        return "#FFFF00";
      case 5:
        return "#008000";
      default:
        return "#FF0000";
    }
  };
  useEffect(() => {
    const calculateScore = () => {
      let score = 0;
      if (password.length > 8) {
        score++;
      }
      if (/[a-z]/.test(password)) {
        score++;
      }
      if (/[A-Z]/.test(password)) {
        score++;
      }
      if (/\d/.test(password)) {
        score++;
      }
      if (/[^a-zA-Z0-9]/.test(password)) {
        score++;
      }
      setScore(score);
    };
    calculateScore();
  }, [password]);

  return (
    <>
      <Box mt={"10px"}>
        <LinearProgress
          variant="determinate"
          value={(score / 5) * 100}
          //   color={generateGradient(strengthScore)}
          sx={{
            height: "0.5rem",
            borderRadius: "5px",
            background: "#f0f0f0",
            "& .MuiLinearProgress-bar": {
              background: generateColor(score), // Change filled color based on strength score
            },
          }}
        />
      </Box>
      <Box m={"10px"} textAlign={"left"}>
        <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
          Password must:
        </Typography>
        <ul>
          <li>
            <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
              &#8226; Be at least 8 characters long
            </Typography>
          </li>
          <li>
            <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
              &#8226; Contain at least one uppercase letter
            </Typography>
          </li>
          <li>
            <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
              &#8226; Contain at least one lowercase letter
            </Typography>
          </li>
          <li>
            <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
              &#8226; Contain at least one digit
            </Typography>
          </li>
          <li>
            <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
              &#8226; Contain at least one special character
            </Typography>
          </li>
        </ul>
      </Box>
    </>
  );
};
