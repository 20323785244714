import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { getData } from "../../utils/getappointments";
import AppointmentsTable from "../AppointmentsTable";

const SelectAppointment = ({ closeModal, next }) => {
  const [data, setData] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const fetchData = async () => {
    setWaiting(true);
    try {
      setData(await getData());
    } catch (e) {
      console.log(e);
    }
    setWaiting(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box>
      <Box display="flex" justifyContent={"space-between"}>
        <Typography variant="h6">Select Appointment</Typography>
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box m={"20px"}>
        {waiting ? (
          <CircularProgress color="inherit" />
        ) : data ? (
          <AppointmentsTable data={data} next={next} />
        ) : (
          <Typography variant="h8" style={{ opacity: "0.7" }}>
            No Appointments Found
          </Typography>
        )}
      </Box>
      <Box display="flex" justifyContent={"flex-end"}>
        <Button onClick={fetchData}>REFRESH</Button>
      </Box>
    </Box>
  );
};
export default SelectAppointment;
