import React from "react";

const FormField = ({
  label,
  type = "text",
  value,
  isValid,
  placeholder,
  required = false,
  onChange,
  inValidMessage = null,
}) => {
  return (
    <div className="w-full flex flex-col gap-1 py-2">
      <label className="form-title">
        {label}
        {required && <span className={isValid ? "" : "text-red-700"}> * </span>}
      </label>
      {type === "select" ? (
        <div className=" multiple-checkboxes">
          {value &&
            value?.map((option) => {
              return (
                <div
                  className={`checkbox-wrapper-4 radio-common ${
                    option?.value ? "text-input-form-filled" : ""
                  }`}
                >
                  <input
                    className="inp-cbx inp-cbx-form"
                    id={option?.id}
                    checked={option?.value}
                    onChange={() => onChange(option?.id)}
                    type="checkbox"
                  />
                  <label className="cbx cbx-form" htmlFor={option?.id}>
                    <span>
                      <svg width="12px" height="10px">
                        <use xlinkHref={`#check${option?.id}`} />
                      </svg>
                    </span>
                    <span className="form-title pl-3">{option?.label}</span>
                  </label>
                  <svg className="inline-svg">
                    <symbol id={`check${option?.id}`} viewBox="0 0 12 10">
                      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                    </symbol>
                  </svg>
                </div>
              );
            })}
        </div>
      ) : type === "textarea" ? (
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          rows="4"
          placeholder={placeholder}
          className={` text-input text-input-form text-input-textarea ${
            !isValid ? "border-red-700" : ""
          } ${value && value !== "" ? "text-input-form-filled" : ""}`}
        />
      ) : (
        <input
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          className={` text-input text-input-form ${
            !isValid ? "border-red-700" : ""
          } ${value && value !== "" ? "text-input-form-filled" : ""}`}
        />
      )}
      {required && (
        <>
          {!isValid && (
            <span className="text-red-700 font-light text-xs">
              {inValidMessage || "Required Field"}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default FormField;
