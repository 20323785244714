import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Appsetup } from "./appmfa";
import { EmailSetup } from "./emailmfa";
import { PhoneSetup } from "./phonemfa";

const EnableMFA = ({ closeModal, type }) => {
  return (
    <Box>
      <Box display="flex" justifyContent={"space-between"}>
        <Typography variant="h6">Enable {type}</Typography>
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box m={"20px"}>
        {type === "App" && <Appsetup />}
        {type === "Email" && <EmailSetup />}
        {type === "Phone" && <PhoneSetup />}
      </Box>
    </Box>
  );
};
export default EnableMFA;
