import CardWrapper from "../../mui-wrappers/mui-components/CardWrapper";

export const CustomCard = ({ children, sx, ...rest }) => {
  return (
    <CardWrapper
      sx={{
        ...sx,
        borderRadius: "10px ",
        borderStyle: "solid",
        borderColor: "grey.300",
        borderWidth: "1px ",
        boxShadow: "none",
      }}
      {...rest}
    >
      {children}
    </CardWrapper>
  );
};
