import React, { useEffect, useState } from "react";
import { ArrowForward, MoreHorizOutlined } from "@mui/icons-material";
import CodeIcon from "@mui/icons-material/Code";
import {
  Box,
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button,
  Chip,
  Avatar,
  CircularProgress,
} from "@mui/material";
import athenaLogo from "../../../../assets/images/athena-logo.png";
import modmedLogo from "../../../../assets/images/modmed-logo.jpeg";
import epicLogo from "../../../../assets/images/epic-logo.png";
import ecwLogo from "../../../../assets/images/ecw-logo.jpeg";
import veradigmLogo from "../../../../assets/images/veradigm-logo.png";
import { CustomCard } from "../../../../components/custom/CustomCard";
import { getAuthToken } from "../../../../utils/cookie";
import axios from "axios";
export const OurSubscriptions = () => {
  const [clientIntegrationList, setClientIntegrationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchClientIntegrations();
  }, []);
  const fetchClientIntegrations = async () => {
    const API_URL = process.env.REACT_APP_FOUNTAIN_HEAD_URL;
    const fetchClientIntegrationsUrl = `${API_URL}/client/integrations`;
    const headers = getAuthToken();
    try {
      const res = await axios.get(fetchClientIntegrationsUrl, headers);
      const { clientIntegrations } = res.data;
      debugger;
      setClientIntegrationList(clientIntegrations);
    } catch (error) {
      alert("error in fetching client integrations, please try again later.");
    }
    setIsLoading(false);
  };

  function titleCase(str) {
    return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
  }

  return (
    <CustomCard sx={{ height: "450px", display: "", flexDirection: "column" }}>
      <CardHeader
        title={<Typography variant="h6">Our Client Integrations</Typography>}
        avatar={
          <IconButton aria-label="subscriptions">
            <CodeIcon />
          </IconButton>
        }
      />
      {isLoading ? (
        <Box
          sx={{
            maxHeight: "320px",
            overflowY: "auto",
            scrollbarWidth: "none",
            flexGrow: "1",
            alignContent: "center",
            alignSelf: "center",
          }}
        >
          <CircularProgress sx={{ color: "#CF7447" }} className="m-auto" />
        </Box>
      ) : (
        <Box
          sx={{
            maxHeight: "320px",
            overflowY: "auto",
            scrollbarWidth: "none",
          }}
        >
          <List sx={{ p: "16px  24px " }}>
            {clientIntegrationList.map((product, index) => (
              <ListItem disablePadding key={product.id}>
                <ListItemAvatar>
                  <Avatar
                    src={
                      product.icon === "athenaLogo"
                        ? athenaLogo
                        : product.icon === "modmedLogo"
                        ? modmedLogo
                        : product.icon === "epicLogo"
                        ? epicLogo
                        : product.icon === "ecwLogo"
                        ? ecwLogo
                        : product.icon === "veradigmLogo"
                        ? veradigmLogo
                        : ""
                    }
                    alt="Product Icon"
                    sx={{
                      width: "40px",
                      height: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={product.integration_name}
                  primaryTypographyProps={{ variant: "subtitle1" }}
                  secondary={product.EHR}
                  secondaryTypographyProps={{ variant: "body2" }}
                />

                <Chip
                  label={titleCase(product?.status ?? "Canceled")}
                  sx={{
                    bgcolor:
                      product.status === "active"
                        ? "#CCFBEF" // Soft green
                        : product.status === "archived"
                        ? "#fff3c6" // Soft orange
                        : "#fee4e2", // Soft red

                    color:
                      product.status === "active"
                        ? "#107569" // Darker green for text
                        : product.status === "archived"
                        ? "#B84D05" // Darker orange for text
                        : "#bb241a", // Darker red for text
                  }}
                />
                <IconButton edge="end">
                  <MoreHorizOutlined weight="bold" />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          endIcon={<ArrowForward />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
    </CustomCard>
  );
};
