import { Stack, useTheme } from "@mui/material";
import { Details } from "./Details";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginLayout } from "../../layouts/LoginLayout";

const steps = ["User Details", "Organization Details", "Review"];

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleExit = () => {
    navigate("/");
  };

  return (
    <LoginLayout heading={"Sign up"}>
      <Stack spacing={1}>
        <Stack>
          <Details
            activeStep={activeStep}
            steps={steps}
            handleNext={handleNext}
            handleExit={handleExit}
            handleBack={handleBack}
          />
        </Stack>
        {/* <Stack>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper> 
        </Stack>*/}
      </Stack>
    </LoginLayout>
  );
}
