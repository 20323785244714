import React, { useEffect, useState, useContext } from "react";
import FormPlayGround from "./formPlayGround";
import FormSideBar from "./formSideBar";
import "./FormBuilder.css";
import FormContext from "../../store/context/FormContext";
import { generateUID } from "../../utils/generateUID";
import { Link } from "react-router-dom";
import { FiPlus, FiCheck, FiX, FiEdit3 } from "react-icons/fi";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useParams, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import {
  getAllJourney,
  getJourneyData,
  updateJourneyData,
} from "../../utils/form";
import CustomButton from "../../components/custom/CustomButton";
import { CustomCard } from "../../components/custom/CustomCard";

import { Box, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DropDown from "../../components/common/InputDropdown";
import { getAuthToken } from "../../utils/cookie";
import axios from "axios";

const FormBuilder = () => {
  const [journeyData, setJourneyData] = useState(null);

  const [showCreateScreen, setShowCreateScreen] = useState(false);
  const [inputScreenValue, setInputScreenValue] = useState("");
  const [showCreatePage, setShowCreatePage] = useState(false);
  const [inputPageValue, setInputPageValue] = useState("");

  const [showEditPage, setShowEditPage] = useState(false);
  const [editPageValue, setEditPageValue] = useState("");

  const [showEditScreen, setShowEditScreen] = useState(false);
  const [editScreenValue, setEditScreenValue] = useState("");
  const [theme, setTheme] = useState("default");
  const [themeList, setThemeList] = useState([
    { label: "Default", value: "default" },
    { label: "Theme 1", value: "theme1" },
    { label: "Theme 2", value: "theme2" },
    { label: "Theme 3", value: "theme3" },
    { label: "Theme 4", value: "theme4" },
  ]);

  const [journeyList, setJourneyList] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState(null);

  const [playGroundData, setPlayGroundData] = useState(null);

  const {
    schema,
    setSchema,
    activeScreen,
    setActiveScreen,
    activePage,
    setActivePage,
  } = useContext(FormContext);

  const { journeyId } = useParams();
  const _id = journeyId;
  let navigate = useNavigate();

  function increaseScreen() {
    if (inputScreenValue) {
      let newScreen = {
        id: `screen-${generateUID()}`,
        label: inputScreenValue,
        screenPages: [],
      };
      let tempSchema = JSON.parse(JSON.stringify(schema));
      if (!tempSchema?.screens) {
        tempSchema.screens = [];
      }
      if (tempSchema?.screens?.length === 0) {
        setActiveScreen(newScreen.id);
        newScreen.active = true;
      }
      tempSchema?.screens?.push(newScreen);
      setSchema(tempSchema);
      setInputScreenValue("");
      setShowCreateScreen(false);
      updateJourneySchema(_id, tempSchema);
    }
  }

  function increasePage() {
    if (inputPageValue) {
      let newPage = {
        id: `page-${generateUID()}`,
        label: inputPageValue,
        components: {},
      };
      let tempSchema = JSON.parse(JSON.stringify(schema));

      tempSchema?.screens?.map((item) => {
        if (item?.active) {
          if (item?.screenPages?.length === 0) {
            setActivePage(newPage.id);
            setPlayGroundData(newPage.components || {});
            newPage.active = true;
          }
          item?.screenPages?.push(newPage);
        }
      });
      setSchema(tempSchema);
      setInputPageValue("");
      setShowCreatePage(false);
      updateJourneySchema(_id, tempSchema);
    }
  }

  function handleScreenClick(id) {
    let tempSchema = JSON.parse(JSON.stringify(schema));
    tempSchema?.screens?.map((item) => {
      if (item?.id === id) {
        item.active = true;
        item?.screenPages?.map((page) => {
          if (page.active) setPlayGroundData(page.components || {});
        });
      } else item.active = false;
    });

    setActiveScreen(id);
    setSchema(tempSchema);
  }

  function handlePageClick(id) {
    let tempSchema = JSON.parse(JSON.stringify(schema));
    tempSchema?.screens?.map((item) => {
      if (item?.active) {
        item?.screenPages?.map((page) => {
          if (page?.id === id) {
            page.active = true;
            setPlayGroundData(page.components || {});
          } else page.active = false;
        });
      }
    });
    setActivePage(id);
    setSchema(tempSchema);
  }

  const updateJourneySchema = async (id, newSchema) => {
    const response = await updateJourneyData(id, newSchema);
    setJourneyData(response?.data?.journey);
    setSchema(response?.data?.journey?.journey_schema);
  };

  const setJourneySchema = async (id) => {
    const response = await getJourneyData(id, () => navigate("/form-journey"));
    let schema = response?.data?.journey?.journey_schema;
    setJourneyData(response?.data?.journey);
    setSchema(schema);
    schema?.screens?.map((item) => {
      if (item?.active) {
        setActiveScreen(item.id);
        item?.screenPages?.map((page) => {
          if (page?.active) {
            setPlayGroundData(page?.components || {});
            setActivePage(page?.id);
          }
        });
      }
    });
  };

  function handleDrop(data) {
    let tempSchema = JSON.parse(JSON.stringify(schema));
    tempSchema?.screens?.map((item) => {
      if (item?.active) {
        item?.screenPages?.map((page) => {
          if (page?.active) {
            page.components = data;
            setPlayGroundData(page.components || {});
          }
        });
      }
    });
    setSchema(tempSchema);
    updateJourneySchema(_id, tempSchema);
  }

  function checkForActivePage() {
    if (schema?.screens?.length) {
      for (let i = 0; i < schema.screens.length; i++) {
        if (
          schema.screens[i].active &&
          schema.screens[i]?.screenPages?.length
        ) {
          for (let j = 0; j < schema.screens[i].screenPages?.length; j++) {
            if (schema.screens[i].screenPages[j].active)
              return schema.screens[i].screenPages[j]?.id;
          }
        }
      }
    }
    return false;
  }
  function checkForActiveScreen() {
    if (schema?.screens?.length) {
      for (let i = 0; i < schema.screens.length; i++) {
        if (schema.screens[i].active) return schema.screens[i]?.id;
      }
    }
    return false;
  }

  function updatePageName() {
    if (!editPageValue) return;

    let tempSchema = JSON.parse(JSON.stringify(schema));
    tempSchema?.screens?.map((item) => {
      if (item?.active) {
        item?.screenPages?.map((page) => {
          if (page?.active) {
            page.label = editPageValue;
          }
        });
      }
    });
    setSchema(tempSchema);
    setEditPageValue("");
    setShowEditPage(false);
    updateJourneySchema(_id, tempSchema);
  }

  function updateScreenName() {
    if (!editScreenValue) return;
    let tempSchema = JSON.parse(JSON.stringify(schema));
    tempSchema?.screens?.map((item) => {
      if (item?.active) {
        item.label = editScreenValue;
      }
    });
    setSchema(tempSchema);
    setEditScreenValue("");
    setShowEditScreen(false);
    updateJourneySchema(_id, tempSchema);
  }

  function handleDeletePage() {
    let tempSchema = JSON.parse(JSON.stringify(schema));
    let screenIndex, pageIndex;
    tempSchema?.screens?.map((item, idx) => {
      if (item?.active) {
        screenIndex = idx;
        item?.screenPages?.map((page, index) => {
          if (page?.active) {
            pageIndex = index;
          }
        });
      }
    });
    tempSchema?.screens[screenIndex]?.screenPages?.splice(pageIndex, 1);
    if (pageIndex > 0) {
      setActivePage(
        tempSchema?.screens[screenIndex]?.screenPages[pageIndex - 1].id
      );
      tempSchema.screens[screenIndex].screenPages[pageIndex - 1].active = true;
    } else setActivePage(null);
    tempSchema?.screens.map((item) => {
      if (item?.active) {
        item?.screenPages.map((page) => {
          if (page.active) {
            setPlayGroundData(page.components || {});
          }
        });
      }
    });
    setSchema(tempSchema);
    updateJourneySchema(_id, tempSchema);
  }

  function setThemeForJourney() {
    let tempSchema = JSON.parse(JSON.stringify(schema));
    tempSchema.theme = theme;
    tempSchema.parentJourney = selectedJourney;
    setSchema(tempSchema);
    updateJourneySchema(_id, tempSchema);
  }

  function handleDeleteScreen() {
    let tempSchema = JSON.parse(JSON.stringify(schema));
    let screenIndex;
    tempSchema?.screens?.map((item, idx) => {
      if (item.active) {
        screenIndex = idx;
      }
    });
    tempSchema?.screens?.splice(screenIndex, 1);
    if (screenIndex > 0) {
      setActiveScreen(tempSchema.screens[screenIndex - 1].id);
      tempSchema.screens[screenIndex - 1].active = true;
    } else setActiveScreen(null);
    tempSchema.screens.map((item) => {
      if (item.active) {
        item.screenPages.map((page) => {
          if (page.active) {
            setPlayGroundData(page.components || {});
          }
        });
      }
    });
    setSchema(tempSchema);
    updateJourneySchema(_id, tempSchema);
  }

  const fetchThemes = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const getThemesApiUrl = `${API_URL}/themes`;
    try {
      const headers = getAuthToken();
      const response = await axios.get(getThemesApiUrl, headers);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllJourneyData = async () => {
    const response = await getAllJourney();
    let journeys = response?.data?.journeys;
    let updatedJourneys = journeys.map((item) => ({
      ...item,
      value: item._id,
    }));
    setJourneyList(updatedJourneys);
  };

  useEffect(() => {
    if (!_id) return <></>;
    if (_id) {
      setJourneySchema(_id);
    }
    fetchThemes();
    getAllJourneyData();
  }, []);

  return (
    <Box m={2} pb={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <CustomButton
          onClick={() => {
            navigate(-1);
          }}
          variant={"outlined"}
        >
          <KeyboardBackspaceIcon className="mr-3" /> {"Back"}
        </CustomButton>
        <CustomButton
          onClick={() => {
            setThemeForJourney();
            navigate(-1);
          }}
        >
          Save and Return
        </CustomButton>
      </Box>
      <div className="form-builder">
        <CustomCard className="px-3">
          <div className="flex justify-between px-3 border-b-2">
            <div className="py-3 text-2xl">Journey : {journeyData?.label}</div>
            <div className="py-3 text-xl">
              Organisation : {journeyData?.org_name}
            </div>
          </div>
          <div className="overflow-x-scroll screen">
            <div className="flex border-b-2">
              {schema && schema?.screens && schema?.screens?.length ? (
                schema?.screens.map((item, idx) => {
                  return (
                    <div className="px-2" key={idx + item.label}>
                      <div
                        className={
                          item.active
                            ? "border-b-4 mustard_border py-2 mx-2 px-1 w-max cursor-pointer"
                            : " py-2 mx-2 px-1 w-max cursor-pointer"
                        }
                        onClick={() => handleScreenClick(item.id)}
                      >
                        {item.label}
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
              {showCreateScreen && (
                <div className="flex">
                  <input
                    type="text"
                    value={inputScreenValue}
                    onChange={(e) => setInputScreenValue(e.target.value)}
                    placeholder="Screen Name"
                    className="px-2 py-1 m-auto h-fit rounded border-2 mustard_border"
                  ></input>
                  <FiCheck
                    size={32}
                    className="my-auto ml-2 rounded purple_bg_dark p-2 cursor-pointer text-white cursor-pointer"
                    onClick={increaseScreen}
                  />
                  <FiX
                    size={32}
                    className="my-auto ml-2 rounded bg-red-500 p-2 cursor-pointer text-white cursor-pointer"
                    onClick={() => setShowCreateScreen(false)}
                  />
                </div>
              )}
              {!showCreateScreen && (
                <div
                  className="rounded mustard_bg px-2 py-1 mx-2 flex m-auto text-white w-max cursor-pointer"
                  onClick={() => setShowCreateScreen(true)}
                >
                  <FiPlus size={22} className="items-center pr-1" />
                  Screen
                </div>
              )}
            </div>
          </div>
          <div className="overflow-x-scroll screen">
            <div className="flex pt-2">
              {schema && schema?.screens && schema?.screens.length ? (
                schema?.screens.map((item, idx) => {
                  return (
                    <div key={idx} className="flex">
                      {item?.active &&
                      item?.screenPages &&
                      item.screenPages?.length ? (
                        item.screenPages.map((page, index) => {
                          return (
                            <div
                              className="px-2"
                              key={index + "" + idx + page.label}
                            >
                              <div
                                className={
                                  page.active
                                    ? "border-b-4 border-green-500 py-2 mx-2 px-1 w-max cursor-pointer"
                                    : " py-2 mx-2 px-1 w-max cursor-pointer"
                                }
                                onClick={() => handlePageClick(page.id)}
                              >
                                {page.label}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })
              ) : (
                <></>
              )}
              {showCreatePage && (
                <div className="flex">
                  <input
                    type="text"
                    value={inputPageValue}
                    onChange={(e) => setInputPageValue(e.target.value)}
                    placeholder="Screen Page Name"
                    className="px-2 py-1 m-auto h-fit rounded border-2 grey_border"
                  ></input>
                  <FiCheck
                    size={32}
                    className="my-auto ml-2 rounded purple_bg_dark p-2 cursor-pointer text-white cursor-pointer"
                    onClick={increasePage}
                  />
                  <FiX
                    size={32}
                    className="my-auto ml-2 rounded bg-red-500 p-2 cursor-pointer text-white cursor-pointer"
                    onClick={() => setShowCreatePage(false)}
                  />
                </div>
              )}
              {!showCreatePage && (
                <div
                  className="rounded mustard_bg px-2 py-1 mx-2 flex m-auto text-white w-max cursor-pointer"
                  onClick={() => setShowCreatePage(!showCreatePage)}
                >
                  <FiPlus size={22} className="items-center pr-1" />
                  Page
                </div>
              )}
            </div>
          </div>
        </CustomCard>

        {/* <div className="flex justify-between p-3">
          {showEditPage && (
            <div className="flex">
              <input
                type="text"
                value={editPageValue}
                onChange={(e) => setEditPageValue(e.target.value)}
                placeholder="Page New Name"
                className="px-2 py-1 m-auto h-fit rounded border-2 mustard_border"
              ></input>
              <FiCheck
                size={32}
                className="my-auto ml-2 rounded mustard_bg p-2 cursor-pointer text-white cursor-pointer"
                onClick={updatePageName}
              />
              <FiX
                size={32}
                className="my-auto ml-2 rounded bg-red-500 p-2 cursor-pointer text-white cursor-pointer"
                onClick={() => setShowEditPage(false)}
              />
            </div>
          )}
          {!showEditPage && checkForActivePage() && (
            <div className="flex">
              <div
                className="rounded mustard_bg px-2 py-1 mx-2 flex m-auto text-white w-max cursor-pointer"
                onClick={() => setShowEditPage(true)}
              >
                <FiEdit3 size={22} className="items-center pr-1" />
                Edit Page Name
              </div>
              <div
                className="rounded mustard_bg px-2 py-1 mx-2 flex m-auto text-white w-max cursor-pointer"
                onClick={handleDeletePage}
              >
                <MdDelete size={22} className="items-center pr-1" />
                Delete Page Name
              </div>
            </div>
          )}
          {showEditScreen && (
            <div className="flex">
              <input
                type="text"
                value={editScreenValue}
                onChange={(e) => setEditScreenValue(e.target.value)}
                placeholder="Screen New Name"
                className="px-2 py-1 m-auto h-fit rounded border-2 mustard_border"
              ></input>
              <FiCheck
                size={32}
                className="my-auto ml-2 rounded mustard_bg p-2 cursor-pointer text-white cursor-pointer"
                onClick={updateScreenName}
              />
              <FiX
                size={32}
                className="my-auto ml-2 rounded bg-red-500 p-2 cursor-pointer text-white cursor-pointer"
                onClick={() => setShowEditScreen(false)}
              />
            </div>
          )}
          {!showEditScreen && checkForActiveScreen() && (
            <div className="flex">
              <div
                className="rounded mustard_bg px-2 py-1 mx-2 flex m-auto text-white w-max cursor-pointer"
                onClick={() => setShowEditScreen(true)}
              >
                <FiEdit3 size={22} className="items-center pr-1" />
                Edit Screen Name
              </div>
              <div
                className="rounded mustard_bg px-2 py-1 mx-2 flex m-auto text-white w-max cursor-pointer"
                onClick={handleDeleteScreen}
              >
                <MdDelete size={22} className="items-center pr-1" />
                Delete Screen Name
              </div>
            </div>
          )}
        </div> */}
        {/* {checkForActivePage() ? (
          <div className="flex justify-end">
            <Link
              className="px-3 py-1 border-2 rounded mustard_border no-underline my-3 text-lg font-semibold text-black flex"
              to={"/edit-form"}
            >
              Create New Form
              <IoIosArrowRoundForward
                size={28}
                className="items-center font-extrabold"
              />
            </Link>
          </div>
        ) : (
          <></>
        )} */}
        <div className="pt-3 text-2xl flex justify-between">
          <div>
            <div className="text-center content-center">Theme : </div>
            <div className="px-3">
              <DropDown
                options={themeList}
                answer={{ value: theme }}
                onChange={(data) => setTheme(data?.value)}
              />
            </div>
          </div>

          <div>
            <div className="text-center content-center">Parent Journey : </div>
            <div className="px-3">
              <DropDown
                options={journeyList}
                answer={{ value: selectedJourney }}
                onChange={(data) => setSelectedJourney(data?.value)}
              />
            </div>
          </div>
        </div>
        {checkForActivePage() ? (
          <div className="flex justify-between py-4 gap-4">
            <div>
              <FormSideBar _id={_id} />
            </div>
            <div className="grow">
              <FormPlayGround
                playGroundData={playGroundData}
                onDrop={handleDrop}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Box>
  );
};
export default FormBuilder;
