import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postRequest } from "../../utils/request";
import { isWeakPassword } from "../../utils/password";
import { PasswordInstructions } from "../../components/PasswordInstructions";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [fail, setFail] = useState(null);
  const [weakPass, setWeakPass] = useState(null);
  const { token, id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const checkPassword = (e) => {
    setFail(null);
    setConfirmPassword(e.target.value);
    if (e.target.value !== password) {
      setError("Passwords do not match");
    } else {
      setError(null);
    }
  };
  const checkPasswordStrength = (e) => {
    const weak = isWeakPassword(e.target.value);
    if (weak) {
      setWeakPass(weak);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postRequest("user/reset-password", {
        id: id,
        token: token,
        password: password,
      });
      console.log(response);
      setSuccess("Password reset successfully");
    } catch (e) {
      setFail(e.response?.data?.message ?? "something went wrong");
      console.log(e);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h3">
            HealthBlox
          </Typography>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>

          {success || fail ? (
            <Box sx={{ mt: 1 }}>
              <Typography
                mt={"20px"}
                align="center"
                // component="body"
                variant="body1"
              >
                {fail ? fail : success}
              </Typography>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Button
                  // fullWidth
                  variant="contained"
                  onClick={() => {
                    navigate(fail ? "/forgot_password" : "/");
                  }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  {fail ? "Resend" : "Login"}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              component="form"
              onSubmit={handleSubmit}
              // noValidate
              sx={{ mt: 1 }}
            >
              <Typography mt={"20px"}>
                Please enter your new password:
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                value={password}
                onBlur={checkPasswordStrength}
                onChange={(e) => {
                  setWeakPass(null);
                  setPassword(e.target.value);
                  setFail(null);
                }}
                error={!!weakPass}
                helperText={weakPass}
                id="password"
                label="New Password"
                name="password"
                autoComplete="password"
                type="password"
              />
              <PasswordInstructions password={password} />
              <TextField
                margin="normal"
                required
                fullWidth
                value={confirmPassword}
                onChange={checkPassword}
                // onBlur={checkPassword}
                error={!!error}
                helperText={error}
                id="confirmpassword"
                label="Confirm New Password"
                name="confirmpassword"
                autoComplete="confirmpassword"
                type="password"
              />
              <Button
                disabled={
                  password && confirmPassword && !error && !weakPass
                    ? false
                    : true
                }
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                RESET
              </Button>
              {fail && (
                <Typography
                  mt={"20px"}
                  align="center"
                  // component="body"
                  variant="body1"
                >
                  {fail}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};
export default ResetPassword;
