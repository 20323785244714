import { Box, Button, Checkbox, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

const Summary = ({ closeModal, data }) => {
  const [name] = useState(data.name);
  const [summary] = useState(data.summary);
  const [primary_id] = useState(data.primary_id);
  const [appointment_updates, setAppointment_updates] = useState(
    data.appointment_updates
  );
  const [signed_order_updates, setSigned_order_updates] = useState(
    data.signed_order_updates
  );
  const [appointment_summary, setappointment_summary] = useState(
    data.appointment_summary
  );
  const save = () => {
    const modified = {
      id: data.id,
      name: name,
      summary: summary,
      primary_id: primary_id,
      appointment_updates: appointment_updates,
      signed_order_updates: signed_order_updates,
      appointment_summary: appointment_summary,
    };
    console.log(modified);
    // change(data.id, modified);
  };
  return (
    <Box>
      <Box display="flex" justifyContent={"space-between"} p={1.5}>
        <Typography variant="h5">{name}</Typography>
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex">
        <Box width={"50%"}>
          <Box>
            <Typography variant="h6">Summary : </Typography>
          </Box>
          <Box
            style={{
              position: "absolute",
              top: "50%",
            }}
          >
            <Box>
              <Typography variant="h7">Name : {name}</Typography>
            </Box>
            <Box>
              <Typography variant="h7">Summary : {summary}</Typography>
            </Box>
            <Box>
              <Typography variant="h7">Primary ID : {primary_id}</Typography>
            </Box>
          </Box>
        </Box>
        <Box width={"50%"}>
          <Box>
            <Typography variant="h6">Workflow Triggers :</Typography>
          </Box>
          <Box>
            <Checkbox
              checked={appointment_updates}
              onChange={() => {
                setAppointment_updates(!appointment_updates);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography variant="h7">Appointment Updates </Typography>
          </Box>
          <Box>
            <Checkbox
              checked={signed_order_updates}
              onChange={() => {
                setSigned_order_updates(!signed_order_updates);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography variant="h7">Signed order updates</Typography>
          </Box>
          <Box>
            <Checkbox
              checked={appointment_summary}
              onChange={() => {
                setappointment_summary(!appointment_summary);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography variant="h7">Appointment Summary</Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            save();
            closeModal();
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
export default Summary;
