import List from "@mui/material/List";
import HomeIcon from "@mui/icons-material/Home";
import CodeIcon from "@mui/icons-material/Code";
import LinkIcon from "@mui/icons-material/Link";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { useNavigate } from "react-router-dom";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import ShareIcon from "@mui/icons-material/Share";
import HomeIconWrapper from "../mui-wrappers/mui-components/HomeIconWrapper";

import { Item } from "./DrawerItem";
import { Settings, Tune } from "@mui/icons-material";
// import { Article } from "@mui/icons-material";
// import { Divider } from "@mui/material";

export const DashboardList = ({ open }) => {
  const navigate = useNavigate();
  return (
    <>
      <List>
        <Item
          text="Home"
          icon={<HomeIconWrapper />}
          nav={() => navigate("/")}
          open={open}
        />
        <Item
          text="Care Flows"
          icon={<ShareIcon />}
          nav={() => navigate("/care-flow")}
          open={open}
        />
        <Item
          text="User Journeys"
          icon={<DynamicFormIcon />}
          nav={() => navigate("/form-journey")}
          open={open}
        />
        <Item
          text="Forms"
          icon={<FormatAlignRightIcon />}
          nav={() => navigate("/user-forms")}
          open={open}
        />
        {/* <Item
          text="IDE"
          icon={<CodeIcon />}
          nav={() => navigate("/ide")}
          open={open}
        /> */}
        <Item
          text="Integrations"
          icon={<LinkIcon />}
          nav={() => navigate("/integrations")}
          open={open}
        />
        <Item
          text="Custom Spaces"
          icon={<WorkspacesIcon />}
          nav={() => navigate("/custom-spaces")}
          open={open}
        />
        {/* <Item
          text="Custom Actions"
          icon={<Tune />}
          nav={() => navigate("/custom_action")}
          open={open}
        /> */}
        <Item
          text="Settings"
          icon={<Settings />}
          nav={() => navigate("/user_settings/")}
          open={open}
        />
      </List>
      {/* <Divider />
      <List>
        <Item
          text="Page1"
          icon={<Article />}
          nav={() => navigate("/page1")}
          open={open}
        />
        <Item
          text="Page2"
          icon={<Article />}
          nav={() => navigate("/page2")}
          open={open}
        />
      </List> */}
    </>
  );
};
