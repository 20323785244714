import { Alert, CircularProgress } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import "./Alert.css";
const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [type, setType] = useState(""); //success,info,warning,error
  const [showLoader, setShowLoader] = useState(false);
  const handleAlert = (message, type = "error") => {
    setAlertMessage(message);
    setShowAlert(true);
    setType(type);
    setTimeout(() => {
      setShowAlert(false);
    }, 6000);
  };

  const handleLoader = async (callbackFn) => {
    setShowLoader(true);
    await callbackFn();
    setShowLoader(false);
  };

  return (
    <AlertContext.Provider value={{ handleLoader, handleAlert }}>
      {showAlert && (
        <div className="alert-overlay">
          <Alert
            className="alert-content"
            severity={type}
            onClose={() => {
              setShowAlert(false);
            }}
          >
            {alertMessage}
          </Alert>
        </div>
      )}
      {showLoader && (
        <div className="loader-overlay">
          <CircularProgress />
        </div>
      )}
      {children}
    </AlertContext.Provider>
  );
};
