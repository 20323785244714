import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import axios from "axios";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function StripeCheckout({
  org_id,
  amount,
  handleNextScreenPage = () => {},
  handleBackScreenPage = () => {},
  setAnswerForWidget = () => {}
}) {
  const [clientSecret, setClientSecret] = useState("");
  const createIntent = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PAYMENT_SERVER}/payments/payment-intent`,
        {
          amount: amount,
          org_id: org_id,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = response.data;
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    createIntent();
  }, []);

  const options = {
    clientSecret,
  };

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <div style={{ width: "500px", margin: "20px" }}>
            <CheckoutForm
              handleNextScreenPage={handleNextScreenPage}
              handleBackScreenPage={handleBackScreenPage}
              setAnswerForWidget={setAnswerForWidget}
            />
          </div>
        </Elements>
      )}
    </div>
  );
}
