import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { postRequest } from "../../../utils/request";
import { useSelector } from "react-redux";
import { isTokenValid } from "../../../utils/sessionutils";
import { useAlert } from "../../../store/context/AlertContext";

export const Appsetup = () => {
  const { handleAlert } = useAlert();
  const userData = useSelector((state) => state.user);
  const [otp, setOtp] = useState("");
  const [verified, setVerified] = useState(userData.user.mfa_app);
  const [app, setApp] = useState(userData.user.mfa_app);
  const [appWaiting, setAppWaiting] = useState(false);
  const [otpData, setOtpData] = useState(null);
  useEffect(() => {
    enable();
  }, []);
  const enable = async () => {
    setAppWaiting(true);
    // console.log(user.email);
    try {
      const response = await postRequest("mfa/generateotp", {
        email: userData.user.email,
        type: "APP",
      });
      setOtpData(response.data);
      setApp(true);
      setAppWaiting(false);
    } catch (e) {
      console.log(e);
    }
  };
  const verifyOtp = async () => {
    try {
      const response = await postRequest("mfa/verifyotp", {
        token: otp,
        email: userData.user.email,
        type: "APP",
      });
      console.log(response.data);
      setVerified(true);
      isTokenValid();
    } catch (err) {
      console.log(err);
      handleAlert("Could not verify, try again");
      // alert("Could not verify, try again");
    }
  };
  return (
    <>
      <Box>
        {appWaiting && <CircularProgress size="25px" />}
        {app &&
          (verified ? (
            <Alert severity="success">Google Authenticator is enabled</Alert>
          ) : (
            <Box textAlign={"center"}>
              <Typography m={"20px"} variant="h6" fontWeight={"bold"}>
                Open google auteticator app
              </Typography>
              <Typography m={"20px"} variant="h6" fontWeight={"bold"}>
                Scan QR Code
              </Typography>
              <Box m={"20px"} display={"flex"} justifyContent={"center"}>
                {console.log(otpData)}
                {otpData && <QRCode value={otpData.qrcode} />}
              </Box>
              <Typography m={"20px"} variant="h6" fontWeight={"bold"}>
                or
              </Typography>
              <Typography m={"20px"} variant="h6" fontWeight={"bold"}>
                Enter the following code:
              </Typography>
              {otpData && (
                <Typography m={"20px"} variant="h5" fontWeight={"bold"}>
                  {otpData.code}
                </Typography>
              )}
              <Box
                display={"flex"}
                flexDirection={"row"}
                sx={{ verticalAlign: "bottom" }}
                justifyContent={"center"}
              >
                <Typography
                  marginRight={"10px"}
                  variant="h6"
                  fontWeight={"bold"}
                >
                  Enter the otp:
                </Typography>
                <TextField
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  variant="standard"
                  sx={{ verticalAlign: "bottom" }}
                ></TextField>
              </Box>
              <Button
                sx={{ margin: "20px" }}
                onClick={() => {
                  verifyOtp();
                }}
              >
                Verify
              </Button>
            </Box>
          ))}
      </Box>
    </>
  );
};
