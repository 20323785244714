import { ReactComponent as LogoIcon } from "../../assets/images/logo/healthblox_icon.svg";
import { ReactComponent as LogoText } from "../../assets/images/logo/healthblox_text.svg";
import { ReactComponent as LogoIconWhite } from "../../assets/images/logo/healthblox_icon_white.svg";
import { ReactComponent as LogoTextWhite } from "../../assets/images/logo/healthblox_text_white.svg";
import BoxWrapper from "../../mui-wrappers/mui-components/BoxWrapper";

export const HealthBloxLogo = ({ height, color, ...rest }) => {
  // Default fallback for height and color props
  const logoHeight = height || "40px"; // Default height if not provided
  const logoColor = color || "white"; // Default color fallback

  return (
    <BoxWrapper
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      {...rest}
    >
      {logoColor === "white" ? (
        <>
          <LogoIconWhite
            style={{
              height: logoHeight,
              display: "block",
            }}
          />
          <LogoTextWhite style={{ height: logoHeight }} />
        </>
      ) : (
        <>
          <LogoIcon
            style={{
              height: logoHeight,
              display: "block",
            }}
          />
          <LogoText style={{ height: logoHeight }} />
        </>
      )}
    </BoxWrapper>
  );
};
