import { Box, Button, Typography } from "@mui/material";

const Test = () => {
  return (
    <Box backgroundColor="#fbfbfb" height="75vh" m="20px">
      <Box
        display="flex"
        justifyContent={"space-between"}
        p={2}
        backgroundColor="#e8e8e8"
      >
        <Typography variant="h6" style={{ verticalAlign: "center" }}>
          Test Event
        </Typography>
        <Box>
          <Button>Save</Button>
          <Button>Test</Button>
        </Box>
      </Box>
      <Box m="20px">
        <Box>
          <Typography variant="p">
            To invoke your function without saving an event, configure the JSON
            event, then choose Test.
          </Typography>
        </Box>
        <Box>
          <Typography variant="p">Test event action</Typography>
        </Box>
        <Box>
          <Typography variant="p">Test name</Typography>
        </Box>
        <Box>
          <Typography variant="p">
            Maximum of 25 characters consisting of letters, numbers, dots,
            hyphens and underscores.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default Test;
