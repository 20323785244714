import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import ReactQuill from "react-quill";
// import "quill/dist/quill.snow.css";
import { useState } from "react";
import { upload } from "../../utils/uploadfuctions";
import { useSelector } from "react-redux";

const UploadNotes = ({ closeModal, next }) => {
  const [note, setNote] = useState("");
  const [waiting, setWaiting] = useState(true);
  const appointment = useSelector((state) => state.appointment);
  const details = {
    appointmentid: appointment.appointmentId,
    practiceid: appointment.practiceId,
  };
  const handleChange = (event) => {
    setNote(event.target.value);
    event.target.value ? setWaiting(false) : setWaiting(true);
  };

  const save = async () => {
    setWaiting(true);
    await upload(note, next, false, details);
    setWaiting(false);
  };
  return (
    <Box>
      <Box display="flex" justifyContent={"space-between"}>
        <Typography variant="h6">ADD APPOINTMENT NOTES</Typography>
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box marginY={"20px"}>
        {/* <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          placeholder="add your note ...."
          onChange={handleProcedureContentChange}
          style={{ height: "200px" }}
        /> */}
        <TextField
          style={{ width: "100%" }}
          id="appointmentnote"
          name="note"
          label="Add Notes"
          value={note}
          onChange={handleChange}
          multiline
          rows={4}
        />
      </Box>
      <Box display="flex" justifyContent={"flex-end"}>
        <Button disabled={waiting} onClick={save}>
          SAVE
        </Button>
      </Box>
    </Box>
  );
};
export default UploadNotes;

// var modules = {
//   toolbar: [
//     [
//       { size: ["small", "normal", "large", "huge"] },
//       "bold",
//       "italic",
//       "underline",
//       "strike",
//       "blockquote",
//       "link",
//       "image",

//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//       { align: [] },
//       {
//         color: [
//           "#000000",
//           "#e60000",
//           "#ff9900",
//           "#ffff00",
//           "#008a00",
//           "#0066cc",
//           "#9933ff",
//           "#ffffff",
//           "#facccc",
//           "#ffebcc",
//           "#ffffcc",
//           "#cce8cc",
//           "#cce0f5",
//           "#ebd6ff",
//           "#bbbbbb",
//           "#f06666",
//           "#ffc266",
//           "#ffff66",
//           "#66b966",
//           "#66a3e0",
//           "#c285ff",
//           "#888888",
//           "#a10000",
//           "#b26b00",
//           "#b2b200",
//           "#006100",
//           "#0047b2",
//           "#6b24b2",
//           "#444444",
//           "#5c0000",
//           "#663d00",
//           "#666600",
//           "#003700",
//           "#002966",
//           "#3d1466",
//           "custom-color",
//         ],
//       },
//     ],
//   ],
// };
// var formats = [
//   "header",
//   "height",
//   "bold",
//   "italic",
//   "underline",
//   "strike",
//   "blockquote",
//   "list",
//   "color",
//   "bullet",
//   "indent",
//   "link",
//   "image",
//   "align",
//   "size",
// ];
