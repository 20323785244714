import React, { useState } from "react";
import DocsHeader from "./header";
import DocsSidebar from "./sidebar";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
const markdownAdditionalScript = `
\`\`\`js
<script src="https://unpkg.com/react@18/umd/react.production.min.js"></script>
<script src="https://unpkg.com/react-dom@18/umd/react-dom.production.min.js"></script>
\`\`\`
`;
const markdownBundleScript = `
\`\`\`js
//index.html
<script src="path/to/hbx-chart-bundle.min.js"></script>
\`\`\`
`;

const GettingStarted = () => {
  return (
    <div>
      <DocsHeader />
      <div className="flex flex-row gap-4">
        <DocsSidebar />
        <div className="grow px-3">
          <div className="py-2 border-b block border-b-gray-400 text-lg text-violet-800	">
            V1
          </div>
          <div className="py-3 text-3xl">Getting started</div>
          <div className="text-xl">
            During the course of this tutorial we'll get acquainted with the
            general concepts behind hbx-chart, terminology used, and other
            things to get you started.
          </div>
          <div className="pt-3 pb-1 text-xl font-semibold">Overview</div>
          <div className="text-lg">
            hbx-chart is a lightweight and powerful JavaScript charting
            library. It allows developers to easily integrate interactive and
            customizable charts into their web applications. The library is
            compiled into a single minified bundle for ease of use and optimized
            performance.
          </div>
          <div className="text-lg">
            The bundle comes with all the necessary dependencies, meaning no
            additional libraries are required for basic functionality.
          </div>
          <div className="pt-3 pb-1 text-xl font-semibold">Installation</div>
          <div className="text-lg">
            hbx-chart comes in as a JavaScript bundle (ES6) file.
          </div>
          <div className="text-lg">
            You can use our official website to download hbx-chart minified
            javascript bundle into your system. It is compiled with all the
            required dependencies.
          </div>

          <div className="pt-3 pb-1 text-xl font-semibold">
            Download the Minified Bundle
          </div>
          <div className="text-lg">
            You can download our standalone module containing everything you
            need to independently run hbx-chart here. 
            <span className="underline text-blue-500 cursor-pointer">
              {" "} hbx-chart-bundle.min.js
            </span>
          </div>
          <div className="pt-3 pb-1 text-xl font-semibold">
            Additional Scripts
          </div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {markdownAdditionalScript}
            </ReactMarkdown>
          </div>
          <div className="pt-3 pb-1 text-xl font-semibold">
            Loading script files
          </div>
          <div className="text-lg pb-2">
            Loading a hbx-chart module file is done via {" "}
            <span className="text-rose-500">{`<script>`}</span> tags in your
            HTML.
          </div>
          <div className="text-lg pb-2">
            The easiest option is to use our bundle and keep that in public
            folder of your project repository. Just point your{" "}
            <span className="text-rose-500">{`<script>`}</span> tag to the
            path pointing towards hbx-chart-bundle.min.js ..... E.g.:
          </div>
          <div
            style={{
              backgroundColor: "#2d2d2d",
              color: "#f8f8f2",
            }}
            className="rounded"
          >
            <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
              {markdownBundleScript}
            </ReactMarkdown>
          </div>
          <div className="text-lg pb-2">
            Also you need to add latest React and React dom script for better
            rendering of all the dependencies in your module. you can skip this
            script if youy have react v18 and react-dom v18 is already
            installed.
          </div>
        </div>
      </div>
    </div>
  );
};
export default GettingStarted;
