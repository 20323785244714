import React, { useEffect, useState } from "react";
import "./FormPlayGround.css";
import { Form } from "@formio/react";
import { GrFormEdit } from "react-icons/gr";
import { useDrop } from "react-dnd";
import { useNavigate } from "react-router-dom";
import { getFormData, getStandardFormData } from "../../../utils/form";
import { MdOutlineRemoveCircle } from "react-icons/md";
import axios from "axios";
import { getAuthToken } from "../../../utils/cookie";
import WidgetModalBox from "../../../components/common/widgetModal";
import WidgetModalBoxForScheduler from "../../../components/common/widgetModalBoxForScheduler";

const FormPlayGround = ({ playGroundData, onDrop }) => {
  const [activeFormPage, setActiveFormPage] = useState(null);
  const [data, setData] = useState(null);
  const [type, setType] = useState(null);
  const [formData, setFormData] = useState(null);
  const [showModalForPayment, setShowModalForPayment] = useState(false);
  const [showModalForScheduler, setShowModalForScheduler] = useState(false);

  const formId = playGroundData?.formId;
  const { widget } = playGroundData;

  let navigate = useNavigate();

  function handleClick(id) {
    setActiveFormPage(id);
    formData?.map((item) => {
      if (item.id === id) {
        setData(item.content);
      }
    });
  }

  const [, drop] = useDrop({
    accept: ["Form", "Widget"],
    drop: async (data) => {
      if (data.type === "scheduler-widget") {
        setShowModalForScheduler(true);
      }
      if (data.type === "widget") {
        setShowModalForPayment(true);
      }
      onDrop(data);
    },
  });

  function handleModalConfirm(data) {
    if (data?.paymentType === "payInstant") {
      playGroundData.widget.paymentType = "payment";
      playGroundData.widget.amount = data?.amount;
      playGroundData.widget.save_details =
        data?.saveDetails?.includes("saveDetails");
    } else if (data?.paymentType === "payLater") {
      playGroundData.widget.paymentType = "setup";
      playGroundData.widget.amount = "Pay Later";
    }
    onDrop(playGroundData);
    setShowModalForPayment(false);
  }

  async function handleSchedulerModalConfirm(data) {
    const { clientIntegration } = data;
    const { selectedScheduler } = data;
    if (!clientIntegration) {
      console.log("No Client Integration Found");
      return;
    }

    // let id = await createSchedulerWidget(playGroundData, clientIntegration);
    playGroundData.id = selectedScheduler;
    playGroundData.widget.id = selectedScheduler;
    onDrop(playGroundData);
    setShowModalForScheduler(false);
  }

  function handleEditRedirection() {
    let path = `/edit-form/${formId}`;
    navigate(path);
  }

  const fetchPlayGroundData = async () => {
    if (playGroundData?.type === "user-form") {
      const res = await getFormData(formId);
      setFormData(res?.data?.form?.form_schema?.formData);
      setData(res?.data?.form?.form_schema?.formData[0]?.content);
      setActiveFormPage(res?.data?.form?.form_schema?.formData[0]?.id);
    } else if (
      playGroundData?.type === "widget" ||
      playGroundData?.type === "scheduler-widget"
    ) {
      setData(playGroundData?.widget);
    } else if (playGroundData?.type === "standard-form") {
      const res = await getStandardFormData(formId);
      setFormData(res?.data?.form?.form_schema?.formData);
      setData(res?.data?.form?.form_schema?.formData[0]?.content);
      setActiveFormPage(res?.data?.form?.form_schema?.formData[0]?.id);
    } else setData({});
  };

  const checkForSchedulerId = async (_CI) => {
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/widgets?client_integration_ids=${_CI}`;

    const headers = getAuthToken();
    try {
      const response = await axios.get(API_URL, headers);
      const { data } = response.data;

      if (data?.length) return data[0]?._id;
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const createNewSchedulerId = async (widget, client_integration) => {
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/widgets`;

    const headers = getAuthToken();
    try {
      const response = await axios.post(
        API_URL,
        {
          client_integration: client_integration,
          label: widget?.label,
          scheduler_status: widget?.scheduler_status,
          type: widget?.type,
        },
        headers
      );

      const { schedulerWidget } = response?.data;
      const { _id } = schedulerWidget;
      return _id;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const createSchedulerWidget = async (playGroundData, clientIntegration) => {
    const { widget } = playGroundData;
    const client_integration = clientIntegration;
    if (!!client_integration) {
      let schedulerId = null;
      schedulerId = await checkForSchedulerId(client_integration);
      if (!schedulerId)
        schedulerId = await createNewSchedulerId(widget, client_integration);
      return schedulerId;
    } else {
      console.log("No client integration found");
      return null;
    }
  };
  useEffect(() => {
    if (playGroundData) {
      setType(playGroundData?.type);
      fetchPlayGroundData();
    }
  }, [playGroundData]);
  return (
    <div className="form-sidebar custom-card card">
      <div className="border-b border-zinc-400 px-16 py-2 text-center text-xl font-semibold flex justify-between">
        <div className="">Screen PlayGround</div>
        <div className="flex justify-end">
          {type === "user-form" ? (
            <div
              className="flex cursor-pointer text-indigo-700 px-3"
              onClick={handleEditRedirection}
            >
              Edit Form
              <GrFormEdit size={28} className="pl-1" />
            </div>
          ) : (
            <></>
          )}
          {type && (type === "standard-form" || type === "user-form") ? (
            <div
              className="flex cursor-pointer text-red-500"
              onClick={() => onDrop({})}
            >
              <MdOutlineRemoveCircle size={28} className="pl-1" />
              Remove Form
            </div>
          ) : type && type === "widget" ? (
            <div
              className="flex cursor-pointer text-red-500"
              onClick={() => onDrop({})}
            >
              <MdOutlineRemoveCircle size={28} className="pl-1" />
              Remove Widget
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="h-screen overflow-y-scroll remove-scroll" ref={drop}>
        {type && (type === "standard-form" || type === "user-form") ? (
          <div className="">
            {formData && formData?.length ? (
              <div className="flex pl-16 mb-2 mt-3">
                {formData.map((form, index) => {
                  return (
                    <div
                      key={index + form.label}
                      className={
                        activeFormPage === form.id
                          ? "mustard_bg p-2 mr-2 rounded cursor-pointer text-white font-semibold"
                          : "shadow bg-white p-2 mr-2 rounded cursor-pointer"
                      }
                      onClick={() => handleClick(form.id)}
                    >
                      {form.label}
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}

            <div>
              <Form form={data} />
            </div>
          </div>
        ) : (
          <></>
        )}
        {type && type === "widget" ? (
          <>
            <div className="text-center ml-16 mustard_bg p-2 rounded cursor-pointer text-white font-semibold inline-block mb-2 mt-3">
              Payment Widget
            </div>
            {showModalForPayment && (
              <WidgetModalBox
                openModal={showModalForPayment}
                rightButtonCallback={handleModalConfirm}
              />
            )}
            <div className="text-center font-semibold text-lg py-3 shadow my-5 mx-24 rounded">
              Amount to be collected from User : {data?.amount}
            </div>
          </>
        ) : (
          <></>
        )}

        {type && type === "scheduler-widget" ? (
          <>
            <div className="text-center mustard_bg p-2 rounded cursor-pointer text-white font-semibold inline-block mb-2 ml-16 mt-3">
              Scheduler Widget
            </div>

            {showModalForScheduler && (
              <WidgetModalBoxForScheduler
                openModal={showModalForScheduler}
                rightButtonCallback={handleSchedulerModalConfirm}
                widget={widget}
              />
            )}

            <div className="text-center font-semibold text-lg py-3 shadow my-5 mx-24 rounded">
              Appointment Scheduler is enabled for this Journey.
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default FormPlayGround;
