import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { useDrag } from "react-dnd";

const FormCard = ({ form, type}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "Form",
    item: { formId: form?._id, type: type },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <>
      <div
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
        }}
        className="card w-64 h-32 custom-card my-1 p-2 cursor-pointer"
      >
        <div className=" text-center font-semibold pb-2">{form.label}</div>
        {form &&
          form?.form_schema &&
          form?.form_schema?.formData &&
          form?.form_schema?.formData.length &&
          form?.form_schema?.formData.map((formPage, index) => {
            return formPage && index < 2 &&
              formPage?.content &&
              formPage?.content?.components &&
              formPage?.content?.components.length ? (
              formPage?.content?.components.map((item, idx) => {
                return item.type !== "button" && idx < 2 ? (
                  <div>
                    {item.label}
                    <Skeleton variant="text" animation={false} />
                  </div>
                ) : (
                  <></>
                );
              })
            ) : (
              <></>
            );
          })}
      </div>
    </>
  );
};
export default FormCard;
