import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import CustomInput, {
  CustomInputSelect,
} from "../../components/custom/CustomInput";
import { useState } from "react";
import CustomButton from "../../components/custom/CustomButton";
import { postRequest } from "../../utils/request";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";

export const MappingTable = ({
  type,
  fields,
  columns,
  spaceId,
  csvId,
  handleNext,
}) => {
  const [mappingArray, setMappingArray] = useState(
    fields.map((field) => {
      return {
        old_name: field.display_name,
        key: field.key,
        display_name: field.display_name,
      };
    })
  );
  const navigate = useNavigate();
  const handleFileUpload = async () => {
    console.log(csvId);
    if (csvId) {
      try {
        const response = await postRequest(
          `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customtable/csv/${spaceId}/${csvId}`,
          { mapping: mappingArray }
        );
        handleNext();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleSelectColumn = (i, newValue) => {
    let tempFields = JSON.parse(JSON.stringify(mappingArray));
    tempFields[i]["display_name"] = newValue;
    setMappingArray(tempFields);
  };
  const handleDeleteColumn = (i) => {
    let tempFields = JSON.parse(JSON.stringify(mappingArray));
    tempFields.splice(i, 1);
    setMappingArray(tempFields);
  };

  return (
    <Stack spacing={2} p={2}>
      <Box
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">Map CSV Columns</Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <Box
          sx={{ overflowY: "auto", maxHeight: type === "display" ? 600 : 400 }}
          p={3}
        >
          {fields.length > 0 ? (
            <TableContainer
              component={Paper}
              sx={{
                border: "1px solid #3F3F3F1A",
                borderRadius: 5,
                scrollbarWidth: "none",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key={"columnName"}
                      sx={{
                        textAlign: "center",
                        background: "#f9fafb",
                        color: "#667085",
                        fontWeight: "600",
                        maxWidth: 250,
                      }}
                    >
                      Column Name
                    </TableCell>
                    <TableCell
                      key={"key"}
                      sx={{
                        textAlign: "center",
                        background: "#f9fafb",
                        color: "#667085",
                        fontWeight: "600",
                        maxWidth: 250,
                      }}
                    >
                      Key
                    </TableCell>
                    <TableCell
                      key={"CSVcolumnName"}
                      sx={{
                        textAlign: "center",
                        background: "#f9fafb",
                        color: "#667085",
                        fontWeight: "600",
                        maxWidth: 250,
                      }}
                    >
                      CSV Column Name
                    </TableCell>
                    <TableCell
                      key={"actions"}
                      align="center"
                      style={{
                        textAlign: "center",
                        width: 100,
                        background: "#f9fafb",
                        color: "#667085",
                        fontWeight: "600",
                      }}
                      className={"sticky-actions-header"}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mappingArray &&
                    mappingArray.length &&
                    mappingArray.map((fieldName, index) => (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            fontSize: 16,
                            textAlign: "center",
                          }}
                        >
                          <Box>
                            <CustomInput
                              fullWidth
                              variant="standard"
                              value={fieldName?.old_name}
                              disabled
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 16,
                            textAlign: "center",
                          }}
                        >
                          <Box>
                            <CustomInput
                              disabled
                              fullWidth
                              variant="standard"
                              value={fieldName?.key}
                            />
                          </Box>
                        </TableCell>
                        <TableCell sx={{ fontSize: 16, textAlign: "center" }}>
                          <Box sx={{ maxWidth: 250 }}>
                            <CustomInputSelect
                              menuItems={columns}
                              value={fieldName?.display_name}
                              onChange={(e) => {
                                handleSelectColumn(index, e.target.value);
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          key={"actions"}
                          align="center"
                          sx={{ fontSize: 16, textAlign: "center" }}
                          className={"sticky-actions"}
                        >
                          <IconButton onClick={() => handleDeleteColumn(index)}>
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <div>
                <div className="text-center font-semibold text-lg py-3 shadow my-5 mx-96 rounded">
                  No Data Found
                </div>
              </div>
            </>
          )}
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
          <CustomButton onClick={handleFileUpload}>Next</CustomButton>
        </Box>
      </Box>
    </Stack>
  );
};
