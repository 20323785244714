import React, { useState } from "react";
import FormContext from "./FormContext";

const FormContextProvider = ({children}) => {
    const [schema, setSchema] = useState(null)
    const [activeScreen, setActiveScreen] = useState(null)
    const [activePage, setActivePage] = useState(null)
    const [answerData, setAnswerData] = useState({})
    const [journeyId, setJourneyId] = useState(null)
    return (
        <FormContext.Provider value = {{ schema, setSchema, activeScreen, setActiveScreen, activePage, setActivePage, answerData, setAnswerData, setJourneyId, journeyId}}>
            {children}
        </FormContext.Provider>
    )

}

export default FormContextProvider