import { Box, Fab, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "./IntegrationsTable";
import CreateIntegration from "./Modal/CreateIntegration";
import { useSelector } from "react-redux";
import { isTokenValid } from "../../../utils/sessionutils";

const Integrations = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const integrations = useSelector((state) => state.integration);
  // const handleSave = (name, platform, primary_id) => {
  //   console.log(name, platform, primary_id);
  //   setData([
  //     {
  //       name: name,
  //       platform: platform,
  //       primary_id: primary_id,
  //     },
  //   ]);
  //   setOpen(false);
  // };
  useEffect(() => {
    isTokenValid();
  }, []);
  return (
    <Box m="20px">
      <Box display="flex" justifyContent={"flex-end"} p={1.5}>
        {/* <Box>
          <Typography variant="h5">INTEGRATIONS</Typography>
        </Box> */}
        <Box>
          <Fab color="primary" aria-label="add" onClick={handleOpen}>
            <AddIcon />
          </Fab>
        </Box>
      </Box>
      <Box m="20px">
        {integrations.length > 0 ? (
          <StickyHeadTable />
        ) : (
          <Typography variant="h3" style={{ opacity: "0.7" }}>
            Create your first Integration
          </Typography>
        )}{" "}
      </Box>
      {/* <Box m="20px">
        <Button onClick={handleOpen}>FORM</Button>
      </Box> */}

      <Modal open={open}>
        <Box sx={style}>
          <CreateIntegration closeModal={handleClose} />
        </Box>
      </Modal>
    </Box>
  );
};

export default Integrations;
export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
