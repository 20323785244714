import RoutesPage from "./Routes";
import CssBaseline from "@mui/material/CssBaseline";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CombinedProviders from "./store/context/CombinedContextProvider";

function App() {
  return (
    <CombinedProviders>
      <CssBaseline />
      <div className="app">
        <DndProvider backend={HTML5Backend}>
          <RoutesPage />
        </DndProvider>
      </div>
    </CombinedProviders>
  );
}

export default App;
