import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";

const Configuration = () => {
  const [value, setValue] = useState(0);
  return (
    <Box>
      <Box m="20px">CONFIGURATION</Box>
      <Box backgroundColor="#fbfbfb" display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          // justifyContent={"space-between"}
          p={2}
          backgroundColor="#e8e8e8"
          //   width="auto"
        >
          <Item
            onClick={() => {
              setValue(0);
            }}
            text="General Confgurations"
          />
          <Item
            onClick={() => {
              setValue(1);
            }}
            text="Triggers"
          />
          <Item
            onClick={() => {
              setValue(2);
            }}
            text="Permissions"
          />
          <Item
            onClick={() => {
              setValue(3);
            }}
            text="Destinations"
          />
          <Item
            onClick={() => {
              setValue(4);
            }}
            text="Function URL"
          />
          <Item
            onClick={() => {
              setValue(5);
            }}
            text="Enviroment Variables"
          />
          <Item
            onClick={() => {
              setValue(6);
            }}
            text="Tags"
          />
          <Item
            onClick={() => {
              setValue(7);
            }}
            text="VPC"
          />
          <Item
            onClick={() => {
              setValue(8);
            }}
            text="Monitoring & operation tools"
          />
          <Item
            onClick={() => {
              setValue(9);
            }}
            text="Concurrency"
          />
          <Item
            onClick={() => {
              setValue(10);
            }}
            text="Asynchronous Invocation"
          />
          <Item
            onClick={() => {
              setValue(11);
            }}
            text="Code Signing"
          />
          <Item
            onClick={() => {
              setValue(12);
            }}
            text="Database Proxies"
          />
          <Item
            onClick={() => {
              setValue(13);
            }}
            text="File systems"
          />
          <Item
            onClick={() => {
              setValue(14);
            }}
            text="State Machines"
          />
        </Box>
        <Box marginLeft="40px" marginTop="20px">
          {value === 0 && <Typography>General Cofiguration</Typography>}
          {value === 1 && <Typography>Triggers</Typography>}
          {value === 2 && <Typography>Permissions</Typography>}
          {value === 3 && <Typography>Destinations</Typography>}
          {value === 4 && <Typography>Function URL</Typography>}
          {value === 5 && <Typography>Enviroment Variables</Typography>}
          {value === 6 && <Typography>Tags</Typography>}
          {value === 7 && <Typography>VPC</Typography>}
          {value === 8 && (
            <Typography>Monitoring and operation tools</Typography>
          )}
          {value === 9 && <Typography>Concurrency</Typography>}
          {value === 10 && <Typography>Asynchronous Invocation</Typography>}
          {value === 11 && <Typography>Code Signing</Typography>}
          {value === 12 && <Typography>Database Proxies</Typography>}
          {value === 13 && <Typography>File systems</Typography>}
          {value === 14 && <Typography>State Machines</Typography>}
        </Box>
      </Box>
    </Box>
  );
};
export default Configuration;

const Item = ({ text, onClick }) => {
  return (
    <Button style={{ justifyContent: "flex-start" }} onClick={onClick}>
      {text}
    </Button>
  );
};
