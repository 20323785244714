import React from "react";
import "./docs.scss";
import { useLocation } from "react-router-dom";

const DocsSidebar = () => {
  let location = useLocation();
  const route = location?.pathname;
  console.log(route)
  return (
    <div className="min-w-64 rounded bg-gray-100 charts-docs-sidebar">
      <a
        href="/charts/docs"
        className={`${
          route === "/charts/docs" ? "highlightedText " : ""
        }px-3 py-2 border-b-2 border-white text-lg no-underline block font-semibold`}
      >
        Overview
      </a>
      <a
        href="/charts/docs/getting-started"
        className={`${
          route === "/charts/docs/getting-started" ? "highlightedText " : ""
        }px-3 pb-1 pt-3 text-md no-underline block`}
      >
        Getting started
      </a>
      <a
        href="/charts/docs/quick-start-guide"
        className={`${
          route === "/charts/docs/quick-start-guide" ? "highlightedText " : ""
        }px-3 py-1 text-md no-underline block`}
      >
        Quick Start Guide
      </a>
      <a
        href="/charts/docs/integration"
        className={`${
          route === "/charts/docs/integration" ? "highlightedText " : ""
        }px-3 pt-1 pb-3 text-md no-underline block`}
      >
        Integration
      </a>
      <div className="px-3 py-2 border-b-2 border-t border-t-gray-400 border-b-white text-lg">
        HBX Charts
      </div>
      <a
        href="/charts/docs/hbx-line-graph"
        className={`${
          route === "/charts/docs/hbx-line-graph" ? "highlightedText " : ""
        }px-3 pt-2 pb-2 text-md no-underline block`}
      >
        Line Graph
      </a>
    </div>
  );
};
export default DocsSidebar;
