import { Box, Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AnimatedTick from "../animatedTick/animatedTick";

const Complete = ({ closeModal, next, text }) => {
  return (
    <Box>
      <Box display="flex" justifyContent={"space-between"}>
        <Typography variant="h6">UPLOAD COMPLETE</Typography>
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box display="flex" justifyContent={"center"}>
        <Box
          m={"20px"}
          display="flex"
          flexDirection={"column"}
          style={{ alignSelf: "center" }}
        >
          <AnimatedTick style={{ alignSelf: "center" }} />
          <Typography color={"green"} style={{ alignSelf: "center" }}>
            SUCCESS
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent={"flex-end"}>
        <Button onClick={next}>{text}</Button>
        <Button onClick={closeModal}>CLOSE</Button>
      </Box>
    </Box>
  );
};
export default Complete;
