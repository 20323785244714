import "./common.scss";

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import InputRadio from "./InputRadio";
import InputNumber from "./InputNumber";
import InputSingleCheckBox from "./InputSingleCheckBox";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

function WidgetModalBox({
  openModal,
  SubTitle,
  rightButtonCallback,
}) {
  const [open, setOpen] = React.useState(openModal);
  const [paymentType, setPaymentType] = React.useState(null);
  const [amount, setAmount] = React.useState(0);
  const [saveDetails, SetSaveDetails] = React.useState([]);
  // const [open, setOpen] = React.useState(openModal);
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  return (
    <div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="box-modal">
            <div className="modal-content flex flex-col justify-center p-4">
              {/* <div className="modal-title mx-auto">
                {"PLEASE ENTER BELOW DETAILS"}
              </div> */}
              <div className="modal-subtitle mx-auto">{SubTitle}</div>

              <InputRadio
                data={{
                  label: "Please select type of payment",
                  values: [
                    { label: "Pay Later", value: "payLater" },
                    { label: "Pay Instant", value: "payInstant" },
                  ],
                  answer: paymentType,
                }}
                onChange={(data, answer) => {
                  setPaymentType(answer.value);
                }}
              />
              {paymentType && paymentType === "payInstant" ? (
                <>
                  <InputNumber
                    data={{
                      label: "Please enter amount",
                      answer: amount,
                    }}
                    onChange={(answer) => {
                      setAmount(answer.value);
                    }}
                  />
                  <InputSingleCheckBox
                    data={{
                      label: "Save Card Details",
                      key: "saveDetails",
                      answer: {
                        value: saveDetails
                      }
                    }}
                    setAnswer={(data, answer) => {
                      SetSaveDetails(answer.value);
                    }}
                    className="pb-3"
                  />
                </>
              ) : (
                <></>
              )}

              <div
                className="text-center grow modal-button modal-button-right"
                onClick={() => {
                  if(paymentType && paymentType === 'payLater') {
                    if (rightButtonCallback) {
                      rightButtonCallback({paymentType});
                    } else handleClose();
                  } else if(paymentType && paymentType === 'payInstant') {
                    if(amount && saveDetails) {
                      if (rightButtonCallback) {
                        rightButtonCallback({paymentType, amount, saveDetails});
                      } else handleClose();
                    }
                  }
                  
                }}
              >
                Confirm
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
export default WidgetModalBox;
