import React, { useState } from "react";
import PropTypes from "prop-types";
import "./common.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";

const TableContent = ({
  columns = [],
  rows = [],
  emptyMessage = "No Data Found", // Custom message for no data
  stickyHeader = true, // Whether to use sticky header
  customCellStyle = {}, // Custom styles for cells
  className = "", // Additional class for the table container
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleSort = (columnId) => {
    const isAscending = orderBy === columnId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(columnId);
  };

  const sortedRows = React.useMemo(() => {
    if (!orderBy) return rows;
    return [...rows].sort((a, b) => {
      const valueA = a[orderBy];
      const valueB = b[orderBy];

      if (valueA < valueB) return order === "asc" ? -1 : 1;
      if (valueA > valueB) return order === "asc" ? 1 : -1;
      return 0;
    });
  }, [rows, order, orderBy]);

  return (
    <div className={`table-content ${className}`}>
      {rows.length ? (
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader={stickyHeader} aria-label="data table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      background: "#f9fafb",
                      color: "#667085",
                      fontWeight: "600",
                      ...customCellStyle, // Apply custom styles
                    }}
                  >
                    {column?.sort ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      <>{column.label}</>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows.map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                  className={row?.status === "PENDING" ? "bg_light_green" : ""}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value, row) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="text-center font-semibold text-lg py-3 shadow my-5 mx-auto rounded">
          {emptyMessage}
        </div>
      )}
    </div>
  );
};

// PropTypes for validation
TableContent.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      align: PropTypes.string,
      minWidth: PropTypes.number,
      format: PropTypes.func,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  emptyMessage: PropTypes.string,
  stickyHeader: PropTypes.bool,
  customCellStyle: PropTypes.object,
  className: PropTypes.string,
};

export default TableContent;
