import { Box, Modal } from "@mui/material";
const ModalBox = ({ children, ...rest }) => {
  return (
    <Modal {...rest}>
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};
export default ModalBox;
export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "90%",
  // overflowY: "auto",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};
