import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Login from "./pages/Login";
import Dashboard from "./pages/dashboard";
import SignUp from "./pages/SignUp";
import Home from "./pages/dashboard/home";
import Page1 from "./pages/dashboard/page1";
import Page2 from "./pages/dashboard/page2";
import Ide from "./pages/dashboard/IDE";
import Integrations from "./pages/dashboard/Integrations";
import CustomSpaces from "./pages/customSpaces";
import { UserSettings } from "./pages/settings/user";
import { Security } from "./pages/settings/user/Security";
import { Notifications } from "./pages/settings/user/Notifications";
import { Activity } from "./pages/settings/user/Activity";
import { Role } from "./pages/settings/user/Role";
import { UserInfo } from "./pages/settings/user/UserInfo";
import { OrganizationSettings } from "./pages/settings/organization";
import { OrganizationInfo } from "./pages/settings/organization/OrganizationInfo";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword ";
import FormBuilder from "./pages/formBuilder";
import FormJourney from "./pages/formJourney";
import EditForm from "./pages/editForm";
import { Payments } from "./pages/settings/organization/Payments";
import { SwitchOrg } from "./pages/settings/organization/SwitchOrg";
import { Teams } from "./pages/settings/organization/Teams";
import { Members } from "./pages/settings/organization/Members";
import UserForms from "./pages/userForms";
import { Stripe } from "./pages/PaymentElement";
import { Success } from "./pages/PaymentElement/Success";
import { Invalid } from "./pages/PaymentElement/Invalid";
import { AccountCreated } from "./pages/PaymentElement/AccountCreated";
import { JoinOrg } from "./pages/Login/joinOrg";
import { PrivateRoute } from "./utils/PrivateRoute";
import SignUpWithInvite from "./pages/SignUp/SignupWithInvite";
import NotFound from "./pages/NotFound";
import PaymentWidgetSetup from "./pages/Payment-setup/PaymentWidgetSetup";
import CreateWorkFlow from "./pages/workFlow";
import EditWorkFlow from "./pages/editWorkFlow";
import Scenarios from "./pages/scenarios";
import CustomAction from "./pages/customActions";
import EditSpace from "./pages/customSpaces/EditSpace";
import Docs from "./pages/docs/docs";
import GettingStarted from "./pages/docs/gettingStarted";
import RootElement from "./pages/docs/rootElement";
import ChartIntegration from "./pages/docs/integration";
import HbxCharts from "./pages/docs/charts";
import ScannedForm from "./pages/scannedForm";

const RoutesPage = () => {
  // const login = useSelector((state) => state.islogin);
  return (
    <Router>
      <Routes>
      <Route path="/scan" element={<ScannedForm />} />
      <Route path="/" element={<PrivateRoute />}>
          <Route path="/join-organization/:token" element={<JoinOrg />} />
          <Route path="/" element={<Dashboard />}>
            <Route path="/" element={<Home />} />
            <Route path="/ide" element={<Ide />} />
            <Route path="/integrations" element={<Integrations />} />
            <Route path="/custom-spaces" element={<CustomSpaces />} />
            <Route path="/edit-custom-space/:spaceID" element={<EditSpace />} />
            <Route
              path="/view-custom-space/:spaceID"
              element={<EditSpace initialStep={2} />}
            />
            <Route path="/custom_action" element={<CustomAction />} />
            <Route path="/page1" element={<Page1 />} />
            <Route path="/page2" element={<Page2 />} />
            <Route path="/form-journey" element={<FormJourney />} />
            <Route path="/user-forms" element={<UserForms />} />
            <Route path="/edit-form" element={<EditForm />} />
            <Route path="/edit-form/:formId" element={<EditForm />} />
            <Route path="/care-flow" element={<Scenarios />} />
            <Route path="/create-work-flow" element={<CreateWorkFlow />} />
            <Route path="/charts/docs" element={<Docs />} />
            <Route path="/charts/docs/getting-started" element={<GettingStarted />} />
            <Route path="/charts/docs/quick-start-guide" element={<RootElement />} />
            <Route path="/charts/docs/integration" element={<ChartIntegration />} />
            <Route path="/charts/docs/hbx-line-graph" element={<HbxCharts />} />
            <Route
              path="/edit-work-flow/:workFlowID"
              element={<EditWorkFlow />}
            />
            <Route
              path="/edit-journey"
              element={<Navigate to="/form-journey" replace />}
            />
            <Route path="/edit-journey/:journeyId" element={<FormBuilder />} />
            <Route path="/stripe" element={<Stripe />} />
            <Route path="/success" element={<Success />} />
            <Route path="/invalid" element={<Invalid />} />
            <Route path="/account_created" element={<AccountCreated />} />{" "}
            <Route path="/user_settings" element={<UserSettings />}>
              <Route path="/user_settings/" element={<UserInfo />} />
              <Route path="/user_settings/security" element={<Security />} />
              <Route
                path="/user_settings/notifications"
                element={<Notifications />}
              />
              <Route path="/user_settings/activity" element={<Activity />} />
              <Route path="/user_settings/role" element={<Role />} />
            </Route>
            <Route
              path="/organization_settings"
              element={<OrganizationSettings />}
            >
              <Route
                path="/organization_settings/"
                element={<OrganizationInfo />}
              />
              <Route
                path="/organization_settings/members"
                element={<Members />}
              />
              <Route path="/organization_settings/teams" element={<Teams />} />
              <Route
                path="/organization_settings/activity"
                element={<Activity />}
              />
              <Route
                path="/organization_settings/payments"
                element={<Payments />}
              />
              <Route
                path="/organization_settings/switch_organization"
                element={<SwitchOrg />}
              />
            </Route>
          </Route>
        </Route>
        {/* {!login && (
          <> */}
        {/* <Route path="/" element={<LogoutRoute />}> */}
        <Route path="/login" element={<Login />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset_password/:id/:token" element={<ResetPassword />} />
        {/* <Route path="/onboard" element={<SignUp />} /> */}
        {/* </Route> */}
        {/* </>
        )} */}
        <Route path="/payment-intent" element={<PaymentWidgetSetup />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signup/:token" element={<SignUpWithInvite />} />
        <Route path="/404_not_found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404_not_found" replace />} />
      </Routes>
    </Router>
  );
};

export default RoutesPage;
