import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import Code from "./Code";
import Test from "./Test";
import Configuration from "./Configuration";
import { isTokenValid } from "../../../utils/sessionutils";

const Ide = () => {
  const [value, setValue] = useState("code");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    isTokenValid();
  }, []);
  return (
    <Box m="20px">
      {/* <Box m="20px">
        <Typography variant="h5">IDE</Typography>
      </Box> */}
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="code" label="Code" />
          <Tab value="test" label="Test" />
          <Tab value="configuration" label="Configuration" />
        </Tabs>
      </Box>
      {value === "code" && <Code />}
      {value === "test" && <Test />}
      {value === "configuration" && <Configuration />}
    </Box>
  );
};

export default Ide;
