import axios from "axios";
import { postRequest } from "../../../utils/request";

export const upload = async (data, next, document, details) => {
  try {
    var token = null;
    var type = null;
    try {
      var params = new URLSearchParams();
      params.append("grant_type", "client_credentials");
      params.append(
        "scope",
        "athena/service/Athenanet.MDP.* system/Patient.read"
      );

      const response = await axios.post(
        process.env.REACT_APP_TOKEN_URL,
        params,
        {
          auth: {
            username: process.env.REACT_APP_USERNAME,
            password: process.env.REACT_APP_PASSWORD,
          },
        }
      );
      token = response.data.access_token;
      type = response.data.token_type;
      console.log(response);
    } catch (e) {
      // alert(e.response.data.message);
      // console.log(e);
      throw e;
    }
    const header = document
      ? {
          headers: {
            Authorization: `${type} ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      : {
          headers: {
            Authorization: `${type} ${token}`,
            "Content-Type": "multipart/form-data",
          },
        };
    const body = document
      ? {
          attachmentcontents: data,
          attachmenttype: "PDF",
          documentsubclass: "CLINICALDOCUMENT",
          internalnote: { referrer: "HSATPro" },
          providerid: details.providerid,
          departmentid: details.departmentid,
        }
      : {
          displayonschedule: true,
          notetext: data,
        };
    const url = document
      ? `${process.env.REACT_APP_DOCUMENT_UPLOAD}${details.practiceid}/patients/${details.patientid}/documents/clinicaldocument`
      : `${process.env.REACT_APP_DOCUMENT_UPLOAD}${details.practiceid}/appointments/${details.appointmentid}/notes`;
    const response = await postRequest(url, body, header);
    console.log(response);
    next();
  } catch (e) {
    // alert(e.response.data.message);
    console.log("error", e);
  }
};
