import {
  Alert,
  Box,
  CardContent,
  CardHeader,
  CircularProgress,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { postRequest } from "../../../utils/request";
import { isWeakPassword } from "../../../utils/password";
import { useSelector } from "react-redux";
import { isTokenValid } from "../../../utils/sessionutils";
import EnableMFA from "./EnableMFA";
import { CustomCard } from "../../../components/custom/CustomCard";
import CustomButton from "../../../components/custom/CustomButton";
import CustomInput from "../../../components/custom/CustomInput";

export const Security = () => {
  const userData = useSelector((state) => state.user);
  useEffect(() => {
    isTokenValid();
  }, []);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      // justifyContent={"space-between"}
      alignSelf={"center"}
    >
      <Stack spacing={4}>
        <Typography variant="h4">Security</Typography>
        {userData?.user?.login !== "email" && (
          <Alert sx={{ marginY: 2 }} severity="info">
            Your account is currently linked to your {userData?.user?.login}
            account!
          </Alert>
        )}
        <Stack spacing={3}>
          <SocialLogin email={userData?.user?.login !== "email"} />
          {userData?.user?.login === "email" && <ChangePassword />}
          {userData?.user?.login === "email" && <MFA />}
        </Stack>
      </Stack>
    </Box>
  );
};

const SocialLogin = () => {
  // console.log("eeeeeee", email);
  const userData = useSelector((state) => state.user);
  // const [social, setSocial] = useState(user);
  return (
    <>
      {/* {console.log("email", social, email)} */}
      {/* <Paper variant="elevation" sx={{ padding: 2, marginY: 2 }}> */}
      <CustomCard>
        <CardHeader
          title={"Social Login"}
          titleTypographyProps={{ variant: "body1" }}
          action={
            <CustomButton onClick={() => console.log(userData?.user?.login)}>
              {userData?.user?.login !== "email" ? "DISABLE" : "ENABLE"}
            </CustomButton>
          }
        ></CardHeader>
        {/* <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
        >
          <Typography>Social login</Typography>
          <Button onClick={() => console.log(userData?.user?.login)}>
            {userData?.user?.login !== "email" ? "DISABLE" : "ENABLE"}
          </Button>
        </Box> */}
        {userData?.user?.login !== "email" ? <Box></Box> : <Box></Box>}
      </CustomCard>
      {/* </Paper> */}
    </>
  );
};

const ChangePassword = () => {
  const [oldPass, setOldPass] = useState("");
  const [oldPassError, setOldPassError] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [confirmPassError, setConfirmPassError] = useState("");
  const [newPassError, setNewPassError] = useState("");
  const [success, setSuccess] = useState(false);
  const checkPasswordStrength = (e) => {
    const weak = isWeakPassword(e.target.value);
    if (weak) {
      setNewPassError(weak);
    }
  };
  const handleChange = async () => {
    if (newPass !== confirmPass) {
      setConfirmPassError("Passwords do not match");
      return;
    }
    try {
      const data = {
        oldPass: oldPass,
        newPass: newPass,
      };
      await postRequest("user/change-password", data);
      setSuccess(true);
      setConfirmPass("");
      setNewPass("");
      setOldPass("");
    } catch (e) {
      console.log(e);
      if (e.response?.data?.message === "Incorrect Password!") {
        setOldPassError("Incorrect Password!");
      }
    }
  };
  return (
    <>
      {/* <Paper variant="elevation" sx={{ padding: 2, marginY: 2 }}> */}
      <CustomCard>
        <CardHeader
          title="Change Password"
          titleTypographyProps={{ variant: "body1" }}
        />
        {/* <Box padding={2}>
          <Typography textAlign={"left"}>Change Password:</Typography>
        </Box> */}
        <CardContent>
          {/* <Box padding={2}> */}
          {/* <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              m={2}
            > */}
          {/* <Typography>Old Password</Typography> */}
          <CustomInput
            labelText={"Old Password"}
            fullWidth
            id="old-password-input"
            // label="Password"
            value={oldPass}
            onChange={(e) => {
              setOldPass(e.target.value);
              setSuccess(false);
              setOldPassError("");
            }}
            error={!!oldPassError}
            helperText={oldPassError}
            type="password"
            autoComplete="current-password"
          />
          {/* </Box> */}
          {/* <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              m={2}
            >
              <Typography>New Password</Typography> */}
          <CustomInput
            labelText={"New Password"}
            fullWidth
            id="new-password-input"
            // label="New Password"
            value={newPass}
            onChange={(e) => {
              setNewPass(e.target.value);
              setNewPassError("");
              setSuccess(false);
            }}
            onBlur={checkPasswordStrength}
            error={!!newPassError}
            helperText={newPassError}
            type="password"
            autoComplete="current-password"
          />
          {/* </Box>
            <PasswordInstructions password={newPass} />
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              m={2}
            >
              <Typography>Confirm Password</Typography> */}
          <CustomInput
            labelText={"Confirm Password"}
            fullWidth
            id="confirm-password-input"
            value={confirmPass}
            error={!!confirmPassError}
            helperText={confirmPassError}
            onChange={(e) => {
              setConfirmPass(e.target.value);
              setSuccess(false);
              setConfirmPassError("");
            }}
            // label="Confirm Password"
            type="password"
            autoComplete="current-password"
          />
          {/* </Box> */}
          {success && (
            <Alert severity="success">
              Your password has been changed successfully
            </Alert>
          )}
          <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
            <CustomButton
              disabled={
                confirmPassError ||
                oldPassError ||
                newPassError ||
                !oldPass ||
                !newPass ||
                !confirmPass
                  ? true
                  : false
              }
              onClick={handleChange}
            >
              Update
            </CustomButton>
          </Box>
          {/* </Box> */}
        </CardContent>
      </CustomCard>
      {/* </Paper> */}
    </>
  );
};

const MFA = () => {
  const userData = useSelector((state) => state.user);
  const [type, setType] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const disable = async (mfaType) => {
    setLoading(true);
    try {
      const response = await postRequest("mfa/disableotp", {
        email: userData.user.email,
        type: mfaType,
      });
      console.log(response);
      isTokenValid();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  return (
    <>
      {/* <Paper variant="elevation" sx={{ padding: 2, marginY: 2 }}> */}
      <CustomCard>
        <CardHeader
          title={"MFA (MULTI FACTOR AUTHENTICATION)"}
          titleTypographyProps={{ variant: "body1" }}
        />
        {/* <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
        >
          <Typography>MFA (MULTI FACTOR AUTHENTICATION)</Typography>
        </Box> */}
        <CardContent>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            m={2}
          >
            <Typography>PHONE</Typography>

            {!userData?.user?.mfa_sms ? (
              <CustomButton
                onClick={() => {
                  setType("Phone");
                  setOpen((isOpen) => !isOpen);
                }}
              >
                {open && type === "Phone" ? (
                  <CircularProgress size="25px" />
                ) : (
                  "Enable"
                )}
              </CustomButton>
            ) : (
              <CustomButton
                onClick={() => {
                  setType("Phone");
                  disable("SMS");
                  setType(null);
                }}
              >
                {loading && type === "Phone" ? (
                  <CircularProgress size="25px" />
                ) : (
                  "Disable"
                )}
              </CustomButton>
            )}
          </Box>
          {userData?.user?.mfa_sms && (
            <Alert severity="success">SMS authorization is enabled</Alert>
          )}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            m={2}
          >
            <Typography>EMAIL</Typography>
            {!userData?.user?.mfa_email ? (
              <CustomButton
                onClick={() => {
                  setType("Email");
                  setOpen((isOpen) => !isOpen);
                }}
              >
                {open && type === "Email" ? (
                  <CircularProgress size="25px" />
                ) : (
                  "Enable"
                )}
              </CustomButton>
            ) : (
              <CustomButton
                onClick={() => {
                  setType("Email");
                  disable("EMAIL");
                  setType(null);
                }}
              >
                {loading && type === "Email" ? (
                  <CircularProgress size="25px" />
                ) : (
                  "Disable"
                )}
              </CustomButton>
            )}
          </Box>
          {userData?.user?.mfa_email && (
            <Alert severity="success">EMAIL authorization is enabled</Alert>
          )}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            m={2}
          >
            <Typography>APP</Typography>
            {!userData?.user?.mfa_app ? (
              <CustomButton
                onClick={() => {
                  setType("App");
                  setOpen((isOpen) => !isOpen);
                }}
              >
                {open && type === "App" ? (
                  <CircularProgress size="25px" />
                ) : (
                  "Enable"
                )}
              </CustomButton>
            ) : (
              <CustomButton
                onClick={() => {
                  setType("App");
                  disable("APP");
                  setType(null);
                }}
              >
                {loading && type === "App" ? (
                  <CircularProgress size="25px" />
                ) : (
                  "Disable"
                )}
              </CustomButton>
            )}
          </Box>
          {userData?.user?.mfa_app && (
            <Alert severity="success">Google Authenticator is enabled</Alert>
          )}
        </CardContent>
      </CustomCard>
      {/* </Paper> */}
      <Modal open={open}>
        <Box sx={style}>
          <EnableMFA
            type={type}
            closeModal={() => {
              setOpen(false);
              setType(null);
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
