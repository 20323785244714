import { useState, useEffect } from "react";

const InputEmail = ({
  label, // Label for the input field
  value = "", // Controlled value for the input (default: empty string)
  onChange, // Callback to handle input changes
  required = false, // Whether the field is required
  invalidMessage = "Required Field", // Custom invalid message
  formClassName = "", // Additional classes for the form container
  inputProps = {}, // Additional props for the input field
  isValid = true, // Validation status
  onValidationChange, // Function to toggle validation status
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    // Update local state when the external value changes
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    // Trigger validation status change if provided
    if (onValidationChange) {
      onValidationChange(isValid);
    }
  }, [isValid, onValidationChange]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (onChange) onChange({value: newValue});
    if (required && onValidationChange) {
      onValidationChange(!!newValue); // Update validation status based on input value
    }
  };

  return (
    <div className={`email-input-container w-full ${formClassName}`}>
      {label && (
        <label className="form-title pt-3">
          {label}
          {required && <span className="text-red-700"> *</span>}
        </label>
      )}
      <div className="input-wrapper my-2">
        <input
          type="email"
          value={inputValue}
          onChange={handleChange}
          className={`text-field text-input ${!isValid ? "input-error" : ""}`}
          {...inputProps} // Spread additional input properties
          aria-invalid={!isValid} // Accessibility: indicate if input is valid
        />
      </div>
      {!isValid && required && (
        <span className="text-red-700 font-light text-xs">
          {invalidMessage}
        </span>
      )}
    </div>
  );
};

export default InputEmail;
