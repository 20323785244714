import { Box, Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DragDropFiles from "../fileUpload";

const UploadDocuments = ({ closeModal, next }) => {
  return (
    <Box>
      <Box display="flex" justifyContent={"space-between"}>
        <Typography variant="h6">Upload Documents</Typography>
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box m={"20px"}>
        <DragDropFiles next={next} />
      </Box>
      <Box display="flex" justifyContent={"flex-end"}>
        <Button disabled onClick={next}>
          NEXT
        </Button>
      </Box>
    </Box>
  );
};
export default UploadDocuments;
