import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "@mui/material";

export default function SetupForm({
  type,
  // handleNextScreenPage,
  // handleBackScreenPage,
  // setAnswerForWidget
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) return;
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) return;
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setMessage(
        paymentIntent.status === "succeeded"
          ? "Your payment succeeded"
          : "Unexpected error occurred"
      );
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    setIsLoading(true);
    if (type === "setup") {
      const { error } = await stripe.confirmSetup({
        elements,
        // confirmParams: {
        //   return_url: `${process.env.REACT_APP_FRONTEND_URL}/success`,
        // },
        redirect: "if_required",
      });
      if (
        error &&
        (error.type === "card_error" || error.type === "validation_error")
      ) {
        setMessage(error.message);
      } else {
        setMessage("setup successful");
        setDisable(true);
        // handleNextScreenPage();
        // setAnswerForWidget();
      }
    } else {
      const { error } = await stripe.confirmPayment({
        elements,
        // confirmParams: {
        //   return_url: `${process.env.REACT_APP_FRONTEND_URL}/success`,
        // },
        redirect: "if_required",
      });
      if (
        error &&
        (error.type === "card_error" || error.type === "validation_error")
      ) {
        setMessage(error.message);
      } else {
        setMessage("payment successful");
        setDisable(true);
        // handleNextScreenPage();
        // setAnswerForWidget();
      }
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="text-black mb-4">
        {type === "setup" ? "Setup payment" : "Complete your payment here!"}
      </p>
      <PaymentElement />
      <div className="flex justify-between pt-3 pb-36">
        <button
          onClick={() => {
            //handleBackScreenPage()
          }}
          className="flex text-xl rounded shadow p-2 mustard_bg text-white px-3 cursor-pointer mr-24 hover:bg-indigo-400"
        >
          {/* <FiChevronsLeft size={28} /> */}
          Back
        </button>
        <Button
          onClick={handleSubmit}
          sx={{
            backgroundColor: "#6366f1",
            "&:hover": {
              backgroundColor: "#7c7ff0",
            },
          }}
          className="rounded-xl text-white p-2 shadow p-2 mustard_bg text-white px-3 cursor-pointer hover:bg-red-600 "
          disabled={isLoading || !stripe || !elements || disable}
        >
          {isLoading ? "Loading..." : type === "setup" ? "setup" : "Pay now"}
        </Button>
      </div>

      {message && <div>{message}</div>}
    </form>
  );
}
