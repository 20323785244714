import { Box, CardHeader, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomCard } from "../custom/CustomCard.jsx";
import AddIcon from "@mui/icons-material/Add";
import { getRequest } from "../../utils/request.js";
import "./common.scss";
import CustomButton, { CustomTextButton } from "../custom/CustomButton.jsx";
import RenderHomePageChart from "./RenderHomePageChart.jsx";

const HomePageCharts = () => {
  const [showConfig, setShowConfig] = useState(false);
  const [xAxis, setXAxis] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [chartType, setChartType] = useState(null);

  const [spaces, setSpaces] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await getRequest(
        `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customspaces`,
        {},
        false
      );
      let { customSpaces } = response.data;
      setSpaces(customSpaces);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }
  const handleSpaceClick = (id) => {
    let newSpace = spaces.find((item) => item?._id === id);
    setSelectedSpace(newSpace);
  };
  const handleXaxisClick = (key) => {
    const selectedField = selectedSpace?.fields?.find(
      (field) => field.key === key
    );
    setXAxis({ key, name: selectedField?.display_name });
  };
  const handleChartTypeClick = (key) => {
    setChartType(key);
  };
  
  const handleConfirm = () => {
    if (xAxis && selectedSpace) {
      setChartData((prevData) => [...prevData, { selectedSpace, xAxis, chartType }]);
      setShowConfig(false);
      setSelectedSpace(null);
      setXAxis(null);
      setChartType(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box my={2} pb={4} className="home-page-charts">
      <Grid container spacing={2}>
        {!!chartData && chartData?.length ? (
          <>
            {chartData.map((chartObj, index) => (
              <Grid item lg={6} md={6} sm={12} xs={12} key={index}>
                <CustomCard sx={{ height: "400px" }}>
                  <RenderHomePageChart chartObj={chartObj} />
                </CustomCard>
              </Grid>
            ))}
          </>
        ) : (
          <></>
        )}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomCard sx={{ height: "400px" }}>
            {showConfig ? (
              <div className="h-full text-center content-center cursor-pointer">
                <div className="flex flex-col gap-4 justify-center items-center py-4">
                  <div className="flex relative dropdown-select">
                    <div className="form-title my-auto pr-3">
                      Aggregate On:{" "}
                    </div>

                    <select
                      className="new-dropdown purple-text text-input px-2 max-h-10 my-auto"
                      value={selectedSpace?._id || ""}
                      onChange={(e) => handleSpaceClick(e.target.value)}
                    >
                      <option
                        className="placeholder-text text-gray-400 italic opacity-75"
                        value=""
                        disabled
                        hidden
                      >
                        Select...
                      </option>
                      {spaces.length > 0 &&
                        spaces.map((space) => (
                          <option key={space._id} value={space._id}>
                            <div>{space?.common_name}</div>
                          </option>
                        ))}
                    </select>
                  </div>
                  {selectedSpace && (
                    <>
                      <div className="flex relative dropdown-select">
                        <div className="form-title my-auto pr-3">X Axis: </div>

                        <select
                          className="new-dropdown purple-text text-input px-2 max-h-10 my-auto"
                          value={xAxis?.key || ""}
                          onChange={(e) => handleXaxisClick(e.target.value)}
                        >
                          <option
                            className="placeholder-text text-gray-400 italic opacity-75"
                            value=""
                            disabled
                            hidden
                          >
                            Select...
                          </option>
                          {selectedSpace?.fields?.length > 0 &&
                            selectedSpace?.fields?.map((field) => (
                              <option key={field.key} value={field.key}>
                                <div>{field.display_name}</div>
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="flex relative dropdown-select">
                        <div className="form-title my-auto pr-3">
                          Chart Type:{" "}
                        </div>

                        <select
                          className="new-dropdown purple-text text-input px-2 max-h-10 my-auto"
                          value={chartType || ""}
                          onChange={(e) => handleChartTypeClick(e.target.value)}
                        >
                          <option
                            className="placeholder-text text-gray-400 italic opacity-75"
                            value=""
                            disabled
                            hidden
                          >
                            Select...
                          </option>
                          {[
                            { key: "barChart", display_name: "Bar Chart" },
                            { key: "pieChart", display_name: "Pie Chart" },
                            { key: "lineChart", display_name: "Line Chart" },
                          ].map((field) => (
                            <option key={field.key} value={field.key}>
                              <div>{field.display_name}</div>
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-28 justify-center items-center py-4">
                  <CustomTextButton onClick={() => setShowConfig(false)}>
                    Cancel
                  </CustomTextButton>
                  <CustomButton
                    size="small"
                    onClick={handleConfirm}
                    disabled={!(selectedSpace, xAxis)}
                  >
                    Confirm
                  </CustomButton>
                </div>
              </div>
            ) : (
              <div className="h-full text-center content-center cursor-pointer grid_item">
                <AddIcon
                  className="grid_icon"
                  sx={{ fontSize: "6rem", color: "lightgray" }}
                  onClick={() => setShowConfig(!showConfig)}
                />
              </div>
            )}
          </CustomCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePageCharts;
