import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import CareFlowContext from "../../store/context/CareFlowContext";
import CreateWorkFlow from "../workFlow";
import { getAuthToken } from "../../utils/cookie";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const careFlowJson = [
  {
    id: "11",
    data: {
      nodes: [
        {
          id: "start-node",
          type: "customNode",
          position: {
            x: 174.00000000000006,
            y: 149.33333333333331,
          },
          data: {
            label: "Trigger",
            nodeType: "router-node",
            configurations: {
              configData: [
                {
                  ques: "Type",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "HealthBlox Journey Submission",
                    value: "HealthBloxJourneySubmission",
                  },
                  options: [
                    {
                      label: "HealthBlox Journey Submission",
                      value: "HealthBloxJourneySubmission",
                    },
                    {
                      label: "Form Submission",
                      value: "FormSubmission",
                    },
                    {
                      label: "HealthBlox Standard Form Submission",
                      value: "HealthBloxStandardFormSubmission",
                    },
                  ],
                },
                {
                  ques: "Journey",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Patient Intake",
                    value: "PatientIntake",
                  },
                  options: [
                    {
                      label: "Patient Intake",
                      value: "PatientIntake",
                    },
                    {
                      label: "Patient Additional Information",
                      value: "PatientAdditionalInformation",
                    },
                    {
                      label: "Journey Demo 1",
                      value: "JourneyDemo1",
                    },
                  ],
                },
              ],
            },
          },
          width: 189,
          height: 82,
          selected: false,
          dragging: false,
          positionAbsolute: {
            x: 174.00000000000006,
            y: 149.33333333333331,
          },
        },
        {
          id: "3979379",
          type: "customNode",
          data: {
            label: "HBx Actions",
            nodeType: "checklist-node",
            configurations: {
              configData: [
                {
                  ques: "Type",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Map Journey",
                    value: "mapJourney",
                  },
                  options: [
                    {
                      label: "Map Journey",
                      value: "mapJourney",
                    },
                  ],
                },
                {
                  ques: "Meta Fields",
                  required: true,
                  type: "text",
                  answer: "$trigger.$meta",
                },
                {
                  ques: "Journey to map",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Patient Information Collection",
                    value: "Patient Information Collection",
                  },
                  options: [
                    {
                      label: "Patient Information Collection",
                      value: "Patient Information Collection",
                    },
                  ],
                },
              ],
            },
          },
          position: {
            x: 547,
            y: 150.66666666666669,
          },
          width: 189,
          height: 82,
          selected: false,
          dragging: false,
          positionAbsolute: {
            x: 547,
            y: 150.66666666666669,
          },
        },
        {
          id: "4014825",
          type: "customNode",
          data: {
            label: "Email",
            nodeType: "gmail-node",
            configurations: {
              configData: [
                {
                  ques: "Sender",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "info@healthblox.care",
                    value: "info@healthblox.care",
                  },
                  options: [
                    {
                      label: "info@healthblox.care",
                      value: "info@healthblox.care",
                    },
                  ],
                },
                {
                  ques: "To",
                  required: true,
                  type: "text",
                  answer: "$trigger.$meta.patient.email",
                },
                {
                  ques: "Content Type",
                  required: true,
                  type: "radio",
                  answer: {
                    label: "Template",
                    value: "Template",
                  },
                  options: [
                    {
                      label: "Raw",
                      value: "Raw",
                    },
                    {
                      label: "Template",
                      value: "Template",
                    },
                  ],
                },
                {
                  ques: "Template",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Patient Onboarding Email",
                    value: "Patient Onboarding Email",
                  },
                  options: [
                    {
                      label: "Patient Onboarding Email",
                      value: "Patient Onboarding Email",
                    },
                  ],
                },
              ],
            },
          },
          position: {
            x: 1036,
            y: 150.66666666666669,
          },
          width: 189,
          height: 82,
          selected: false,
          dragging: false,
          positionAbsolute: {
            x: 1036,
            y: 150.66666666666669,
          },
        },
        {
          id: "4136990",
          type: "customNode",
          data: {
            label: "End",
            nodeType: "end-node",
          },
          position: {
            x: 1400,
            y: 165.33333333333334,
          },
          width: 107,
          height: 48,
          selected: true,
          dragging: false,
          positionAbsolute: {
            x: 1529,
            y: 165.33333333333334,
          },
        },
      ],
      edges: [
        {
          type: "buttonedge",
          id: "3979379",
          source: "start-node",
          target: "3979379",
          markerEnd: {
            type: "arrowclosed",
            width: 16,
            height: 16,
          },
          style: {
            strokeWidth: 2,
          },
        },
        {
          type: "buttonedge",
          id: "4014825",
          source: "3979379",
          target: "4014825",
          markerEnd: {
            type: "arrowclosed",
            width: 16,
            height: 16,
          },
          style: {
            strokeWidth: 2,
          },
        },
        {
          id: "4136990",
          source: "4014825",
          target: "4136990",
          markerEnd: {
            type: "arrowclosed",
            width: 16,
            height: 16,
          },
          style: {
            strokeWidth: 2,
          },
        },
      ],
      conditionData: [],
      careFlowName: "Patient Appointment Experience",
    },
  },
  {
    id: "16",
    data: {
      nodes: [
        {
          id: "start-node",
          type: "customNode",
          position: {
            x: 50,
            y: 348,
          },
          data: {
            label: "Trigger",
            nodeType: "router-node",
            configurations: {
              configData: [
                {
                  ques: "Type",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Form Submission",
                    value: "FormSubmission",
                  },
                  options: [
                    {
                      label: "HealthBlox Journey Submission",
                      value: "HealthBloxJourneySubmission",
                    },
                    {
                      label: "Form Submission",
                      value: "FormSubmission",
                    },
                    {
                      label: "HealthBlox Standard Form Submission",
                      value: "HealthBloxStandardFormSubmission",
                    },
                  ],
                },
                {
                  ques: "Form",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "HPI",
                    value: "hpi",
                  },
                  options: [
                    {
                      label: "HPI",
                      value: "hpi",
                    },
                    {
                      label: "HIPAA Consent",
                      value: "HIPAAConsent",
                    },
                    {
                      label: "Form Demo 1",
                      value: "FormDemo1",
                    },
                  ],
                },
                {
                  ques: "Client Integration",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Athena",
                    value: "Athena",
                  },
                  options: [
                    {
                      label: "Athena",
                      value: "Athena",
                    },
                    {
                      label: "Client Demo 1",
                      value: "clientDemo1",
                    },
                    {
                      label: "Client Demo 2",
                      value: "clientDemo2",
                    },
                  ],
                },
              ],
            },
          },
          width: 189,
          height: 82,
          selected: false,
          dragging: false,
          positionAbsolute: {
            x: 50,
            y: 348,
          },
        },
        {
          id: "7523357",
          type: "customNode",
          data: {
            label: "EHR Api",
            nodeType: "api-node",
            configurations: {
              configData: [
                {
                  ques: "Client Integration",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Athena",
                    value: "Athena",
                  },
                  options: [
                    {
                      label: "Athena",
                      value: "Athena",
                    },
                    {
                      label: "Client Demo 1",
                      value: "clientDemo1",
                    },
                    {
                      label: "Client Demo 2",
                      value: "clientDemo2",
                    },
                  ],
                },
                {
                  ques: "API",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Submit HPI",
                    value: "Submit HPI",
                  },
                  options: [
                    {
                      label: "Submit HPI",
                      value: "Submit HPI",
                    },
                    {
                      label: "Submit HPI",
                      value: "Submit HPI",
                    },
                    {
                      label: "Submit HPI",
                      value: "Submit HPI",
                    },
                  ],
                },
                {
                  ques: "Body",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Standard Form",
                    value: "Standard Form",
                  },
                  options: [
                    {
                      label: "Standard Form",
                      value: "Standard Form",
                    },
                    {
                      label: "Standard Form",
                      value: "Standard Form",
                    },
                    {
                      label: "Standard Form",
                      value: "Standard Form",
                    },
                  ],
                },
              ],
            },
          },
          position: {
            x: 539,
            y: 348,
          },
          width: 189,
          height: 82,
          selected: false,
          dragging: false,
          positionAbsolute: {
            x: 539,
            y: 348,
          },
        },
        {
          id: "7526947",
          type: "customNode",
          data: {
            label: "End",
            nodeType: "end-node",
          },
          position: {
            x: 1045.453896200983,
            y: 362.76868140083184,
          },
          width: 107,
          height: 48,
          selected: true,
          dragging: false,
          positionAbsolute: {
            x: 1045.453896200983,
            y: 362.76868140083184,
          },
        },
      ],
      edges: [
        {
          type: "buttonedge",
          id: "7523357",
          source: "start-node",
          target: "7523357",
          markerEnd: {
            type: "arrowclosed",
            width: 16,
            height: 16,
          },
          style: {
            strokeWidth: 2,
          },
        },
        {
          id: "7526947",
          source: "7523357",
          target: "7526947",
          markerEnd: {
            type: "arrowclosed",
            width: 16,
            height: 16,
          },
          style: {
            strokeWidth: 2,
          },
        },
      ],
      conditionData: [],
      careFlowName: "test 2",
    },
  },
  {
    id: "18",
    data: {
      nodes: [
        {
          id: "start-node",
          type: "customNode",
          position: {
            x: 50,
            y: 348,
          },
          data: {
            label: "Trigger",
            nodeType: "router-node",
            configurations: {
              configData: [
                {
                  ques: "Type",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Form Submission",
                    value: "FormSubmission",
                  },
                  options: [
                    {
                      label: "HealthBlox Journey Submission",
                      value: "HealthBloxJourneySubmission",
                    },
                    {
                      label: "Form Submission",
                      value: "FormSubmission",
                    },
                    {
                      label: "HealthBlox Standard Form Submission",
                      value: "HealthBloxStandardFormSubmission",
                    },
                  ],
                },
                {
                  ques: "Form",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "HIPAA Consent",
                    value: "HIPAAConsent",
                  },
                  options: [
                    {
                      label: "HPI",
                      value: "hpi",
                    },
                    {
                      label: "HIPAA Consent",
                      value: "HIPAAConsent",
                    },
                    {
                      label: "Form Demo 1",
                      value: "FormDemo1",
                    },
                  ],
                },
                {
                  ques: "Client Integration",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Athena",
                    value: "Athena",
                  },
                  options: [
                    {
                      label: "Athena",
                      value: "Athena",
                    },
                    {
                      label: "Client Demo 1",
                      value: "clientDemo1",
                    },
                    {
                      label: "Client Demo 2",
                      value: "clientDemo2",
                    },
                  ],
                },
              ],
            },
          },
          width: 189,
          height: 82,
          selected: false,
          dragging: false,
          positionAbsolute: {
            x: 50,
            y: 348,
          },
        },
        {
          id: "7967414",
          type: "customNode",
          data: {
            label: "Generate PDF",
            nodeType: "calculator-node",
            configurations: {
              configData: [
                {
                  ques: "Template",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "None",
                    value: "None",
                  },
                  options: [
                    {
                      label: "None",
                      value: "None",
                    },
                  ],
                },
                {
                  ques: "Raw Render",
                  required: true,
                  key: "Raw Render",
                  type: "checkbox",
                  answer: {
                    value: ["Raw Render"],
                  },
                },
              ],
            },
          },
          position: {
            x: 539,
            y: 348,
          },
          width: 200,
          height: 82,
          selected: false,
          dragging: false,
          positionAbsolute: {
            x: 539,
            y: 348,
          },
        },
        {
          id: "7978566",
          type: "customNode",
          data: {
            label: "EHR Api",
            nodeType: "api-node",
            configurations: {
              configData: [
                {
                  ques: "Client Integration",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Athena",
                    value: "Athena",
                  },
                  options: [
                    {
                      label: "Athena",
                      value: "Athena",
                    },
                    {
                      label: "Client Demo 1",
                      value: "clientDemo1",
                    },
                    {
                      label: "Client Demo 2",
                      value: "clientDemo2",
                    },
                  ],
                },
                {
                  ques: "API",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Upload Document Reference",
                    value: "Upload Document Reference",
                  },
                  options: [
                    {
                      label: "Upload Document Reference",
                      value: "Upload Document Reference",
                    },
                    {
                      label: "Submit HPI",
                      value: "Submit HPI",
                    },
                    {
                      label: "Submit HPI",
                      value: "Submit HPI",
                    },
                  ],
                },
                {
                  ques: "Body",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Standard Form",
                    value: "Standard Form",
                  },
                  options: [
                    {
                      label: "Standard Form",
                      value: "Standard Form",
                    },
                    {
                      label: "Standard Form",
                      value: "Standard Form",
                    },
                    {
                      label: "Standard Form",
                      value: "Standard Form",
                    },
                  ],
                },
              ],
            },
          },
          position: {
            x: 1039,
            y: 348,
          },
          width: 189,
          height: 82,
          selected: false,
          dragging: false,
          positionAbsolute: {
            x: 1039,
            y: 348,
          },
        },
        {
          id: "7990652",
          type: "customNode",
          data: {
            label: "End",
            nodeType: "end-node",
          },
          position: {
            x: 1539.2387227383765,
            y: 360.84425455814454,
          },
          width: 107,
          height: 48,
          selected: true,
          dragging: false,
          positionAbsolute: {
            x: 1539.2387227383765,
            y: 360.84425455814454,
          },
        },
      ],
      edges: [
        {
          type: "buttonedge",
          id: "7967414",
          source: "start-node",
          target: "7967414",
          markerEnd: {
            type: "arrowclosed",
            width: 16,
            height: 16,
          },
          style: {
            strokeWidth: 2,
          },
        },
        {
          type: "buttonedge",
          id: "7978566",
          source: "7967414",
          target: "7978566",
          markerEnd: {
            type: "arrowclosed",
            width: 16,
            height: 16,
          },
          style: {
            strokeWidth: 2,
          },
        },
        {
          id: "7990652",
          source: "7978566",
          target: "7990652",
          markerEnd: {
            type: "arrowclosed",
            width: 16,
            height: 16,
          },
          style: {
            strokeWidth: 2,
          },
        },
      ],
      conditionData: [],
      careFlowName: "test 3",
    },
  },
  {
    id: "10",
    data: {
      nodes: [
        {
          id: "start-node",
          type: "customNode",
          position: {
            x: 50,
            y: 348,
          },
          data: {
            label: "EHR Listener",
            nodeType: "message-node",
            configurations: {
              configData: [
                {
                  ques: "Client Integration",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Athena",
                    value: "Athena",
                    badge: "Recommendend",
                  },
                  options: [
                    {
                      label: "Athena",
                      value: "Athena",
                      badge: "Recommendend",
                    },
                  ],
                },
                {
                  ques: "Listener",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Order Updates",
                    value: "Order Updates",
                  },
                  options: [
                    {
                      label: "Order Updates",
                      value: "Order Updates",
                    },
                  ],
                },
              ],
            },
          },
          width: 194,
          height: 82,
          selected: false,
          dragging: false,
          positionAbsolute: {
            x: 50,
            y: 348,
          },
        },
        {
          id: "badge-node",
          type: "customNode",
          data: {
            label: "EHR Api",
            nodeType: "api-node",
            configurations: {
              configData: [
                {
                  ques: "Client Integration",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Athena",
                    value: "Athena",
                  },
                  options: [
                    {
                      label: "Athena",
                      value: "Athena",
                    },
                  ],
                },
                {
                  ques: "API",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Get Patient",
                    value: "Get Patient",
                  },
                  options: [
                    {
                      label: "Get Patient",
                      value: "Get Patient",
                    },
                  ],
                },
                {
                  ques: "Params",
                  required: true,
                  type: "params",
                  answer: [
                    {
                      key: "patientId",
                      value: "$listener.patientId",
                      required: true,
                    },
                    {
                      key: "dob",
                      value: "",
                    },
                    {
                      key: "firstName",
                      value: "",
                    },
                    {
                      key: "lastName",
                      value: "",
                    },
                  ],
                },
              ],
            },
          },
          position: {
            x: 422.8792715286803,
            y: 348.07917697616807,
          },
          width: 189,
          height: 82,
          positionAbsolute: {
            x: 422.8792715286803,
            y: 348.07917697616807,
          },
          selected: false,
          dragging: false,
        },
        {
          id: "2608707",
          type: "customNode",
          data: {
            label: "EHR Api",
            nodeType: "api-node",
            configurations: {
              configData: [
                {
                  ques: "Client Integration",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Athena",
                    value: "Athena",
                  },
                  options: [
                    {
                      label: "Athena",
                      value: "Athena",
                    },
                  ],
                },
                {
                  ques: "API",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "Get Appointment",
                    value: "Get Appointment",
                  },
                  options: [
                    {
                      label: "Get Appointment",
                      value: "Get Appointment",
                    },
                  ],
                },
                {
                  ques: "Params",
                  required: true,
                  type: "params",
                  answer: [
                    {
                      key: "appointmentId",
                      value: "$listener.appointmentId",
                      required: true,
                    },
                  ],
                },
              ],
            },
          },
          position: {
            x: 801.2673651330538,
            y: 348,
          },
          width: 189,
          height: 82,
          selected: false,
          dragging: false,
          positionAbsolute: {
            x: 801.2673651330538,
            y: 348,
          },
        },
        {
          id: "2630320",
          type: "customNode",
          data: {
            label: "External API",
            nodeType: "scheduler-node",
            configurations: {
              configData: [
                {
                  ques: "External Integration",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "IDTF",
                    value: "IDTF",
                  },
                  options: [
                    {
                      label: "IDTF",
                      value: "IDTF",
                    },
                  ],
                },
                {
                  ques: "Authorisation: Enabled",
                  type: "type",
                },
                {
                  ques: "Endpoint",
                  required: true,
                  type: "dropdown",
                  answer: {
                    label: "/eprescription",
                    value: "/eprescription",
                  },
                  options: [
                    {
                      label: "/eprescription",
                      value: "/eprescription",
                    },
                  ],
                },
                {
                  ques: "Body",
                  required: true,
                  type: "params",
                  answer: [
                    {
                      key: "orderInfo",
                      value: "$trigger",
                    },
                    {
                      key: "patientInfo",
                      value: "$ehrApi1",
                    },
                    {
                      key: "appointmentInfo",
                      value: "$ehrApi2",
                    },
                  ],
                },
              ],
            },
          },
          position: {
            x: 1241.9897328691068,
            y: 348.6896804609135,
          },
          width: 189,
          height: 82,
          positionAbsolute: {
            x: 1241.9897328691068,
            y: 348.6896804609135,
          },
          selected: false,
          dragging: false,
        },
        {
          id: "2748456",
          type: "customNode",
          data: {
            label: "End",
            nodeType: "end-node",
          },
          position: {
            x: 1648.9177580203966,
            y: 364.16518612632916,
          },
          width: 107,
          height: 48,
          selected: false,
          dragging: false,
          positionAbsolute: {
            x: 1648.9177580203966,
            y: 364.16518612632916,
          },
        },
      ],
      edges: [
        {
          type: "buttonedge",
          id: "2575133",
          source: "start-node",
          target: "badge-node",
          markerEnd: {
            type: "arrowclosed",
            width: 16,
            height: 16,
          },
          style: {
            strokeWidth: 2,
          },
          selected: false,
        },
        {
          type: "buttonedge",
          id: "2608707",
          source: "badge-node",
          target: "2608707",
          markerEnd: {
            type: "arrowclosed",
            width: 16,
            height: 16,
          },
          style: {
            strokeWidth: 2,
          },
        },
        {
          type: "buttonedge",
          id: "2630320",
          source: "2608707",
          target: "2630320",
          markerEnd: {
            type: "arrowclosed",
            width: 16,
            height: 16,
          },
          style: {
            strokeWidth: 2,
          },
          selected: false,
        },
        {
          type: "buttonedge",
          id: "2748456",
          source: "2630320",
          target: "2748456",
          markerEnd: {
            type: "arrowclosed",
            width: 16,
            height: 16,
          },
          style: {
            strokeWidth: 2,
          },
        },
      ],
      conditionData: [
        {
          id: "2575133",
          edge: {
            type: "buttonedge",
            id: "2575133",
            source: "start-node",
            target: "badge-node",
            markerEnd: {
              type: "arrowclosed",
              width: 16,
              height: 16,
            },
            style: {
              strokeWidth: 2,
            },
            transition: [
              {
                variableKey: "$listener.HBx.class",
                operation: {
                  label: "Equals to (=)",
                  value: "==",
                },
                operand: "DME",
              },
            ],
          },
        },
      ],
      careFlowName: "test 4",
    },
  },
];

const EditWorkFlow = () => {
  const { workFlowID } = useParams();
  const [nodes, setNodes] = useState(null);
  const [edges, setEdges] = useState(null);
  const [loader, setLoader] = useState(true);

  const { conditionData, setConditionData } = useContext(CareFlowContext);

  async function fetchCareFlowData(workFlowID) {
    if (workFlowID < 30 && workFlowID > 0) {
      let filteredData = careFlowJson.filter(
        (item) => item.id === workFlowID
      )[0];
      setNodes(filteredData.data.nodes);
      setEdges(filteredData.data.edges);
      setConditionData(filteredData.data.conditionData);
      setLoader(false);
    } else {
      const API_URL = process.env.REACT_APP_FOUNTAIN_HEAD_URL;
      const getFlowApiUrl = `${API_URL}/workflows/raw/${workFlowID}`;
      try {
        const headers = getAuthToken();
        const res = await axios.get(getFlowApiUrl, headers);
        const { data } = res?.data?.workflow;
        setNodes(data?.nodes);
        setEdges(data?.edges);
        setConditionData(data?.conditionData);
        setLoader(false);
      } catch (error) {
        console.log(error);
        setLoader(false);
        // setOpenModal(true);
      }
    }
  }
  useEffect(() => {
    if (workFlowID) {
      fetchCareFlowData(workFlowID);
    }
  }, []);

  if (!workFlowID) return <></>;
  return (
    <>
      {loader ? (
        <div className="flex justify-center py-24">
          <CircularProgress className="m-auto purple-text" />
        </div>
      ) : (
        <CreateWorkFlow fetchedNodes={nodes} fetchedEdges={edges} />
      )}
    </>
  );
};
export default EditWorkFlow;
