import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Business,
  Diversity3,
  Groups,
  History,
  Info,
  Notifications,
  Receipt,
  Security,
} from "@mui/icons-material";

const SettingsLayout = ({ children, title }) => {
  return (
    <>
      {/* <PageHeading heading={"Settings"} /> */}
      <Stack direction={"row"} mt={2} p={3}>
        <Stack>
          <Stack>
            <SettingsList />
          </Stack>
        </Stack>
        <Stack
          // alignItems={"center"}
          width={"100%"}
          sx={{ overflowY: "auto", scrollbarWidth: "none" }}
          px={3}
          pt={1.5}
        >
          <Box width={"100%"}>
            <Typography variant="h4">{title}</Typography>
            {children}
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
export default SettingsLayout;

export const SettingsList = () => {
  const navigate = useNavigate();
  return (
    <List>
      <ListSubheader>User</ListSubheader>
      <SettingsListItem
        text={"User details"}
        nav={() => {
          navigate("/user_settings/");
        }}
        icon={<Info />}
      />
      <SettingsListItem
        text={"Security"}
        nav={() => {
          navigate("/user_settings/security");
        }}
        icon={<Security />}
      />
      <SettingsListItem
        text={"Notifications"}
        nav={() => {
          navigate("/user_settings/notifications");
        }}
        icon={<Notifications />}
      />
      <SettingsListItem
        text={"Activity"}
        nav={() => {
          navigate("/user_settings/activity");
        }}
        icon={<History />}
      />
      <SettingsListItem
        text={"Role/Permission"}
        nav={() => {
          navigate("/user_settings/role");
        }}
        icon={<Business />}
      />
      <ListSubheader>Organization</ListSubheader>
      <SettingsListItem
        text={"Organization Details"}
        nav={() => {
          navigate("/organization_settings/");
        }}
        icon={<Info />}
      />
      <SettingsListItem
        text={"Members"}
        nav={() => {
          navigate("/organization_settings/members");
        }}
        icon={<Groups />}
      />
      <SettingsListItem
        text={"Teams"}
        nav={() => {
          navigate("/organization_settings/teams");
        }}
        icon={<Diversity3 />}
      />
      <SettingsListItem
        text={"Activity"}
        nav={() => {
          navigate("/organization_settings/activity");
        }}
        icon={<History />}
      />
      <SettingsListItem
        text={"Payments"}
        nav={() => {
          navigate("/organization_settings/payments");
        }}
        icon={<Receipt />}
      />
      <SettingsListItem
        text={"Switch Organization"}
        nav={() => {
          navigate("/organization_settings/switch_organization/");
        }}
        icon={<Business />}
      />
    </List>
  );
};
export const SettingsListItem = ({ text, icon, nav }) => {
  return (
    <ListItem
      key={text}
      disablePadding
      sx={{ display: "block", width: 260 }}
      onClick={nav}
    >
      <ListItemButton
        sx={{
          height: 40,
          justifyContent: "initial",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 3,
            justifyContent: "center",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{ variant: "overline" }}
          sx={{ opacity: 1 }}
        />
      </ListItemButton>
    </ListItem>
  );
};
