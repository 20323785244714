import { AlertProvider } from "./AlertContext";
import FormContextProvider from "./FormContextProvider";
import FormRendererContextProvider from "./FormRendererContextProvider";
import CareFlowContextProvider from "./CareFlowContextProvider";

const CombinedProviders = ({ children }) => {
  return (
    <AlertProvider>
      <FormContextProvider>
        <FormRendererContextProvider>
          <CareFlowContextProvider>
            {children}
          </CareFlowContextProvider>
        </FormRendererContextProvider>
      </FormContextProvider>
    </AlertProvider>
  );
};

export default CombinedProviders;
