import React, {
  useCallback,
  useRef,
  useState,
  useContext,
  useEffect,
} from "react";
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  MarkerType,
  ReactFlowProvider,
  Panel,
} from "reactflow";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import EdgePopUp from "./edgePopup";

import "./workFlow.scss";
import "reactflow/dist/style.css";
import CustomEdge from "./custom-edges/index.jsx";
import CustomNode from "./custom-nodes";
import SaveOutlinedIcon from "@mui/icons-material/Save";
import SettingsOutlinedIcon from "@mui/icons-material/Settings";
import DescriptionOutlinedIcon from "@mui/icons-material/Description";
import AlignHorizontalCenterOutlinedIcon from "@mui/icons-material/AlignHorizontalCenter";
import ShareOutlinedIcon from "@mui/icons-material/Share";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHoriz";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Tooltip from "@mui/material/Tooltip";
import CareFlowContext from "../../store/context/CareFlowContext";
import { getAuthToken } from "../../utils/cookie";
import axios from "axios";
import TextField from "../../components/common/InputText";
import CustomButton from "../../components/custom/CustomButton";

import Dagre from "@dagrejs/dagre";

import { CiCalendar } from "react-icons/ci";
import { MdOutlineLocalShipping } from "react-icons/md";
import { VscDebugStart } from "react-icons/vsc";
import { IoCloudUploadOutline } from "react-icons/io5";
import { CiCalculator2 } from "react-icons/ci";
import { MdFormatListBulleted } from "react-icons/md";
import { MdChecklist } from "react-icons/md";
import { IoStopSharp } from "react-icons/io5";
import { TbRouteAltLeft } from "react-icons/tb";
import { CgMail } from "react-icons/cg";
import { RiMessage2Line } from "react-icons/ri";
import { GrUpdate } from "react-icons/gr";
import { useSelector } from "react-redux";

const getId = () => `${String(+new Date()).slice(6)}`;

const windowHeight = window.screen.height;
const windowWidth = window.screen.width;

const initialNodes = [
  {
    id: "start-node",
    type: "customNode",
    position: { x: 50, y: windowHeight / 2 - 200 },
    data: {
      nodeType: "empty-node",
    },
  },
];
const popUpStyle = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
};

const nodeTypes = { customNode: CustomNode };

const edgeTypes = {
  buttonedge: CustomEdge,
};

const defaultEdgeOptions = {
  type: "buttonedge",
};

function CreateWorkFlow({ fetchedNodes, fetchedEdges }) {
  const [nodes, setNodes, onNodesChange] = useNodesState(
    fetchedNodes || initialNodes
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(fetchedEdges || []);
  const [careFlowName, setCareFlowName] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [source, setsource] = useState(null);
  const [target, settarget] = useState(null);
  const [edgeID, setEdgeID] = useState(null);
  const [edgeN, setEdgeN] = useState(null);

  const [activeNodeId, setActiveNodeId] = useState("1");
  const [showPopup, setShowPopup] = useState(false);
  const [openEdgeDrawer, setOpenEdgeDrawer] = useState(false);

  const [style, setStyle] = useState({
    width: "300px",
    height: "400px",
    top: (windowHeight / 2 - 180) / 2,
    left: 140,
  });
  const [edgePopupStyle, setEdgePopupStyle] = useState({});

  const reactFlowWrapper = useRef(null);
  const navigate = useNavigate();
  const {
    conditionData,
    setConditionData,
    history,
    setHistory,
    historyStep,
    setHistoryStep,
    appsList, setAppsList
  } = useContext(CareFlowContext);

  const userData = useSelector((state) => state.user);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const handleNodeClick = (e, node) => {
    setActiveNodeId(node.id);
    if (node && node?.clickType === "add-node") {
      addNewNodeHandler(node);
    } else if (node && node?.clickType === "delete-node") {
      deleteNode(node);
    }
  };

  async function fetchAllCustomSpaces() {
    const API_URL = process.env.REACT_APP_FOUNTAIN_HEAD_URL;
    const getSpacesApiUrl = `${API_URL}/customspaces`;
    try {
      const headers = getAuthToken();
      const res = await axios.get(getSpacesApiUrl, headers);
      const { customSpaces } = res?.data;
      let customSpacesListForDropdown = [];
      customSpaces.forEach((customSpace) => {
        customSpacesListForDropdown.push({
          label: customSpace?.common_name || "Custom Space",
          value: customSpace?._id || "id",
        });
      });
      return customSpacesListForDropdown;
    } catch (error) {
      console.log(error);
      return [];
      // setOpenModal(true);
    }
  }
  async function setPopUpApps() {
    const customSpacesOptions = await fetchAllCustomSpaces();
    setAppsList([
      {
        label: "Trigger",
        Icon: <VscDebugStart />,
        nodeType: "normal-node",
        configurations: {
          configData: [
            {
              ques: "Type",
              quesKey: "type",
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "HealthBlox Journey",
                  value: "type_journey",
                },
                {
                  label: "User Forms",
                  value: "type_form",
                },
                {
                  label: "HealthBlox Standard Forms",
                  value: "type_standard_form",
                },
                {
                  label: "Custom Spaces",
                  value: "custom_spaces",
                },
              ],
            },
            {
              ques: "Action",
              quesKey: "journey_action",
              dependentOn: [
                {
                  dependentOnQuesKey: "type",
                  dependentValue: ["type_journey"],
                },
              ],
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Create Journey",
                  value: "create_journey",
                },
                {
                  label: "Publish Journey",
                  value: "publish_journey",
                },
                {
                  label: "Delete Journey",
                  value: "delete_journey",
                },
                {
                  label: "Journey Submission",
                  value: "journey_submission",
                },
              ],
            },

            {
              ques: "Journey",
              quesKey: "journey",
              dependentOn: [
                {
                  dependentOnQuesKey: "journey_action",
                  dependentValue: [
                    "journey_submission",
                    "publish_journey",
                    "delete_journey",
                  ],
                },
                {
                  dependentOnQuesKey: "type",
                  dependentValue: ["type_journey"],
                },
              ],
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Patient Intake",
                  value: "PatientIntake",
                },
                {
                  label: "Patient Additional Information",
                  value: "PatientAdditionalInformation",
                },
                {
                  label: "Patient Information Collection",
                  value: "Patient Information Collection",
                },
                {
                  label: "Patient Intake - Commercial Partner",
                  value: "Patient Intake - Commercial Partner",
                },
                {
                  label: "Patient Information - Partner",
                  value: "Patient Information - Partner",
                },
                {
                  label: "Patient Intake - Simple",
                  value: "Patient Intake - Simple",
                },
              ],
            },

            {
              ques: "Action",
              quesKey: "form_action",
              dependentOn: [
                {
                  dependentOnQuesKey: "type",
                  dependentValue: ["type_form"],
                },
              ],
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Create Form",
                  value: "create_form",
                },
                {
                  label: "Publish Form",
                  value: "publish_form",
                },
                {
                  label: "Delete Form",
                  value: "delete_form",
                },
                {
                  label: "Form Submission",
                  value: "form_submission",
                },
              ],
            },
            {
              ques: "Form",
              quesKey: "form",
              dependentOn: [
                {
                  dependentOnQuesKey: "form_action",
                  dependentValue: [
                    "publish_form",
                    "delete_form",
                    "form_submission",
                  ],
                },
                {
                  dependentOnQuesKey: "type",
                  dependentValue: ["type_form"],
                },
              ],
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Patient Insurance",
                  value: "Patient Insurance",
                },
                {
                  label: "F1",
                  value: "F1",
                },
                {
                  label: "Test",
                  value: "test",
                },
              ],
            },

            {
              ques: "Client Integration",
              quesKey: "client_integration",
              dependentOn: [
                {
                  dependentOnQuesKey: "form_action",
                  dependentValue: ["form_submission"],
                },
                {
                  dependentOnQuesKey: "type",
                  dependentValue: ["type_form"],
                },
              ],
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Athena",
                  value: "Athena",
                },
                {
                  label: "Client Demo 1",
                  value: "clientDemo1",
                },
                {
                  label: "Client Demo 2",
                  value: "clientDemo2",
                },
              ],
            },

            {
              ques: "Action",
              quesKey: "standard_form_action",
              dependentOn: [
                {
                  dependentOnQuesKey: "type",
                  dependentValue: ["type_standard_form"],
                },
              ],
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Standard Form Submission",
                  value: "standard_form_submission",
                },
              ],
            },
            {
              ques: "Standard Form",
              quesKey: "standard_form",
              dependentOn: [
                {
                  dependentOnQuesKey: "standard_form_action",
                  dependentValue: ["standard_form_submission"],
                },
                {
                  dependentOnQuesKey: "type",
                  dependentValue: ["type_standard_form"],
                },
              ],
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "ESS",
                  value: "ESS",
                },
                {
                  label: "Demographics",
                  value: "Demographics",
                },
                {
                  label: "Consent Form",
                  value: "Consent Form",
                },
                {
                  label: "HIPAA Notice of Privacy Practices",
                  value: "HIPAA Notice of Privacy Practices",
                },
                {
                  label: "Patient Information",
                  value: "Patient Information",
                },
                {
                  label: "Additional Patient Info",
                  value: "Additional Patient Info",
                },
                {
                  label: "Care Team",
                  value: "Care Team",
                },
                {
                  label: "Social History",
                  value: "Social History",
                },
                {
                  label: "Sleep HPI Questionnaire",
                  value: "Sleep HPI Questionnaire",
                },
                {
                  label: "Health History",
                  value: "Health History",
                },
              ],
            },
            {
              ques: "Client Integration",
              quesKey: "client_integration_standard",
              dependentOn: [
                {
                  dependentOnQuesKey: "type",
                  dependentValue: ["type_standard_form"],
                },
                {
                  dependentOnQuesKey: "standard_form_action",
                  dependentValue: ["standard_form_submission"],
                },
              ],
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Athena",
                  value: "Athena",
                },
                {
                  label: "Client 1",
                  value: "clientDemo1",
                },
                {
                  label: "Client 2",
                  value: "clientDemo2",
                },
              ],
            },
            {
              ques: "Custom Spaces",
              quesKey: "custom_spaces_list",
              dependentOn: [
                {
                  dependentOnQuesKey: "type",
                  dependentValue: ["custom_spaces"],
                },
              ],
              required: true,
              type: "dropdown",
              options: customSpacesOptions || [],
            },
            {
              ques: "Action",
              quesKey: "custom_spaces_action",
              dependentOn: [
                {
                  dependentOnQuesKey: "type",
                  dependentValue: ["custom_spaces"],
                },
              ],
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Add Entry",
                  value: "add_entry",
                },
                {
                  label: "Delete Entry",
                  value: "delete_entry",
                },
                {
                  label: "Update Entry",
                  value: "update_entry",
                },
              ],
            },
          ],
        },
      },
      {
        label: "EHR Api",
        Icon: <IoCloudUploadOutline />,
        nodeType: "normal-node",
        configurations: {
          configData: [
            {
              ques: "Client Integration",
              quesKey: "client_integration",
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Athena",
                  value: "Athena",
                },
                {
                  label: "Client Demo 1",
                  value: "clientDemo1",
                },
                {
                  label: "Client Demo 2",
                  value: "clientDemo2",
                },
              ],
            },
            {
              ques: "API",
              quesKey: "ehr_api",
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Get Patient",
                  value: "get_patient",
                },
                {
                  label: "Update Patient Record",
                  value: "update_patient",
                },
                {
                  label: "Get Appointment",
                  value: "get_appointment",
                },
                {
                  label: "Get All Appointments",
                  value: "get_all_appointments",
                },
                {
                  label: "Get Orders",
                  value: "get_orders",
                },
              ],
            },
            {
              ques: "Params",
              quesKey: "params",
              dependentOn: [
                {
                  dependentOnQuesKey: "ehr_api",
                  dependentValue: ["get_patient"],
                },
              ],
              required: true,
              type: "params",
              answer: [
                {
                  key: "patientId",
                  value: "",
                },
                {
                  key: "dob",
                  value: "",
                },
                {
                  key: "firstName",
                  value: "",
                },
                {
                  key: "lastName",
                  value: "",
                },
              ],
            },
            {
              ques: "Params",
              quesKey: "params",
              dependentOn: [
                {
                  dependentOnQuesKey: "ehr_api",
                  dependentValue: ["get_orders"],
                },
              ],
              required: true,
              type: "params",
              answer: [
                {
                  key: "appointmentId",
                  value: "",
                },
              ],
            },
            {
              ques: "Params",
              quesKey: "params",
              required: true,
              dependentOn: [
                {
                  dependentOnQuesKey: "ehr_api",
                  dependentValue: ["get_appointment"],
                },
              ],
              type: "params",
              answer: [
                {
                  key: "appointmentId",
                  value: "",
                },
              ],
            },
            {
              ques: "Params",
              quesKey: "params",
              required: true,
              dependentOn: [
                {
                  dependentOnQuesKey: "ehr_api",
                  dependentValue: ["get_all_appointments"],
                },
              ],
              type: "params",
              answer: [
                {
                  key: "start_date",
                  value: "",
                },
                {
                  key: "end_date",
                  value: "",
                },
                {
                  key: "with_patient",
                  value: "",
                },
              ],
            },
            {
              ques: "Params",
              quesKey: "params",
              required: true,
              dependentOn: [
                {
                  dependentOnQuesKey: "ehr_api",
                  dependentValue: ["update_patient"],
                },
              ],
              type: "params",
              answer: [
                {
                  key: "patientId",
                  value: "",
                },
                {
                  key: "dob",
                  value: "",
                },
              ],
            },
          ],
        },
      },
      {
        label: "Crons",
        Icon: <GrUpdate />,
        nodeType: "normal-node",
        configurations: {
          configData: [
            {
              ques: "Frequency",
              quesKey: "freq",
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Hourly",
                  value: "Hourly",
                },
                {
                  label: "Daily",
                  value: "Daily",
                },
                {
                  label: "Weekly",
                  value: "Weekly",
                },
                {
                  label: "Monthly",
                  value: "Monthly",
                },
                {
                  label: "Yearly",
                  value: "Yearly",
                },
              ],
            },
            {
              ques: "data",
              quesKey: "cron_data",
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "EHRs",
                  value: "ehr",
                },
                {
                  label: "Custom Spaces",
                  value: "custom_spaces_cron",
                },
              ],
            },
            {
              ques: "Custom Spaces",
              quesKey: "custom_spaces_list_crom",
              dependentOn: [
                {
                  dependentOnQuesKey: "cron_data",
                  dependentValue: ["custom_spaces_cron"],
                },
              ],
              required: true,
              type: "dropdown",
              options: customSpacesOptions || [],
            },
            {
              ques: "If",
              quesKey: "cron_filter_if",
              dependentOn: [
                {
                  dependentOnQuesKey: "cron_data",
                  dependentValue: ["custom_spaces_cron"],
                },
              ],
              required: true,
              type: "text",
            },
            {
              // ques: "Filters, if any:",
              quesKey: "cron_filter_operator",
              dependentOn: [
                {
                  dependentOnQuesKey: "cron_data",
                  dependentValue: ["custom_spaces_cron"],
                },
              ],
              type: "dropdown",
              options: [
                { label: "Equals to (=)", value: "==" },
                { label: "Not equals to (!=)", value: "!=" },
                { label: "Greater than (>)", value: ">" },
                { label: "Less than (<)", value: "<" },
              ],
            },
            {
              ques: "Operands",
              quesKey: "cron_filter_operand",
              dependentOn: [
                {
                  dependentOnQuesKey: "cron_data",
                  dependentValue: ["custom_spaces_cron"],
                },
              ],
              required: true,
              type: "text",
            },
          ],
        },
      },
      {
        label: "EHR Listener",
        Icon: <RiMessage2Line />,
        nodeType: "normal-node",
        configurations: {
          configData: [
            {
              ques: "Client Integration",
              quesKey: "client_integration",
              required: true,
              type: "dropdown",
              answer: {
                label: "Athena",
                value: "Athena",
              },
              options: [
                {
                  label: "Athena",
                  value: "Athena",
                },
                {
                  label: "Client Demo 1",
                  value: "clientDemo1",
                },
                {
                  label: "Client Demo 2",
                  value: "clientDemo2",
                },
              ],
            },
            {
              ques: "Listener",
              quesKey: "listener",
              required: true,
              type: "dropdown",
              answer: {
                label: "Order Updates",
                value: "Order Updates",
              },
              options: [
                {
                  label: "Order Updates",
                  value: "Order Updates",
                },
              ],
            },
          ],
        },
      },

      {
        label: "Generate PDF",
        Icon: <CiCalculator2 />,
        nodeType: "normal-node",
        configurations: {
          configData: [
            {
              ques: "Template",
              quesKey: "template",
              required: true,
              type: "dropdown",
              answer: {
                label: "None",
                value: "None",
              },
              options: [
                {
                  label: "None",
                  value: "None",
                },
              ],
            },
            {
              ques: "Raw Render",
              quesKey: "raw_render",
              required: true,
              type: "dropdown",
              answer: {
                label: "True",
                value: "True",
              },
              options: [
                {
                  label: "True",
                  value: "True",
                },
                {
                  label: "False",
                  value: "False",
                },
              ],
            },
          ],
        },
      },
      {
        label: "HBx Actions",
        Icon: <MdChecklist />,
        nodeType: "normal-node",
        configurations: {
          configData: [
            {
              ques: "Type",
              quesKey: "hbx_action_type",
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Map Journey",
                  value: "mapJourney",
                },
                {
                  label: "Custom Space",
                  value: "custom_space_action",
                },
              ],
            },
            {
              ques: "Meta Fields",
              dependentOn: [
                {
                  dependentOnQuesKey: "hbx_action_type",
                  dependentValue: ["mapJourney"],
                },
              ],
              quesKey: "hbx_meta",
              required: true,
              type: "text",
              answer: "$trigger.$meta",
            },
            {
              ques: "Journey to map",
              dependentOn: [
                {
                  dependentOnQuesKey: "hbx_action_type",
                  dependentValue: ["mapJourney"],
                },
              ],
              quesKey: "journey_mapper",
              required: true,
              type: "dropdown",
              answer: {
                label: "Patient Information Collection",
                value: "Patient Information Collection",
              },
              options: [
                {
                  label: "Patient Information Collection",
                  value: "Patient Information Collection",
                },
              ],
            },
            {
              ques: "Custom Spaces",
              quesKey: "custom_spaces_list_action",
              dependentOn: [
                {
                  dependentOnQuesKey: "hbx_action_type",
                  dependentValue: ["custom_space_action"],
                },
              ],
              required: true,
              type: "dropdown",
              options: customSpacesOptions || [],
            },
            {
              ques: "Action",
              quesKey: "custom_spaces_action_hbx",
              dependentOn: [
                {
                  dependentOnQuesKey: "hbx_action_type",
                  dependentValue: ["custom_space_action"],
                },
              ],
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Add Entry",
                  value: "add_entry",
                },
                {
                  label: "Delete Entry",
                  value: "delete_entry",
                },
                {
                  label: "Update Entry",
                  value: "update_entry",
                },
                
              ],
            },
            {
              ques: "Body",
              required: true,
              type: "params",
              dependentOn: [
                {
                  dependentOnQuesKey: "hbx_action_type",
                  dependentValue: ["custom_space_action"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_action_hbx",
                  dependentValue: ["delete_entry", "update_entry"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_list_action",
                  dependentValue: ["663de4ad4ca7618922d24f33"],
                },
              ],
              answer: [
                {
                  key: "id",
                  value: "$trigger.id",
                },
                {
                  key: "first_name",
                  value: "$ehrApi.firstName",
                },
                {
                  key: "last_name",
                  value: "$ehrApi.lastName",
                },
                {
                  key: "patientid",
                  value: "",
                },
                {
                  key: "dob",
                  value: "$ehrApi.dob",
                },
                {
                  key: "insurance",
                  value: "$ehrApi.insurance",
                },
                {
                  key: "address",
                  value: "$ehrApi.address",
                }
              ],
            },
            {
              ques: "Body",
              required: true,
              type: "params",
              dependentOn: [
                {
                  dependentOnQuesKey: "hbx_action_type",
                  dependentValue: ["custom_space_action"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_action_hbx",
                  dependentValue: ["delete_entry", "update_entry"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_list_action",
                  dependentValue: ["663de6374ca7618922d24f78"],
                },
              ],
              answer: [
                {
                  key: "_id",
                  value: "$trigger.id",
                },
                {
                  key: "date",
                  value: "",
                },
                {
                  key: "patientid",
                  value: "",
                },
                {
                  key: "first_name",
                  value: "$ehrApi.firstName",
                },
                {
                  key: "last_name",
                  value: "$ehrApi.lastName",
                },
                {
                  key: "appointmentid",
                  value: "",
                }
              ],
            },
            {
              ques: "Body",
              required: true,
              type: "params",
              dependentOn: [
                {
                  dependentOnQuesKey: "hbx_action_type",
                  dependentValue: ["custom_space_action"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_action_hbx",
                  dependentValue: ["delete_entry", "update_entry"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_list_action",
                  dependentValue: ["663de9e44ca7618922d24f90"],
                },
              ],
              answer: [
                {
                  key: "_id",
                  value: "$trigger.id",
                },
                {
                  key: "patientid",
                  value: "",
                },
                {
                  key: "first_name",
                  value: "$ehrApi.firstName",
                },
                {
                  key: "last_name",
                  value: "$ehrApi.lastName",
                },
                {
                  key: "device",
                  value: "",
                },
                {
                  key: "test_status",
                  value: "",
                },
                {
                  key: "interpretation_date",
                  value: "",
                },
              ],
            },
            {
              ques: "Body",
              required: true,
              type: "params",
              dependentOn: [
                {
                  dependentOnQuesKey: "hbx_action_type",
                  dependentValue: ["custom_space_action"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_action_hbx",
                  dependentValue: ["delete_entry", "update_entry"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_list_action",
                  dependentValue: ["663dea5c4ca7618922d24fad"],
                },
              ],
              answer: [
                {
                  key: "_id",
                  value: "$trigger.id",
                },
                {
                  key: "patientid",
                  value: "",
                },
                {
                  key: "first_name",
                  value: "$ehrApi.firstName",
                },
                {
                  key: "last_name",
                  value: "$ehrApi.lastName",
                },
                {
                  key: "handling_fee",
                  value: "",
                },
                {
                  key: "tracking_number",
                  value: "",
                },
                {
                  key: "shipment_date",
                  value: "",
                },
                {
                  key: "shipment_status",
                  value: "",
                },
              ],
            },
            {
              ques: "Body",
              required: true,
              type: "params",
              dependentOn: [
                {
                  dependentOnQuesKey: "hbx_action_type",
                  dependentValue: ["custom_space_action"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_action_hbx",
                  dependentValue: ["add_entry"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_list_action",
                  dependentValue: ["663de4ad4ca7618922d24f33"],
                },
              ],
              answer: [
                {
                  key: "id",
                  value: "",
                },
                {
                  key: "first_name",
                  value: "",
                },
                {
                  key: "last_name",
                  value: "",
                },
                {
                  key: "patientid",
                  value: "",
                },
                {
                  key: "dob",
                  value: "",
                },
                {
                  key: "insurance",
                  value: "",
                },
                {
                  key: "address",
                  value: "",
                }
              ],
            },
            {
              ques: "Body",
              required: true,
              type: "params",
              dependentOn: [
                {
                  dependentOnQuesKey: "hbx_action_type",
                  dependentValue: ["custom_space_action"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_action_hbx",
                  dependentValue: ["add_entry"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_list_action",
                  dependentValue: ["663de6374ca7618922d24f78"],
                },
              ],
              answer: [
                {
                  key: "_id",
                  value: "",
                },
                {
                  key: "date",
                  value: "",
                },
                {
                  key: "patientid",
                  value: "",
                },
                {
                  key: "first_name",
                  value: "",
                },
                {
                  key: "last_name",
                  value: "",
                },
                {
                  key: "appointmentid",
                  value: "",
                }
              ],
            },
            {
              ques: "Body",
              required: true,
              type: "params",
              dependentOn: [
                {
                  dependentOnQuesKey: "hbx_action_type",
                  dependentValue: ["custom_space_action"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_action_hbx",
                  dependentValue: ["add_entry"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_list_action",
                  dependentValue: ["663de9e44ca7618922d24f90"],
                },
              ],
              answer: [
                {
                  key: "_id",
                  value: "",
                },
                {
                  key: "patientid",
                  value: "",
                },
                {
                  key: "first_name",
                  value: "",
                },
                {
                  key: "last_name",
                  value: "",
                },
                {
                  key: "device",
                  value: "",
                },
                {
                  key: "test_status",
                  value: "",
                },
                {
                  key: "interpretation_date",
                  value: "",
                },
              ],
            },
            {
              ques: "Body",
              required: true,
              type: "params",
              dependentOn: [
                {
                  dependentOnQuesKey: "hbx_action_type",
                  dependentValue: ["custom_space_action"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_action_hbx",
                  dependentValue: ["add_entry"],
                },
                {
                  dependentOnQuesKey: "custom_spaces_list_action",
                  dependentValue: ["663dea5c4ca7618922d24fad"],
                },
              ],
              answer: [
                {
                  key: "_id",
                  value: "",
                },
                {
                  key: "patientid",
                  value: "",
                },
                {
                  key: "first_name",
                  value: "",
                },
                {
                  key: "last_name",
                  value: "",
                },
                {
                  key: "handling_fee",
                  value: "",
                },
                {
                  key: "tracking_number",
                  value: "",
                },
                {
                  key: "shipment_date",
                  value: "",
                },
                {
                  key: "shipment_status",
                  value: "",
                },
              ],
            },
          ],
        },
      },
      {
        label: "Email",
        Icon: <CgMail />,
        nodeType: "normal-node",
        configurations: {
          configData: [
            {
              ques: "Actions",
              quesKey: "mail_action",
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "Watch Emails",
                  value: "watch",
                },
                {
                  label: "Send an Email",
                  value: "Send",
                },
                {
                  label: "Draft an Email",
                  value: "Draft",
                },
              ],
            },
            {
              ques: "Sender",
              quesKey: "sender",
              dependentOn: [
                {
                  dependentOnQuesKey: "mail_action",
                  dependentValue: ["Send"],
                },
              ],
              required: true,
              type: "dropdown",
              answer: {
                label: "info@healthblox.care",
                value: "info@healthblox.care",
              },
              options: [
                {
                  label: "info@healthblox.care",
                  value: "info@healthblox.care",
                },
              ],
            },
            {
              ques: "To",
              quesKey: "to",
              dependentOn: [
                {
                  dependentOnQuesKey: "mail_action",
                  dependentValue: ["Send"],
                },
              ],
              required: true,
              type: "text",
              answer: "$trigger.$meta.patient.email",
            },
            {
              ques: "Content Type",
              quesKey: "content",
              dependentOn: [
                {
                  dependentOnQuesKey: "mail_action",
                  dependentValue: ["Send"],
                },
              ],
              required: true,
              type: "dropdown",
              answer: {
                label: "Template",
                value: "Template",
              },
              options: [
                {
                  label: "Raw",
                  value: "raw",
                },
                {
                  label: "Template",
                  value: "template",
                },
              ],
            },
            {
              ques: "Template",
              quesKey: "template",
              dependentOn: [
                {
                  dependentOnQuesKey: "mail_action",
                  dependentValue: ["Send"],
                },
                {
                  dependentOnQuesKey: "content",
                  dependentValue: ["template"],
                },
              ],
              required: true,
              type: "dropdown",
              answer: {
                label: "Patient Onboarding Email",
                value: "Patient Onboarding Email",
              },
              options: [
                {
                  label: "Patient Onboarding Email",
                  value: "Patient Onboarding Email",
                },
              ],
            },
          ],
        },
      },
      {
        label: "External API",
        Icon: <CiCalendar />,
        nodeType: "normal-node",

        configurations: {
          configData: [
            {
              ques: "External Integration",
              quesKey: "external_integration",
              required: true,
              type: "dropdown",
              answer: {
                label: "IDTF",
                value: "IDTF",
              },
              options: [
                {
                  label: "IDTF",
                  value: "IDTF",
                },
              ],
            },
            { ques: "Authorisation:enabled", type: "type" },
            {
              ques: "Endpoint",
              required: true,
              type: "dropdown",
              answer: {
                label: "/eprescription",
                value: "/eprescription",
              },
              options: [
                {
                  label: "/eprescription",
                  value: "/eprescription",
                },
              ],
            },
            {
              ques: "Body",
              required: true,
              type: "params",
              answer: [
                {
                  key: "orderInfo",
                  value: "$trigger",
                },
                {
                  key: "patientInfo",
                  value: "$ehrApi1",
                },
                {
                  key: "appointmentInfo",
                  value: "$ehrApi2",
                },
              ],
            },
          ],
        },
      },
      {
        label: "Shipment",
        Icon: <MdOutlineLocalShipping />,
        nodeType: "normal-node",

        configurations: {
          configData: [
            {
              ques: "Vendor",
              quesKey: "external_integration",
              required: true,
              type: "dropdown",
              options: [
                {
                  label: "USPS",
                  value: "USPS",
                },{
                  label: "DHL",
                  value: "DHL",
                },{
                  label: "FedEx",
                  value: "FedEx",
                },
              ],
            },
            { ques: "Authorisation:enabled", type: "type" },
            {
              ques: "Endpoint",
              required: true,
              type: "dropdown",
              answer: {
                label: "GET/shipment",
                value: "GET/shipment",
              },
              options: [
                {
                  label: "GET/shipment",
                  value: "GET/shipment",
                }
              ],
            },
            {
              ques: "Body",
              required: true,
              type: "params",
              answer: [
                {
                  key: "trackingId",
                  value: "$trigger.tracking_number",
                  required: true
                }
              ],
            },
          ],
        },
      },
      {
        label: "End",
        Icon: <IoStopSharp />,
        nodeType: "end-node",
      },
    ]);
  }
  const onLayout = (nodes, edges, direction) => {
    const layouted = getLayoutedElements(nodes, edges, { direction });
    setNodes([...layouted.nodes]);
    setEdges([...layouted.edges]);
    setHistory((prev) => [
      ...prev,
      { nodes: layouted.nodes, edges: layouted.edges },
    ]);
    setHistoryStep((prev) => prev + 1);
  };
  const getLayoutedElements = (nodes, edges, options) => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({ rankdir: options.direction });

    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) => g.setNode(node.id, { width: 450, height: 150 }));

    // Set the initial position for the starting node

    Dagre.layout(g);

    return {
      nodes: nodes.map((node) => {
        const { x, y } = g.node(node.id);
        return { ...node, position: { x: x + 100, y: y + 150 } };
      }),
      edges,
    };
  };
  const handleEdgeClick = (e, ed) => {
    const { id } = ed;
    setEdgeID(id);
    setEdgeN(ed);
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    setEdgePopupStyle({
      width: "340px",
      height: "400px",
      top: e.clientY - reactFlowBounds.top - 190,
      left: e.clientX - reactFlowBounds.left + 20,
    });
    setOpenEdgeDrawer(true);
  };
  const handleDrag = (e, node) => {
    if (activeNodeId === node.id) {
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      setStyle({
        ...style,
        top: e.clientY - reactFlowBounds.top - 190,
        left: e.clientX - reactFlowBounds.left + 50,
      });
    }
  };

  const handleUndo = () => {
    if (historyStep === 0) {
      return;
    }
    const previous = history[historyStep - 1];
    setNodes(previous.nodes);
    setEdges(previous.edges);
    setHistoryStep((prev) => prev - 1);
  };

  const handleRedo = () => {
    if (historyStep === history.length - 1) {
      return;
    }
    const next = history[historyStep + 1];
    setNodes(next.nodes);
    setEdges(next.edges);
    setHistoryStep((prev) => prev + 1);
  };
  async function saveCareFlow() {
    if (careFlowName) {
      const API_URL = process.env.REACT_APP_FOUNTAIN_HEAD_URL;
      const saveFlowApiUrl = `${API_URL}/workflows/raw`;
      try {
        const headers = getAuthToken();
        await axios.post(
          saveFlowApiUrl,
          {
            data: {
              nodes,
              edges,
              conditionData,
              name: careFlowName,
              approved: "true",
              client: userData?.org?.name || "HBx Test",
              default_client_integration: "Athena",
              originator_client: "self",
              status: "ENABLED",
              madeFromHBxDashboard: true
            },
          },
          headers
        );
        setOpenModal(false);
        navigate("/care-flow")
      } catch (error) {
        console.log(error);
        // setOpenModal(true);
      }
      console.log({ data: { nodes, edges, conditionData, careFlowName } });
    }
  }
  async function handleSaveWorkflow() {
    setOpenModal(true);
  }

  useEffect(() => {
    if (fetchedNodes) {
      setNodes(fetchedNodes);
    }
    if (fetchedEdges) {
      setEdges(fetchedEdges);
    }
    setHistory([
      { nodes: fetchedNodes || initialNodes, edges: fetchedEdges || [] },
    ]);
    setHistoryStep(0);
    // onLayout(fetchedNodes || initialNodes, fetchedEdges || [], "LR")
  }, [fetchedEdges, fetchedNodes]);

  useEffect(()=> {
    setPopUpApps();
  }, [])

  function addNewNodeHandler(node) {
    const activeNodeId = node?.id;
    const activeNode = nodes.filter((node) => node.id === activeNodeId)[0];

    const id = getId();
    let newNode = {
      id,
      type: "customNode",
      position: {
        x: activeNode.position.x + 300 + activeNode.width,
        y: activeNode.position.y,
      },
      data: {
        nodeType: "empty-node",
      },
    };
    let newEdge = {
      id,
      source: activeNode.id,
      target: id,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 16,
        height: 16,
      },
      style: {
        strokeWidth: 2,
      },
    };
    // setNodes((nds) => nds.concat(newNode));
    // setEdges((eds) =>
    //   eds.concat(newEdge)
    // );

    onLayout(nodes.concat(newNode), edges.concat(newEdge), "LR");
  }
  function deleteNode(node) {
    const activeNode = node?.id;
    const childNodes = edges.filter((edge) => edge?.source === activeNode);
    const parentNodeID = edges.filter((edge) => edge?.target === activeNode)[0]
      ?.source;
    let newNodesArray = nodes.filter((node) => node?.id !== activeNode);
    let newEdgesArray = edges.filter((edge) => edge?.target !== activeNode);
    for (let i = 0; i < newEdgesArray?.length; i++) {
      if (newEdgesArray[i]?.source === activeNode) {
        newEdgesArray[i].source = parentNodeID;
      }
    }
    if(newNodesArray?.length === 0) {
      newNodesArray = [...initialNodes]
    }
    onLayout(newNodesArray, newEdgesArray, "LR");
    setActiveNodeId(parentNodeID);
    // setNodes(newNodesArray)
    // setEdges(newEdgesArray)
    // onLayout(nodes, edges, "LR")
  }
  return (
    <div className="w-full h-full">
      <ReactFlowProvider>
        <div
          className="reactflow-wrapper w-full h-full relative nowheel nodrag"
          ref={reactFlowWrapper}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            edgeTypes={edgeTypes}
            snapToGrid={true}
            nodeTypes={nodeTypes}
            defaultEdgeOptions={defaultEdgeOptions}
            onEdgeClick={handleEdgeClick}
            onNodeClick={handleNodeClick}
            zoomOnScroll={false}
            onNodeDrag={(e, node) => handleDrag(e, node)}
            defaultViewport={{ x: 0, y: 0, zoom: 0.75 }}
          >
            <Panel position="top-right">
              <div className="react-flow-header flex justify-end gap-2">
                {historyStep > 0 && (
                  <CustomButton onClick={handleUndo}>Undo</CustomButton>
                )}
                {historyStep < history.length - 1 && (
                  <CustomButton onClick={handleRedo}>Redo</CustomButton>
                )}
              </div>
            </Panel>
            <Controls position="top-left" />
            <Background variant="dots" gap={12} size={1} />
          </ReactFlow>
          {openEdgeDrawer ? (
            <div className="absolute" style={edgePopupStyle}>
              <EdgePopUp
                onClickOutside={() => {
                  setOpenEdgeDrawer(false);
                }}
                sourceNodeID={source}
                targetNodeID={target}
                edgeID={edgeID}
                edgeData={edgeN}
                // handleAppClick={handleAppClick}
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="react-flow-footer flex justify-between gap-16">
          <div className="flex cursor-pointer">
            <PlayArrowIcon
              sx={{
                fontSize: "48px",
                backgroundColor: "#cf7447",
                color: "#fff",
                borderRadius: "4px",
              }}
            />
            <div className="my-auto footer-icon-text px-2">Run Once</div>
          </div>
          <div className="flex gap-4">
            <Tooltip title="Save" placement="top">
              <SaveOutlinedIcon
                onClick={handleSaveWorkflow}
                sx={{
                  fontSize: "48px",
                  backgroundColor: "#cf74471a",
                  color: "black",
                  borderRadius: "4px",
                  padding: "10px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
            <Tooltip title="Workflow Setting" placement="top">
              <SettingsOutlinedIcon
                sx={{
                  fontSize: "48px",
                  backgroundColor: "#cf74471a",
                  color: "black",
                  borderRadius: "4px",
                  padding: "10px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
            <Tooltip title="Notes" placement="top">
              <DescriptionOutlinedIcon
                sx={{
                  fontSize: "48px",
                  backgroundColor: "#cf74471a",
                  color: "black",
                  borderRadius: "4px",
                  padding: "10px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
            <Tooltip title="Auto-align" placement="top">
              <AlignHorizontalCenterOutlinedIcon
                sx={{
                  fontSize: "48px",
                  backgroundColor: "#cf74471a",
                  color: "black",
                  borderRadius: "4px",
                  padding: "10px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
            <Tooltip title="Explain flow" placement="top">
              <ShareOutlinedIcon
                sx={{
                  fontSize: "48px",
                  backgroundColor: "#cf74471a",
                  color: "black",
                  borderRadius: "4px",
                  padding: "10px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
            <Tooltip title="More" placement="top">
              <MoreHorizOutlinedIcon
                sx={{
                  fontSize: "48px",
                  backgroundColor: "#cf74471a",
                  color: "black",
                  borderRadius: "4px",
                  padding: "10px",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </div>
        </div>
      </ReactFlowProvider>
      {openModal ? (
        <div>
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={popUpStyle} className="box-modal">
              <div className="modal-content flex flex-col gap-4 justify-center p-4">
                <div>
                  <div className="modal-title">Care Flow Name :</div>
                  <div className="modal-subtitle">
                    <TextField
                      className="w-full"
                      data={{ answer: { value: careFlowName } }}
                      onChange={(data, textFieldValue) =>
                        setCareFlowName(textFieldValue.value)
                      }
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div
                    className="text-center grow modal-button modal-button-right"
                    onClick={() => {
                      saveCareFlow();
                    }}
                  >
                    {"Confirm"}
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default CreateWorkFlow;
