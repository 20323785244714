import "./common.scss";

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import InputRadio from "./InputRadio";
import InputNumber from "./InputNumber";
import InputSingleCheckBox from "./InputSingleCheckBox";
import axios from "axios";
import { getAuthToken } from "../../utils/cookie";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

function WidgetModalBoxForScheduler({
  openModal,
  SubTitle,
  rightButtonCallback = () => {},
  widget = {},
}) {
  const [open, setOpen] = React.useState(openModal);
  const [clientIntegration, setClientIntegration] = React.useState(null);
  const [clientIntegrationList, setClientIntegrationList] = React.useState([]);
  const [schedulersForSelectedCI, setSchedulersForSelectedCI] = React.useState(
    []
  );
  const [selectedScheduler, setSelectedScheduler] = React.useState(null);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const handleChange = async (e) => {
    const selectedValue = e.target.value;
    setClientIntegration(selectedValue);
    let schedulerList = await checkForSchedulerId(selectedValue);
    setSchedulersForSelectedCI(schedulerList);
  };

  const handleChangeForScheduler = async (e) => {
    const selectedValue = e.target.value;
    setSelectedScheduler(selectedValue);
  };

  const fetchClientIntegrations = async () => {
    const API_URL = process.env.REACT_APP_FOUNTAIN_HEAD_URL;
    const fetchClientIntegrationsUrl = `${API_URL}/client/integrations`;
    const headers = getAuthToken();
    try {
      const res = await axios.get(fetchClientIntegrationsUrl, headers);
      const { clientIntegrations } = res.data;
      setClientIntegrationList(clientIntegrations);
    } catch (error) {
      alert("error in fetching client integrations, please try again later.");
    }
  };
  React.useEffect(() => {
    fetchClientIntegrations();
  }, []);

  const checkForSchedulerId = async (_CI) => {
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/widgets?client_integration_ids=${_CI}`;

    const headers = getAuthToken();
    try {
      const response = await axios.get(API_URL, headers);
      const { data } = response.data;

      if (data?.length) return data;
      return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const createNewSchedulerId = async () => {
    let client_integration = clientIntegration;
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/widgets`;

    const headers = getAuthToken();
    try {
      const response = await axios.post(
        API_URL,
        {
          client_integration: client_integration,
          label: widget?.label,
          scheduler_status: widget?.scheduler_status,
          type: widget?.type,
        },
        headers
      );

      const { schedulerWidget } = response?.data;
      const { _id } = schedulerWidget;
      setSelectedScheduler(_id);
      // await mapSchedulerForJourney();
      rightButtonCallback({
        clientIntegration,
        selectedScheduler: _id,
      });
      return _id;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const mapSchedulerForJourney = async () => {
    let API_URL = `${
      process.env?.REACT_APP_SCHEDULER_URL ||
      "https://apps-sandbox.healthblox.care/scheduler"
    }/api/v1/widgets`;
    const headers = getAuthToken();
    try {
      const response = await axios.post(
        API_URL,
        // {
        //   client_integration: client_integration,
        //   label: widget?.label,
        //   scheduler_status: widget?.scheduler_status,
        //   type: widget?.type,
        // },
        headers
      );

      const { data } = response;
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return (
    <div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {clientIntegrationList && clientIntegrationList?.length ? (
            <>
              <Box sx={style} className="box-modal">
                <div className="modal-content flex flex-col justify-center p-4">
                  <div className="modal-subtitle mx-auto">{SubTitle}</div>

                  <div className={`w-full relative dropdown-select py-3`}>
                    <div className={`form-title font_semibold`}>
                      Please select Client Integration
                    </div>

                    <select
                      className="new-dropdown radio-common text-field text-input my-3"
                      value={clientIntegration || ""}
                      onChange={handleChange}
                    >
                      <option
                        className="placeholder-text text-gray-400 italic opacity-75"
                        value=""
                        disabled
                        hidden
                      >
                        Please select
                      </option>
                      {clientIntegrationList.length > 0 &&
                        clientIntegrationList.map((option) => (
                          <option key={option._id} value={option._id}>
                            {option.integration_name || "Option"}
                          </option>
                        ))}
                    </select>
                  </div>
                  {clientIntegration && (
                    <>
                      {schedulersForSelectedCI &&
                      schedulersForSelectedCI?.length ? (
                        <>
                          <div
                            className={`w-full relative dropdown-select py-3`}
                          >
                            <div className={`form-title font_semibold`}>
                              Please select Scheduler
                            </div>

                            <select
                              className="new-dropdown radio-common text-field text-input my-3"
                              value={selectedScheduler || ""}
                              onChange={handleChangeForScheduler}
                            >
                              <option
                                className="placeholder-text text-gray-400 italic opacity-75"
                                value=""
                                disabled
                                hidden
                              >
                                Please select
                              </option>
                              {schedulersForSelectedCI.length > 0 &&
                                schedulersForSelectedCI.map((option) => (
                                  <option key={option._id} value={option._id}>
                                    {option._id || "Option"}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </>
                      ) : (
                        <div className="text-sm text-center mx-auto content-center">
                          No Schedulers found for this CI, Please create a new
                          one.
                        </div>
                      )}
                      <div
                        className="text-sm mustard_text py-3 cursor-pointer text-center"
                        onClick={createNewSchedulerId}
                      >
                        Create new Scheduler
                      </div>
                    </>
                  )}

                  <div
                    className="text-center grow modal-button modal-button-right"
                    onClick={async () => {
                      if (clientIntegration) {
                        // await mapSchedulerForJourney();
                        rightButtonCallback({
                          clientIntegration,
                          selectedScheduler,
                        });
                      }
                    }}
                  >
                    Confirm
                  </div>
                </div>
              </Box>
            </>
          ) : (
            <>...Fetching Client Integrations</>
          )}
        </Modal>
      </div>
    </div>
  );
}
export default WidgetModalBoxForScheduler;
