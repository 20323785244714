import React from "react";
import { BarChartOutlined } from "@mui/icons-material";
import {
  Box,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import {
  Rectangle,
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { CustomCard } from "../../../../components/custom/CustomCard";

export const AppUsage = () => {
  const dataset = [
    {
      thisYear: 19,
      lastYear: 36,
      name: "Jan",
    },
    {
      thisYear: 23,
      lastYear: 45,
      name: "Feb",
    },
    {
      thisYear: 12,
      lastYear: 26,
      name: "Mar",
    },
    {
      thisYear: 20,
      lastYear: 39,
      name: "Apr",
    },
    {
      thisYear: 12,
      lastYear: 26,
      name: "May",
    },
    {
      thisYear: 31,
      lastYear: 42,
      name: "Jun",
    },
    {
      thisYear: 19,
      lastYear: 38,
      name: "Jul",
    },
    {
      thisYear: 20,
      lastYear: 39,
      name: "Aug",
    },
    {
      thisYear: 18,
      lastYear: 37,
      name: "Sep",
    },
    {
      thisYear: 22,
      lastYear: 41,
      name: "Oct",
    },
    {
      thisYear: 24,
      lastYear: 45,
      name: "Nov",
    },
    {
      thisYear: 17,
      lastYear: 23,
      name: "Dec",
    },
  ];
  return (
    <CustomCard
      sx={{
        height: "450px",
      }}
    >
      <CardHeader
        title={<Typography variant="h6">Patients</Typography>}
        avatar={
          <IconButton aria-label="sales">
            <BarChartOutlined />
          </IconButton>
        }
      />
      {/* <Divider /> */}
      <Box
        p={"0px 24px 0px 24px"}
        sx={{ overflowY: "auto" }}
        alignContent={"center"}
      >
        <Stack direction={"row"} spacing={4} height={"350px"}>
          <Stack justifyContent={"space-between"} maxWidth={"240px"}>
            <Stack spacing={2}>
              <Typography variant="h2" sx={{ color: "#15B795" }}>
                +28%
              </Typography>
              <Typography variant="body1" sx={{ color: "#667085" }}>
                increase in new patients with{" "}
                <span style={{ color: "#212636" }}>6,521</span> new patients
                seen
              </Typography>
            </Stack>
            <Typography variant="body1" sx={{ color: "#667085" }}>
              <span style={{ color: "#635BFF" }}>This year</span> is forecasted
              to increase in your traffic by the end of the current month
            </Typography>
          </Stack>
          <Stack spacing={3} width={"100%"}>
            <ResponsiveContainer width={"100%"} height={"80%"}>
              <BarChart
                // width={500}
                // height={300}
                data={dataset}
                margin={
                  {
                    // top: 20,
                    // right: 30,
                    // left: 20,
                    // bottom: 5,
                  }
                }
              >
                <CartesianGrid vertical={false} strokeDasharray="3" />
                <XAxis dataKey="name" tickLine={false} />
                {/* <YAxis /> */}
                <Tooltip />
                {/* <Legend /> */}
                <Bar
                  dataKey="lastYear"
                  stackId="a"
                  fill="#3339F8"
                  shape={<RoundedBar val={0} />}
                />
                <Bar
                  dataKey="thisYear"
                  stackId="a"
                  fill="#738DFF"
                  shape={<RoundedBarInverse val={5} />}
                />
              </BarChart>
            </ResponsiveContainer>
            <Divider />
            <Stack spacing={2} direction={"row"}>
              <LabelItem bgcolor={"#738DFF"} label={"This Year"} />
              <LabelItem bgcolor={"#3339F8"} label={"Last Year"} />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </CustomCard>
  );
};

const RoundedBar = (props) => {
  const { x, width } = props;

  return (
    <Rectangle
      {...props}
      x={x - 1} // Adjust the x-coordinate for rounding effect
      width={width} // Adjust the width for rounding effect
      radius={[5, 5, 5, 5]} // Set border-radius for top corners
    />
  );
};

const RoundedBarInverse = (props) => {
  const { x, y, width } = props;

  return (
    <Rectangle
      {...props}
      x={x - 1} // Adjust the x-coordinate for rounding effect
      y={y + 5}
      width={width} // Adjust the width for rounding effect
      radius={[5, 5, 0, 0]} // Set border-radius for top corners
    />
  );
};

const LabelItem = ({ bgcolor, label }) => {
  return (
    <Stack spacing={1} direction={"row"} alignItems={"center"}>
      <Box
        sx={{
          backgroundColor: bgcolor, // "#738DFF",
          height: "4px",
          width: "16px",
          borderRadius: "2px",
        }}
      />

      <Typography variant="caption" sx={{ color: "#667085" }}>
        {label}
      </Typography>
    </Stack>
  );
};
