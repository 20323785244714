import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { postRequest } from "../../../utils/request";
import { Check, Email, Key } from "@mui/icons-material";
import CustomInput from "../../../components/custom/CustomInput";
import CustomButton from "../../../components/custom/CustomButton";

const CreateInvite = ({ closeModal }) => {
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const save = async () => {
    //sendinvite
    console.log(email, role);
    const str = role.replace(/\s/g, "");
    let roles = str.split(",");
    console.log(roles);
    try {
      const response = await postRequest("organization/invite", {
        email: email,
        roles: roles,
      });
      console.log(response.data);
      setSuccess(response.data.message);
      // closeModal();
    } catch (e) {
      console.log(e);
      setError(e.response?.data?.message ?? "Something went wrong");
    }
  };

  return (
    <Stack spacing={2}>
      <Stack>
        <CustomInput
          labelText={"Email"}
          id="email"
          error={!!error}
          helperText={error}
          variant="standard"
          onChange={(e) => {
            setEmail(e.target.value);
            setError(null);
            setSuccess(null);
          }}
          startAdornment={
            <InputAdornment position="start">
              <Email />
            </InputAdornment>
          }
        />
        <CustomInput
          labelText={"Roles"}
          id="role"
          variant="standard"
          onChange={(e) => {
            setRole(e.target.value);
            setError(null);
            setSuccess(null);
          }}
          startAdornment={
            <InputAdornment position="start">
              <Key />
            </InputAdornment>
          }
        />
      </Stack>
      {success ? (
        <Alert
          sx={{ marginTop: 2 }}
          icon={<Check fontSize="inherit" />}
          severity="success"
        >
          {success}
        </Alert>
      ) : (
        <Box display={"flex"}>
          <CustomButton
            // sx={{ width: "150px" }}
            disabled={success || error || !role || !email}
            onClick={() => {
              save();
            }}
          >
            Send Invite
          </CustomButton>
        </Box>
      )}
    </Stack>
  );
};
export default CreateInvite;
