import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import axios from "axios";
import SetupForm from "./SetupForm";

export default function StripeSetup({
  org_id,
  email,
  connect_id,
  amount,
  save_details,
  type,
  // handleNextScreenPage = () => {},
  // handleBackScreenPage = () => {},
  // setAnswerForWidget = () => {},
}) {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {
    stripeAccount: connect_id,
  });
  const [clientSecret, setClientSecret] = useState("");
  const createIntent = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PAYMENT_SERVER}/payments/setup-intent`,
        {
          org_id: org_id,
          email: email,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = response.data;
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error(error);
    }
  };
  const createPaymentIntent = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PAYMENT_SERVER}/payments/payment-intent`,
        {
          amount: amount,
          org_id: org_id,
          email: email,
          save_details: save_details,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = response.data;
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (type === "payment") {
      createPaymentIntent();
    } else if (type === "setup") {
      createIntent();
    }
  }, []);

  const options = {
    clientSecret,
  };

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <div style={{ width: "500px", margin: "20px" }}>
            <SetupForm
              type={type}
              // handleNextScreenPage={handleNextScreenPage}
              // handleBackScreenPage={handleBackScreenPage}
              // setAnswerForWidget={setAnswerForWidget}
            />
          </div>
        </Elements>
      )}
    </div>
  );
}
