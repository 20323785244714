import React, { useState, useEffect } from "react";
import "./common.scss";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableContent from "./TableContent";

const TableLayout = ({
  columns = [],
  rows = [],
  filters = [],
  onFilterChange,
  noDataMessage = "No Data Found", // Default no data message
}) => {
  const [filteredRows, setFilteredRows] = useState(rows);
  const [activeFilter, setActiveFilter] = useState("ALL");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    applyFilter(activeFilter);
  }, [activeFilter, rows]);

  const applyFilter = (filterID) => {
    if (filterID === "ALL") {
      setFilteredRows(rows);
    } else {
      const updatedRows = rows.filter((item) => item.status === filterID);
      setFilteredRows(updatedRows);
    }
    if (onFilterChange) onFilterChange(filterID); // Trigger filter change callback
  };

  const handleFilterClick = (filterID) => {
    setActiveFilter(filterID);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const countForFilter = (filterKey) => {
    return rows.filter(
      (item) => item.status === filterKey || filterKey === "ALL"
    ).length;
  };

  return (
    <div className="table-layout">
      {filteredRows.length ? (
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "16px",
            border: "1px",
            borderColor: "inherit",
            border: "solid",
            boxShadow: "none",
          }}
        >
          <div className="flex justify-between border-bottom">
            {filters.length > 0 && (
              <div className="flex flex-row px-3">
                {filters.map((filter) => (
                  <div
                    key={filter.id}
                    className="flex flex-col"
                    onClick={() => handleFilterClick(filter.id)}
                  >
                    <div
                      className={`py-3 px-2 cursor-pointer text-md font-semibold text-gray-500 ${
                        activeFilter === filter.id
                          ? "purple-text"
                          : "hover:text-gray-800"
                      }`}
                    >
                      {filter.label}
                      <span className="ml-2 border rounded-full bg-gray-200 px-1 text-sm">
                        {countForFilter(filter.id)}
                      </span>
                    </div>
                    <div
                      className={
                        activeFilter === filter.id ? "active-filter" : ""
                      }
                    ></div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <TableContent
            rows={filteredRows.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )}
            columns={columns}
            rowsPerPage={rowsPerPage}
            page={page}
          />

          <TablePagination
            rowsPerPageOptions={[5, 10, 30]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="m-auto"
          />
        </Paper>
      ) : (
        <div className="text-center font-semibold text-lg py-3">
          {noDataMessage} {/* Render the no data message */}
        </div>
      )}
    </div>
  );
};

export default TableLayout;
